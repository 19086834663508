// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advance-filter-modal {
  max-width: 700px !important;
}`, "",{"version":3,"sources":["webpack://./src/component/Filter/advanceFilter.scss"],"names":[],"mappings":"AAIA;EACC,2BAAA;AAHD","sourcesContent":[".overflow-container {\n\t// height: 400px auto !important; /* Force height */\n}\n\n.advance-filter-modal {\n\tmax-width: 700px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
