// import Offcanvas from 'react-bootstrap/Offcanvas';
// import React from 'react';

// function Drawer({ isOpen, setIsOpen, title, children }) {
// 	const handleClose = () => setIsOpen(false);

// 	return (
// 		<div>
// 			<Offcanvas
// 				show={isOpen}
// 				onHide={handleClose}
// 			>
// 				<Offcanvas.Header closeButton>
// 					<Offcanvas.Title>{title}</Offcanvas.Title>
// 				</Offcanvas.Header>
// 				<Offcanvas.Body>{children}</Offcanvas.Body>
// 			</Offcanvas>
// 		</div>
// 	);
// }

// export default Drawer;
