import React, { useState } from 'react';
import './Purchase.scss';
import Delete from '../../assets/image/delete.svg'

const PurchaseDetails = ({ onButtonClick, setIsDiscardQuantityModalOpen }) => {
  // State to control checkbox toggle
  const [isPdfView, setIsPdfView] = useState(false);

  // Toggle function
  const handleToggleChange = () => {
    setIsPdfView(!isPdfView);
  };


  return (
    <div>

      <div className="invoice-head" >
        {!isPdfView && (
          <div></div>
        )}
        {isPdfView && (<div className="invoice-status">
          <div className="received-s">
            Received Status: <span style={{ color: '#6AA247' }}>Received</span>
          </div>
          <span>|</span>
          <div className="bill-s">
            Bill Status: <span style={{ color: '#6AA247' }}>Pending</span>
          </div>
        </div>)}



        <div className="status-toggle">
          Show PDF View
          <span>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                onChange={handleToggleChange}
                checked={isPdfView}
              />
            </div>
          </span>
        </div>
      </div>

      <div className={isPdfView ? "invoice pdf-view" : "invoice"}>
        <div className="header">
          {isPdfView && (<div>
            <h1>Medical Store</h1>
            <p>
              <div className="state">Rajasthan</div>
              <div className="country">India</div>
              <div className="email-id">aditbhargava.devoy@gmail.com</div>
            </p>
          </div>)}

          {!isPdfView && (<div>
            <h1 style={{ fontSize: '22px' }}>PO - 0012</h1>
            <br />
            <div >
              <div style={{
                color: '#555B67',
                fontWeight: '600',
                fontSize: '14px',
                marginBottom: '5px'
              }}>STATUS</div>
              <div className='d-flex' style={{ gap: '30px', borderLeft: '2px solid #3B58FF', paddingLeft: '5px' }}>
                <div>
                  <p>Order</p>
                  <p>Receive</p>
                  <p>Bill</p>

                </div>
                <div style={{ color: '#6AA247', }}>
                  <p style={{ backgroundColor: '#6AA247', color: '#fff', borderRadius: '5px', textAlign: 'center' }}>Close</p>
                  <p>Received</p>
                  <p>Billed</p>

                </div>
              </div>
            </div>
          </div>)}


          {isPdfView && (<div className="bill-info">
            <h2>Purchase order</h2>
            <p>PO-0001231</p>
          </div>)}

          {!isPdfView && (<div className="bill-info">
            <h2 style={{ color: '#555B67', fontSize: '16px', textAlign: 'start' }}>VENDOR NAME</h2>
            <p style={{ textAlign: 'start' }}><a href="#" style={{ textDecoration: 'none', }}>Medico Agencies</a></p>

            <div>
              <h2 style={{ color: '#555B67', fontSize: '16px', textAlign: 'start' }}>DELIVERY ADDRESS</h2>
              <p style={{ textAlign: 'start' }}>
                103/3 Kumbha Marg Pratap Nagar <br />
                Sanganer Jaipur. Rajasthan <br />
                302033
              </p>

            </div>
          </div>)}


        </div>

        {/* <div className="address-to">
          <p className="label">Vendor Address</p>
          <p className="address">ABC Company Private Limited</p>
        </div> */}

        {isPdfView && (<div className="delivey-details">
          <div className="address-to">
            <p className="label">Vendor Address</p>
            <p className="address">ABC Company Private Limited</p>
            <p>DL. No.: 89878-89879</p>
            <p>GSTIN: 08AAGCD8761R</p>
          </div>

          <div className="deliver-to">
            <p className="label">Deliver To</p>
            <p className="address">
              103/3 Kumbha Marg Pratap Nagar Sanganare Jaipur 302033 Rajasthan
              India
            </p>
          </div>
          {/* <div className="date-to">
            <p className="label">Date:</p>
            <span className="date">10/10/2024</span>
          </div> */}
        </div>)}


        {!isPdfView && (<div className="delivey-details">
          <div className="address-to">
            <div className='d-flex' style={{ gap: '25px' }}>
              <div style={{ color: '#555B67', fontWeight: '600' }}>
                <p>ORDER DATE</p>
                <p>EXPECTED DELIVERY DATE</p>

              </div>
              <div style={{ color: '#000', fontWeight: '600' }}>
                <p>29/09/2024</p>
                <p>10/10/2024</p>

              </div>
            </div>

          </div>

          <div className="deliver-to">

          </div>
          {/* <div className="date-to">
            <p className="label">Date:</p>
            <span className="date">10/10/2024</span>
          </div> */}
        </div>)}




        {!isPdfView && (<table className="items">
          <thead>
            <tr>
              <th>#</th>
              <th>ITEMS AND DESCTIPTION</th>
              <th>ORDERED</th>
              <th>RECEIVED Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td onClick={onButtonClick} style={{ color: 'blue' }}>Product A</td>
              <td>Strip</td>
              <td>100</td>
              <td style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <img src={Delete} alt="" onClick={() => setIsDiscardQuantityModalOpen(true)} />
              </td>

            </tr>
            <tr>
              <td>2</td>
              <td onClick={onButtonClick} style={{ color: 'blue' }}>Product A</td>
              <td>Strip</td>
              <td>100</td>
              <td style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <img src={Delete} alt="" onClick={() => setIsDiscardQuantityModalOpen(true)} />
              </td>
            </tr>
          </tbody>

        </table>)}


        {isPdfView && (<table className="items">
          <thead>
            <tr>
              <th>#</th>
              <th>ITEMS AND DESCTIPTION</th>
              <th>PackaGING</th>
              <th>ORDERED</th>


            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td onClick={onButtonClick} style={{ color: 'blue' }}>Product A</td>
              <td>Strip</td>
              <td>100</td>

            </tr>
            <tr>
              <td>2</td>
              <td onClick={onButtonClick} style={{ color: 'blue' }}>Product A</td>
              <td>Strip</td>
              <td>100</td>
            </tr>
          </tbody>

        </table>)}



        {isPdfView && (
          <div className="signature" style={{ textAlign: 'start' }}>
            <p>Authorized Signature--------------------</p>
          </div>
        )}

      </div>
    </div>
  );
};

export default PurchaseDetails;
