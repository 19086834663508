import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import OtherDetails from './OtherDetails';
import NavigationTab from './NavigationTab';
import VendorsAddress from './VendorsAddress';
import ContactPerson from './ContactPerson';
import BankDetails from './BankDetails';
import UploadDoc from './VenUploadDocument';
import { createVendor, updateVendor, vendor_list } from '../../store/action/vendor';
import PageLoader from '../../component/PageLoader';
import { formatErrors } from '../../utils/controller';
import { toast } from 'react-toastify';
import Remark from './Remark';
import { isAllValuesEmpty, validateGSTNumber, validatePANCard } from '../../utils/utility';
import { master_data } from '../../store/action/master';
import { change_status } from '../../store/action/vendor';
import NavigationTabMain from '../vendors/VendorDetailsCom/NavigationTabMain';
import renderContent from './VendorDetailsCom/renderContent';
import { deleteVendorService } from '../../services/vendor';
import ConfirmationModal from '../../component/modal/ConfirmationModal';

const CreateVendors = () => {
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState({});
	const [disabled, setDisabled] = useState(id ? true : false); // Add state for disabling inputs
	const [pageLoader, setPageLoader] = useState(false);
	const isVendorDisplayNameTouched = useRef(false);
	const [clone, setClone] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [activeTab, setActiveTab] = useState('Other Details');
	const [activeTabMain, setActiveTabMain] = useState('Information');
	const [isDeleteVendorModalOpen, setIsDeleteVendorModalOpen] = useState(false);
	const [vendorStatusModal, setVendorStatusModal] = useState({
		isOpen: false,
		status: false,
	});
	const [isUpdateVendorModalOpen, setIsUpdateVendorModalOpen] = useState(false);

	function checkTabsData(obj) {
		return Object.values(obj).every((value) => value !== '');
	}

	// Table Data States
	const [vendorContact, setVendorContact] = useState([]);
	const [vendorBasicData, setVendorBasicData] = useState({
		vendor_name: '',
		vendor_display_name: '',
		drug_license_number: '',
		email: '',
		phone_landline: '',
		phone_mobile: '',
	});
	const [otherDetailsData, setOtherDetailsData] = useState({
		pan: '',
		gst: '',
		currency: 'INR',
		payment_terms: '',
		website_url: '',
		designation: '',
	});
	const [address, setAddress] = useState({
		attention: '',
		country_id: null,
		state_id: null,
		street_1: '',
		street_2: '',
		city: '',
		zip_code: '',
	});
	const [bankDetails, setBankDetails] = useState([]);
	const [fssaiDoc, setFassaiDoc] = useState(null);
	const [gstDoc, setGstDoc] = useState(null);
	const [drugLicanceDocs, setDrugLicanceDocs] = useState(null);
	const [panDocs, setPanDocs] = useState(null);
	const [adharDocs, setAdharDocs] = useState(null);
	const [remark, setRemark] = useState({
		remark_text: '',
	});
	const [vendorDocumentId, setVendorDocumentId] = useState(null);

	const vendoralldata = useSelector(
		(state) => state?.vendorReducer?.vendorList?.data?.vendors?.[0],
	);

	useEffect(() => {
		getProductById();
	}, [id]);

	useEffect(() => {
		if (!id && vendoralldata?.vendor_id !== Number(id)) return;

		let otherDetails = vendoralldata?.vendor_other_details;
		let address = vendoralldata?.vendor_address;
		let vendorDocuments = vendoralldata?.vendor_documents;

		setVendorDocumentId(vendorDocuments?.vendor_document_id || null);
		setGstDoc(vendorDocuments?.gst_certificate || null);
		setDrugLicanceDocs(vendorDocuments?.drug_license || null);
		setAdharDocs(vendorDocuments?.aadhar_card || null);
		setPanDocs(vendorDocuments?.pan_card || null);
		setFassaiDoc(vendorDocuments?.fssai_license || null);

		let vendorRemarks = vendoralldata?.vendor_remarks;

		setVendorContact(vendoralldata?.contact_person);
		setBankDetails(vendoralldata?.vendor_bank_details);
		setVendorBasicData({
			vendor_name: vendoralldata?.vendor_name ? vendoralldata?.vendor_name : '',
			vendor_display_name: vendoralldata?.vendor_display_name
				? vendoralldata?.vendor_display_name
				: '',
			drug_license_number: vendoralldata?.drug_license_number
				? vendoralldata?.drug_license_number
				: '',
			email: vendoralldata?.email ? vendoralldata?.email : '',
			phone_landline: vendoralldata?.phone_landline ? vendoralldata?.phone_landline : '',
			phone_mobile: vendoralldata?.phone_mobile ? vendoralldata?.phone_mobile : '',
		});
		setAddress({
			address_id: address?.address_id ? address?.address_id : null,
			attention: address?.attention ? address?.attention : '',
			country_id: address?.country_id ? address?.country_id : null,
			state_id: address?.state_id ? address?.state_id : null,
			street_1: address?.street_1 ? address?.street_1 : '',
			street_2: address?.street_2 ? address?.street_2 : '',
			city: address?.city ? address?.city : '',
			zip_code: address?.zip_code ? address?.zip_code : '',
		});
		setOtherDetailsData({
			vendor_other_details_id: otherDetails?.vendor_other_details_id
				? otherDetails?.vendor_other_details_id
				: null,
			pan: otherDetails?.pan ? otherDetails?.pan : '',
			gst: otherDetails?.gst ? otherDetails?.gst : '',
			currency: 'INR',
			payment_terms: otherDetails?.payment_terms ? 'Net ' + otherDetails?.payment_terms : '',
			website_url: otherDetails?.website_url ? otherDetails?.website_url : '',
			designation: otherDetails?.designation ? otherDetails?.designation : '',
		});
		setRemark({
			remark_text: vendorRemarks?.remark_text || '',
			vendor_remark_id: vendorRemarks?.vendor_remark_id || null,
		});
	}, [id, vendoralldata]);

	const getProductById = () => {
		if (id) {
			let param = `filterBy=vendor_id&filterValue=${id}`;
			dispatch(vendor_list(param));
		}
	};

	// master API call
	useEffect(() => {
		dispatch(master_data());
	}, [dispatch]);

	const navigateRouter = (path) => {
		navigate(path);
	};
	const handleBasicInputChange = (key, value) => {
		const updatedData = { ...vendorBasicData, [key]: value };
		setVendorBasicData(updatedData);

		if (!isVendorDisplayNameTouched.current && key === 'vendor_name') {
			setVendorBasicData({ ...updatedData, vendor_display_name: value });
		}

		if (key === 'drug_license_number') {
			// if (value.length > 3) {
			// 	if (!validDrugLicenseNumber(value)) {
			// 		const newError = { ...error, [key]: 'Drug license is not valid' };
			// 		setError(newError);
			// 	} else {
			// 		const newError = { ...error };
			// 		delete newError?.[key];
			// 		setError(newError);
			// 	}
			// }
		} else {
			if (value && error[key]) {
				if (key === 'vendor_name') {
					delete error?.vendor_display_name;
				}
				delete error?.[key];
			}
		}
	};
	//==============================================================

	const handleSubmit = () => {
		const newError = { ...error }; // Existing error state ko copy karein

		// Validation checks
		if (!vendorBasicData.vendor_name || vendorBasicData.vendor_name.trim() === '') {
			newError.vendor_name = 'Vendor name is required';
		} else {
			delete newError.vendor_name; // Agar fill kiya hai to error ko remove karein
		}

		if (!vendorBasicData.vendor_display_name || vendorBasicData.vendor_display_name.trim() === '') {
			newError.vendor_display_name = 'Vendor display name is required';
		} else {
			delete newError.vendor_display_name;
		}

		if (!vendorBasicData.drug_license_number || vendorBasicData.drug_license_number.trim() === '') {
			newError.drug_license_number = 'Drug license number is required';
		} else {
			delete newError.drug_license_number;
		}

		// Update error state if there are any errors
		if (Object.keys(newError).length > 0) {
			setError(newError);
			return;
		}
		// Continue with form submission logic here...
	};

	// Function to handle the validate icon click
	const handleValidateIconClick = (key) => {
		if (error?.[key]) {
			const newError = { ...error };
			delete newError[key];
			setError(newError);
		}
	};

	//==============================================================

	const handleOtherDetailsInputChange = (key, value) => {
		const updatedData = { ...otherDetailsData, [key]: value };
		setOtherDetailsData(updatedData);

		if (key === 'pan') {
			if (value.length > 3) {
				if (!validatePANCard(value)) {
					const newError = { ...error, [key]: 'Pan number is not valid' };
					setError(newError);
				} else {
					const newError = { ...error };
					delete newError?.[key];
					setError(newError);
				}
			}
		} else if (key === 'gst') {
			if (value.length > 3) {
				if (!validateGSTNumber(value)) {
					const newError = { ...error, [key]: 'Gst number is not valid' };
					setError(newError);
				} else {
					const newError = { ...error };
					delete newError?.[key];
					setError(newError);
				}
			}
		} else {
			if (value && error[key]) {
				delete error?.[key];
			}
		}
	};

	const handleAddressInputChange = (key, value) => {
		const updatedData = { ...address, [key]: value };

		setAddress(updatedData);
		if (value && error[key]) {
			delete error?.[key];
		}
	};

	const submitForm = () => {
		if (Object.keys(error).length > 0) {
			return;
		}
		setPageLoader(true);
		const formData = new FormData();
		const { currency, payment_terms, ...restOtherDetails } = otherDetailsData;
		const paymentTermValue = payment_terms.split(' ')[1];
		if (!id) {
			const vendorObj = {
				...vendorBasicData,
				vendor_other_details: !isAllValuesEmpty({
					...restOtherDetails,
					payment_terms: paymentTermValue,
				})
					? JSON.stringify([
						{ ...otherDetailsData, payment_terms: paymentTermValue || '', tds: null },
					])
					: '',
				address: !isAllValuesEmpty(address) ? JSON.stringify([address]) : '',
				contact_person: vendorContact?.length > 0 ? JSON.stringify(vendorContact) : '',
				bank_details: bankDetails?.length > 0 ? JSON.stringify(bankDetails) : '',
				remarks: remark.remark_text || '',
			};

			for (const key in vendorObj) {
				formData.append(key, vendorObj[key]);
			}

			if (gstDoc) {
				formData.append('gst_certificate_doc', gstDoc);
			}
			if (drugLicanceDocs) {
				formData.append('drug_license_doc', drugLicanceDocs);
			}
			if (panDocs) {
				formData.append('pan_card_doc', panDocs);
			}
			if (adharDocs) {
				formData.append('aadhar_card_doc', adharDocs);
			}
			if (fssaiDoc) {
				formData.append('fssai_license_doc', fssaiDoc);
			}

			dispatch(createVendor(formData))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						navigate(`/user/${suburl}/vendor`);
					} else {
						if (res?.code === 422) {
							let errorObj = formatErrors(res?.message);
							setError(errorObj);
						} else {
							toast.error(res?.message || 'Something went wrong');
						}
					}

					setPageLoader(false);
				})
				.catch((err) => {
					setError(err);
					setPageLoader(false);
				});
		}

		if (id) {
			const { currency, payment_terms, ...restOtherDetails } = otherDetailsData;
			const paymentTermValue = payment_terms?.split(' ')[1];

			const updateVendorObj = {
				...vendorBasicData,
				vendor_other_details: !isAllValuesEmpty({
					...restOtherDetails,
					payment_terms: paymentTermValue,
				})
					? JSON.stringify([
						{ ...otherDetailsData, payment_terms: paymentTermValue || '', tds: null },
					])
					: '',
				address: !isAllValuesEmpty(address) ? JSON.stringify([address]) : '',
				remarks: JSON.stringify([remark]) || '',
			};

			for (const key in updateVendorObj) {
				formData.append(key, updateVendorObj[key]);
			}

			dispatch(updateVendor(id, formData))
				.then((res) => {
					setPageLoader(false);
					if (res?.success) {
						toast.success(res?.message);
						setIsEdit(false);
						setDisabled(true);
					} else {
						if (res.code === 422) {
							let errorObj = formatErrors(res?.message);
							setError(errorObj);
						} else {
							toast.error(res?.message || 'Something went wrong');
						}
					}
				})
				.catch((err) => {
					setError(err);
					setPageLoader(false);
				});
		}
	};

	const markAsInactive = () => {
		let vendorIds = Number(id);
		let formBody = {
			vendor_ids: [vendorIds],
			status: 0,
		};
		dispatch(change_status(formBody)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				let param = `filterBy=vendor_id&filterValue=${id}`;
				dispatch(vendor_list(param));
			} else {
				toast.error(res?.message);
			}
		});
	};

	const markAsActive = () => {
		let vendorIds = Number(id);
		let formBody = {
			vendor_ids: [vendorIds],
			status: 1,
		};
		dispatch(change_status(formBody)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				let param = `filterBy=vendor_id&filterValue=${id}`;
				dispatch(vendor_list(param));
			} else {
				toast.error(res?.message);
			}
		});
	};

	// const cloneVendor = () => {
	// 	setVendorBasicData({
	// 		vendor_name: vendoralldata.vendor_name || '',
	// 		vendor_display_name: vendoralldata.vendor_display_name || '',
	// 		// drug_license_number: vendoralldata.drug_license_number || '',
	// 		email: vendoralldata.email || '',
	// 		phone_landline: vendoralldata.phone_landline || '',
	// 		phone_mobile: vendoralldata.phone_mobile || '',
	// 	});

	// 	setOtherDetailsData({
	// 		pan: vendoralldata.vendor_other_details?.pan || '',
	// 		gst: vendoralldata.vendor_other_details?.gst || '',
	// 		currency: 'INR',
	// 		payment_terms: vendoralldata.vendor_other_details?.payment_terms || '',
	// 		website_url: vendoralldata.vendor_other_details?.website_url || '',
	// 		designation: vendoralldata.vendor_other_details?.designation || '',
	// 	});

	// 	setAddress({
	// 		attention: vendoralldata.vendor_address?.attention || '',
	// 		country_id: vendoralldata.vendor_address?.country_id || null,
	// 		state_id: vendoralldata.vendor_address?.state_id || null,
	// 		street_1: vendoralldata.vendor_address?.street_1 || '',
	// 		street_2: vendoralldata.vendor_address?.street_2 || '',
	// 		city: vendoralldata.vendor_address?.city || '',
	// 		zip_code: vendoralldata.vendor_address?.zip_code || '',
	// 	});

	// 	// setRemark(vendorRemarks?.remark_text || '');
	// 	setDisabled(false);
	// 	navigateRouter(`/user/${suburl}/vendor-create`);
	// };

	async function handleDeleteVendor() {
		try {
			await deleteVendorService([id]);
			navigateRouter(`/user/${suburl}/vendor`);
			toast.success('Vendor Deleted Successfully.');
		} catch (error) {
			toast.success(error?.message || 'Something went wrong!');
		}
	}

	return (
		<>
			{pageLoader ? (
				<PageLoader />
			) : (
				<div className="">
					<div className="">
						<div className="product-pg-header add-product ">
							{id ? <p>Vendor Details</p> : <p>Add New Vendor</p>}
							<div className="add-product-button">
								{id && disabled && (
									<>
										<Button
											type="button"
											className="btn-primary mt-0 px-4"
											button="Edit"
											onClick={() => {
												setDisabled(false);
												setIsEdit(true);
												setActiveTabMain('Information'); // Navigate to Information tab
											}}
										/>

										<div className="dropdown">
											<button
												className="btn btn-light mt-0 px-4 dropdown-toggle"
												style={{
													width: '163px',
													height: '32px',
													fontSize: '14px',
													fontWeight: '500',
												}}
												type="button"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												New Transaction
											</button>
											<ul className="dropdown-menu font-14">
												<li>
													<a
														className="dropdown-item"
														href="#"
													>
														Bill
													</a>
												</li>
												<li>
													<a
														className="dropdown-item"
														href="#"
													>
														Bill Payment
													</a>
												</li>
												<li>
													<a
														className="dropdown-item"
														href="#"
													>
														Purchase Order
													</a>
												</li>
												<li>
													<a
														className="dropdown-item"
														href="#"
													>
														Purchase Receive
													</a>
												</li>
												<li>
													<a
														className="dropdown-item"
														href="#"
													>
														Vendor Credits
													</a>
												</li>
											</ul>
										</div>
										{vendoralldata?.status === 0 ? (
											//clone === false &&
											<Button
												type="button"
												className="btn-gray mt-0 px-4"
												button="Mark as Active"
												onClick={() => {
													setVendorStatusModal({
														isOpen: true,
														status: true,
													});
												}}
											/>
										) : (
											//clone === false &&
											<Button
												type="button"
												className="btn-gray mt-0 px-4"
												button="Mark as Inactive"
												onClick={() => {
													setVendorStatusModal({
														isOpen: true,
														status: false,
													});
												}}
											/>
										)}

										{/* Clone Button */}
										{/* <Button
											type="button"
											className="btn-gray mt-0 px-4"
											button="Clone"
											onClick={() => {
												cloneVendor();
												setActiveTabMain('Information'); // Navigate to Information tab
											}}
										/> */}

										<Button
											type="button"
											button="Delete"
											className="mt-0 px-4 btn-gray relative"
											onClick={() => {
												setIsDeleteVendorModalOpen(true);
											}}
										/>
									</>
								)}

								{disabled === false && (
									<Button
										type="button"
										className="btn-primary mt-0 px-4"
										button="Save"
										onClick={() => {
											if (isEdit) {
												setIsUpdateVendorModalOpen(true);
											} else {
												submitForm();
											}
										}}
									/>
								)}
								<Button
									type="button"
									className="mt-0 btn-danger add-pro"
									button=""
									close
									onClick={() => {
										if (isEdit) {
											setDisabled(true);
											setIsEdit(false);
											setActiveTabMain('Information'); // Navigate to Information tab
										} else {
											navigateRouter(`/user/${suburl}/vendor`);
										}
									}}
								/>
							</div>
						</div>
						<div
							style={{ backgroundColor: '#EDEDF7', marginTop: '-10px', paddingLeft: '20px' }}
							className={id ? '' : 'd-none'}
						>
							<NavigationTabMain
								activeTabMain={activeTabMain}
								setActiveTabMain={setActiveTabMain}
							/>
						</div>
						<div className="tab-content">
							{renderContent(activeTabMain)} {/* Pass activeTabMain here */}
						</div>
					</div>
					{/* Vendor Form */}
					<div className={activeTabMain === 'Information' ? '' : 'd-none'}>
						<div
							className="max-w-1297"
							style={{ padding: '1rem 2rem 0' }}
						>
							<div className={id ? '' : 'd-none'}>
								<div
									style={{
										borderBottom: '1px solid #EEEFF3',
										height: '75px',
										width: '100%',
										gap: '10px',
									}}
									className="d-flex"
								>
									<div
										style={{ width: '25%', height: '68px', marginTop: '-12px' }}
										className="d-flex flex-column"
									>
										<div>
											<h4
												className="fw-bold"
												style={{ fontSize: '16px' }}
											>
												Summary
											</h4>
										</div>
										<div>
											<p style={{ fontSize: '1.5rem', color: '#555B67' }}>
												Total Outstanding Payables
											</p>
										</div>
										<div>
											<h4
												className="fw-bold"
												style={{ fontSize: '16px' }}
											>
												₹3,45,675.00
											</h4>
										</div>
									</div>

									<div
										style={{
											height: '55px',
											width: '65%',
											marginTop: '10px',
											borderLeft: '1px solid #EEEFF3',
											paddingLeft: '55px',
										}}
										className="d-flex flex-column "
									>
										<div>
											<p style={{ fontSize: '1.5rem', color: '#555B67' }}>Due In Next 15Days</p>
										</div>
										<div>
											<h4
												className="fw-bold"
												style={{ fontSize: '16px' }}
											>
												₹3,45,675.00
											</h4>
										</div>
									</div>
								</div>
								{/* Summary End */}

								<div
									className="d-flex"
									style={{ gap: '10px', padding: '10px 0px 10px 0px ', fontSize: '1.5rem' }}
								>
									<b style={{ borderBottom: '2px solid #3B58FF', height: '24px' }}>Information</b>
									<p>Referrals</p>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<InputField
										label="Vendor Name"
										placeholder="Enter Vendor Name"
										type="text"
										required={true}
										value={vendorBasicData.vendor_name}
										onChange={(value) => handleBasicInputChange('vendor_name', value)}
										disabled={disabled}
										error={error?.vendor_name}
										autoFocus={true}
									/>
								</div>
								<div className="col">
									<InputField
										label="Vendor Display Name"
										placeholder="Vendor Display Name"
										type="text"
										onChange={(value) => handleBasicInputChange('vendor_display_name', value)}
										value={vendorBasicData.vendor_display_name}
										disabled={disabled}
										required={true}
										error={error?.vendor_display_name}
										onFocus={(e) => {
											isVendorDisplayNameTouched.current = true;
										}}
									/>
								</div>
								<div className="col">
									<InputField
										label="Drug License Number"
										placeholder="Drug License Number"
										type="text"
										required={true}
										value={vendorBasicData.drug_license_number}
										onChange={(value) => handleBasicInputChange('drug_license_number', value)}
										disabled={disabled}
										error={error?.drug_license_number}
										// showValidateIcon={true}
										onValidateIconClick={() => handleValidateIconClick('drug_license_number')}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<InputField
										label="Email"
										placeholder="Email Address"
										type="text"
										value={vendorBasicData.email}
										onChange={(value) => handleBasicInputChange('email', value)}
										disabled={disabled}
										error={error?.email}
									/>
								</div>

								<div className="col">
									<InputField
										label="Phone (Landline)"
										placeholder="Landline No."
										type="text"
										value={vendorBasicData.phone_landline}
										onChange={(value) => handleBasicInputChange('phone_landline', value)}
										disabled={disabled}
										error={error?.phone_landline}
									/>
								</div>

								<div
									className="col"
									style={{ paddingBottom: '15px' }}
								>
									<InputField
										label="Phone (Mobile)"
										placeholder="Vendor Mobile (Work)"
										type="text"
										value={vendorBasicData.phone_mobile}
										onChange={(value) => handleBasicInputChange('phone_mobile', value)}
										disabled={disabled}
										error={error?.phone_mobile}
									/>
								</div>
							</div>
							<NavigationTab
								activeTab={activeTab}
								setActiveTab={setActiveTab}
							/>
							{activeTab === 'Other Details' && (
								<OtherDetails
									data={otherDetailsData}
									handleInputChange={handleOtherDetailsInputChange}
									error={error}
									disabled={disabled}
									setError={setError}
								/>
							)}
							{activeTab === 'Address' && (
								<VendorsAddress
									data={address}
									error={error}
									handleInputChange={handleAddressInputChange}
									disabled={disabled}
								/>
							)}
							{activeTab === 'Contact person' && (
								<ContactPerson
									isEdit={isEdit}
									vendorContact={vendorContact}
									setVendorContact={setVendorContact}
									reloadTable={getProductById}
									vendorId={id}
								/>
							)}
							{activeTab === 'Bank Details' && (
								<BankDetails
									isEdit={isEdit}
									error={error}
									data={bankDetails}
									setBankDetails={setBankDetails}
									reloadTable={getProductById}
									vendorId={id}
								/>
							)}
							{activeTab === 'Upload Document' && (
								<UploadDoc
									setVendorDocumentId={setVendorDocumentId}
									vendorDocumentId={vendorDocumentId}
									setFassaiDoc={setFassaiDoc}
									setGstDoc={setGstDoc}
									setDrugLicanceDocs={setDrugLicanceDocs}
									setAdharCardDocs={setAdharDocs}
									setPanDocs={setPanDocs}
									fssaiDoc={fssaiDoc}
									gstDoc={gstDoc}
									drugLicanceDocs={drugLicanceDocs}
									adharDocs={adharDocs}
									panDocs={panDocs}
									reloadTable={getProductById}
									vendorId={id}
								/>
							)}

							{activeTab === 'Remarks' && (
								<Remark
									disabled={disabled}
									setRemark={setRemark}
									remark={remark}
								/>
							)}
						</div>
					</div>

					{isUpdateVendorModalOpen && (
						<ConfirmationModal
							content={
								<p
									style={{
										textAlign: 'center',
										backgroundColor: '#E6F5FF',
										padding: '10px',
										borderRadius: '5px',
										fontWeight: '500',
										color: 'black',
									}}
								>
									Are you sure you want to update{' '}
									<span
										style={{
											fontWeight: '600',
										}}
									>
										{vendorBasicData.vendor_name}
									</span>{' '}
									data ?
								</p>
							}
							isOpen={isUpdateVendorModalOpen}
							title="Update Vendor"
							onCancel={() => {
								setIsUpdateVendorModalOpen(false);
							}}
							onOk={() => {
								if (
									!vendorBasicData.drug_license_number ||
									vendorBasicData.drug_license_number.trim() === ''
								) {
									handleSubmit();
								} else {
									submitForm();
								}
								setIsUpdateVendorModalOpen(false);
							}}
						/>
					)}

					{isDeleteVendorModalOpen && (
						<ConfirmationModal
							content={
								<p
									style={{
										textAlign: 'center',
										backgroundColor: '#E6F5FF',
										padding: '10px',
										borderRadius: '5px',
										fontWeight: '500',
										color: 'black',
									}}
								>
									Are you sure you want to delete{' '}
									<span
										style={{
											fontWeight: '600',
										}}
									>
										{vendorBasicData.vendor_name}
									</span>{' '}
									Vendor ?
								</p>
							}
							isOpen={isDeleteVendorModalOpen}
							title="Delete Vendor"
							onCancel={() => {
								setIsDeleteVendorModalOpen(false);
							}}
							onOk={handleDeleteVendor}
						/>
					)}

					{vendorStatusModal.isOpen && (
						<ConfirmationModal
							content={
								<p
									style={{
										textAlign: 'center',
										backgroundColor: '#E6F5FF',
										padding: '10px',
										borderRadius: '5px',
										fontWeight: '500',
										color: 'black',
									}}
								>
									Are you sure you want to mark{' '}
									<span
										style={{
											fontWeight: '600',
										}}
									>
										{vendorBasicData.vendor_name}{' '}
									</span>
									as {vendorStatusModal.status ? ' Active ' : ' Inactive '}?
								</p>
							}
							isOpen={vendorStatusModal.isOpen}
							title={`${vendorStatusModal.status ? 'Active' : 'Inactive '} Vendor`}
							onCancel={() => {
								setVendorStatusModal((prev) => ({
									...prev,
									isOpen: false,
								}));
							}}
							onOk={() => {
								if (vendorStatusModal.status) {
									markAsActive();
								} else {
									markAsInactive();
								}
								setVendorStatusModal((prev) => ({
									isOpen: false,
								}));
							}}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default CreateVendors;
