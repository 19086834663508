// NotFound.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="error-code">404</div>
      <div className="error-message">Oops! Page Not Found</div>
      <div className="back-home-link">
        <Link to="/user/home" className="link-text">Back to Home</Link>
      </div>
    </div>
  );
};

export default NotFound;
