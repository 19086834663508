// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FILE UPLOAD */
.image-upload-component .dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 23rem;
  border: 1px dashed rgba(85, 91, 103, 0.15);
  padding: 2rem;
  background: rgba(218, 218, 218, 0.3411764706);
  border-radius: 1rem;
  width: calc(100% - 15rem);
  margin: auto;
}
.image-upload-component .dropzone img {
  width: 6rem;
}
.image-upload-component .file_list > div {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 16rem);
  margin: auto;
  align-items: center;
  margin-top: 2rem;
  border-bottom: 1px solid #C3CBDC;
}
.image-upload-component .file_list > div:last-child {
  border-bottom: none;
}
.image-upload-component .file_list > div .file_name {
  overflow: hidden;
  display: flex;
}
.image-upload-component .file_list > div .file_name span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/vendors/uploadDocument/uploadDocument.scss"],"names":[],"mappings":"AAAA,gBAAA;AAEE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,0CAAA;EACA,aAAA;EACA,6CAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;AAAJ;AACI;EACE,WAAA;AACN;AAGI;EACE,aAAA;EACA,8BAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,gCAAA;AADN;AAGM;EACE,mBAAA;AADR;AAGM;EACE,gBAAA;EACJ,aAAA;AADJ;AAEI;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;AAAN","sourcesContent":["/* FILE UPLOAD */\n.image-upload-component{\n  .dropzone {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 23rem;\n    border: 1px dashed rgba(85, 91, 103, 0.15);\n    padding: 2rem;\n    background: rgba(218, 218, 218, 0.3411764706);\n    border-radius: 1rem;\n    width: calc(100% - 15rem);\n    margin: auto;\n    img{\n      width: 6rem;\n    }\n    }\n    \n    .file_list>div{\n      display: flex;\n      justify-content: space-between;\n      width: calc(100% - 16rem);\n      margin: auto;\n      align-items: center;\n      margin-top: 2rem;\n      border-bottom:1px solid #C3CBDC;\n\n      &:last-child{\n        border-bottom: none;\n      }\n      .file_name{\n        overflow: hidden;\n    display: flex;\n    span{\n      overflow: hidden;\n      text-overflow: ellipsis;\n      padding-right: 3rem;\n    }\n      }\n    }\n}\n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
