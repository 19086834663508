import { USER_INFO_GET_DONE, USER_INFO_GET_FAIL } from "../../action/userInfo";

const initialState = {
    loading: false,
    userInfo: {},
    error: '',
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_INFO_GET_DONE:
            return {
                ...state,
                loading: false,
                userInfo: action.payload,
                error: '',
            };
        case USER_INFO_GET_FAIL:
            return {
                ...state,
                loading: false,
                userInfo: {},
                error: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;
