import React from 'react';

const PasswordPolicy = ({ password }) => {
    const cases = [
        { text: '1 uppercase', match: /[A-Z]/ },
        { text: '1 lowercase', match: /[a-z]/ },
        { text: '1 number', match: /[0-9]/ },
        { text: '1 special character', match: /\W|_/g },
        { text: 'Minimum 8 characters.', match: /^.{8,35}$/ }
    ];

    // Check if all password requirements are satisfied
    const allRequirementsMet = cases.every(({ match }) => String(password).match(match));

    const checkRequirement = (text, match) => {
        return String(password).match(match) ? (
            <span className="text-success">
                <i className="fa fa-check-circle" aria-hidden="true"></i> {text}
            </span>
        ) : (
            <span className="text-danger">{text}</span>
        );
    };

    return (
        <div style={{ display: allRequirementsMet ? 'none' : 'block' }}>
            <div>
                <p>Password must contain</p>
                <ul className=''>
                    {cases.map(({ text, match }, index) => (
                        <span key={index} className='mx-1'>{checkRequirement(text, match)}</span>
                        // <li key={index}>{checkRequirement(text, match)},</li>

                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PasswordPolicy;
