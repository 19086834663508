import React, { useState } from 'react';
import './ContactPersons.scss';
import CombinedShape from '../../../assets/image/Combined-Shape.svg'

const OpenPOSTab = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="purchase-order-card">
        {/* Status Bar */}
        <div className="status-bar">
          Pending Approval
        </div>

        {/* Header */}
        <div className="order-header" onClick={toggleDropdown}>
          <div className="order-info">
            <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
            <span className="order-date">26/09/2024</span>
          </div>
          <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
            <div className="order-summary d-flex flex-column">
              <span>Total Items: 10</span>
              <span className="expected-date">EXPECTED BY 10/10/2024</span>
            </div>
            <div>
              <span>{isOpen ? '▲' : '▼'}</span>
            </div>
          </div>

        </div>

        {/* Dropdown Content */}
        {isOpen && (
          <div className="order-items">
            {Array(5).fill().map((_, index) => (
              <div key={index} className="order-item">
                <div>Alcet</div>
                <div>Strip</div>
                <div>200</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <br />


      <div className="purchase-order-card">
        {/* Status Bar */}
        <div className="status-bar" style={{ backgroundColor: '#3B58FF' }}>
          Partially Received
        </div>

        {/* Header */}
        <div className="order-header" onClick={toggleDropdown}>
          <div className="order-info">
            <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
            <span className="order-date">26/09/2024</span>
          </div>
          <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
            <div className="order-summary d-flex flex-column">
              <span>Total Items: 10</span>
              <span className="expected-date">EXPECTED BY 10/10/2024</span>
            </div>
            <div>
              <span>{isOpen ? '▲' : '▼'}</span>
            </div>
          </div>

        </div>

        {/* Dropdown Content */}
        {isOpen && (
          <div className="order-items">
            {Array(5).fill().map((_, index) => (
              <div key={index} className="order-item">
                <div>Alcet</div>
                <div>Strip</div>
                <div>200</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <br />
      <br />

    </div>
  );
};

export default OpenPOSTab;
