// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-bank-detail-modal {
  width: 700px;
}

.bankDetailsBody {
  max-width: 60rem;
}
.bankDetailsBody .headr {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.bankDetailsBody .headr p {
  flex: 1 1;
  font-family: "PlusJakartaSans-Bold";
  font-size: 1.8rem;
}
.bankDetailsBody .headr button {
  flex: 0 1;
}
.bankDetailsBody .accDetails {
  display: flex;
  align-items: center;
}
.bankDetailsBody .accDetails .accIcon {
  flex: 0 1;
  background: #e5e6e8;
}
.bankDetailsBody .accDetails .accIcon img {
  border-radius: 10px;
}
.bankDetailsBody .accDetails .accName {
  flex: 1 1;
  margin-left: 1.5rem;
}
.bankDetailsBody .accDetails .accName div:first-child {
  font-family: "PlusJakartaSans-Bold";
}
.bankDetailsBody .accDetails .accName .numberView {
  margin-left: 6rem;
  color: var(--brand-color);
  text-decoration: underline;
  cursor: pointer;
}
.bankDetailsBody .accDetails .accEdit {
  flex: 0 1;
  margin: 0 1.5rem;
  cursor: pointer;
}
.bankDetailsBody .codeName {
  display: flex;
  margin: 1.5rem 0;
}
.bankDetailsBody .codeName span:nth-child(1) {
  flex: 1 1;
}
.bankDetailsBody .codeName span:nth-child(2) {
  flex: 2 1;
  font-family: "PlusJakartaSans-Bold";
}`, "",{"version":3,"sources":["webpack://./src/pages/vendors/BankDetails/BankDetails.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;;AAEA;EACC,gBAAA;AACD;AACC;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACC,SAAA;EACA,mCAAA;EACA,iBAAA;AACH;AAEE;EACC,SAAA;AAAH;AAIC;EACC,aAAA;EACA,mBAAA;AAFF;AAIE;EACC,SAAA;EACA,mBAAA;AAFH;AAIG;EACC,mBAAA;AAFJ;AAME;EACC,SAAA;EACA,mBAAA;AAJH;AAMG;EACC,mCAAA;AAJJ;AAOG;EACC,iBAAA;EACA,yBAAA;EACA,0BAAA;EACA,eAAA;AALJ;AASE;EACC,SAAA;EACA,gBAAA;EACA,eAAA;AAPH;AAWC;EACC,aAAA;EACA,gBAAA;AATF;AAWE;EACC,SAAA;AATH;AAYE;EACC,SAAA;EACA,mCAAA;AAVH","sourcesContent":[".add-bank-detail-modal {\n\twidth: 700px;\n}\n\n.bankDetailsBody {\n\tmax-width: 60rem;\n\n\t.headr {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-bottom: 1rem;\n\n\t\tp {\n\t\t\tflex: 1;\n\t\t\tfont-family: 'PlusJakartaSans-Bold';\n\t\t\tfont-size: 1.8rem;\n\t\t}\n\n\t\tbutton {\n\t\t\tflex: 0;\n\t\t}\n\t}\n\n\t.accDetails {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\n\t\t.accIcon {\n\t\t\tflex: 0;\n\t\t\tbackground: #e5e6e8;\n\n\t\t\timg {\n\t\t\t\tborder-radius: 10px;\n\t\t\t}\n\t\t}\n\n\t\t.accName {\n\t\t\tflex: 1;\n\t\t\tmargin-left: 1.5rem;\n\n\t\t\tdiv:first-child {\n\t\t\t\tfont-family: 'PlusJakartaSans-Bold';\n\t\t\t}\n\n\t\t\t.numberView {\n\t\t\t\tmargin-left: 6rem;\n\t\t\t\tcolor: var(--brand-color);\n\t\t\t\ttext-decoration: underline;\n\t\t\t\tcursor: pointer;\n\t\t\t}\n\t\t}\n\n\t\t.accEdit {\n\t\t\tflex: 0;\n\t\t\tmargin: 0 1.5rem;\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n\t.codeName {\n\t\tdisplay: flex;\n\t\tmargin: 1.5rem 0;\n\n\t\tspan:nth-child(1) {\n\t\t\tflex: 1;\n\t\t}\n\n\t\tspan:nth-child(2) {\n\t\t\tflex: 2;\n\t\t\tfont-family: 'PlusJakartaSans-Bold';\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
