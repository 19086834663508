import { toast } from "react-toastify";

// Function to generate a strong password
export function generateStrongPassword(length = 8) {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const special = '@$%';
    
    const allCharacters = uppercase + lowercase + numbers + special;
    let password = '';
    
    // Ensure the password contains at least one character from each category
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += special[Math.floor(Math.random() * special.length)];
    
    // Fill the remaining length of the password
    for (let i = password.length; i < length; i++) {
        password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }
    
    // Shuffle the characters to avoid predictable patterns
    password = password.split('').sort(() => Math.random() - 0.5).join('');
    
    return password;
}

// Function to copy text to the clipboard
export function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
        toast.success('Password copied to clipboard!');
    }).catch(err => {
        console.error('Could not copy text: ', err);
    });
}
export const encodeEmailToToken = (email) => {
    return btoa(email);
};

export const decodeTokenToEmail = (token) => {
    return atob(token);
};

export const objectCreater = (array) => {
    return array?.map(array => ({
        value: parseInt(array?.id),
        label: array?.name?.charAt(0) + array.name.slice(1).toLowerCase().replace(/&/, 'and')
    }));
};

export const formatErrors = (errorArray) => {
    if (typeof errorArray === 'object' && Object.keys(errorArray).length > 0) {
        return errorArray?.reduce((acc, error) => {
            let field = error.path[0];
            let message = error.message;

            // Remove '_id' from the field name
            // if (field.endsWith('_id')) {
            //     field = field.slice(0, -3);
            // }

            // Format the field name by replacing underscores with spaces and capitalizing each word
            const formattedField = field.split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');

            // Remove the field name from the message to start with the actual error message
            message = message.substring(message.indexOf(' ') + 1);

            acc[field] = `${formattedField} ${message}`;
            return acc;
        }, {});
    }
};