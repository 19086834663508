import ImageRenderer from "../../../component/imageRenderer/imageRnder";

export const ProductTableColumn = [
    {
        field: " ",
        headerClass: 'HeaderBg',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        minWidth: 50,
        maxWidth: 50,
        lockPosition: true,
    },
    {
        field: "IMAGE",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 50,
        maxWidth: 80,
        flex: 1,
        cellRenderer:ImageRenderer
    },

    {
        field: "PRODUCT CODE",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1
    },
    {
        field: "PRODUCT NAME",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 200,
        flex: 1
    },
    {
        field: "STOCK IN HAND",
        headerClass: 'HeaderBg right-align',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
        cellClass: 'right-align',
    },
    {
        field: "REORDER LEVEL",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
    },
];