// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custumCheckBox {
  height: 15px;
  width: 15px;
}

.form-check-input {
  width: 2rem;
  height: 2rem;
  margin-top: 0rem;
  margin-right: 0.8rem;
  background-color: var(--white);
  border-radius: 0.6rem !important;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/component/checkbox/checkBoxStyles.scss"],"names":[],"mappings":"AAAC;EACC,YAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;EACA,8BAAA;EACE,gCAAA;EACA,eAAA;AACJ","sourcesContent":[" .custumCheckBox{\n  height: 15px;\n  width: 15px;\n }\n\n.form-check-input {\n  width: 2rem;\n  height: 2rem;\n  margin-top: 0rem;\n  margin-right: .8rem;\n  background-color: var(--white);\n    border-radius: .6rem !important;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
