import React, { useEffect, useState } from 'react';
import InputField from '../../../component/form/FormInput';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../component/ui/Button';
import InputSelect from '../../../component/inputSelect/InputSelect';
import AdjustmentTable from './AdjustmentTable';
import { ObjectCreate } from '../../../utils/utility';
import { useDispatch, useSelector } from 'react-redux';
import {
	getRefNumber,
	inventoryAdjustmentConvert,
	inventoryAdjustmentCreate,
	inventoryAdjustmentDelete,
	inventoryAdjustmentList,
	inventoryAdjustmentReverted,
	inventoryAdjustmentUpdate,
} from '../../../store/action/inventory';
import { toast } from 'react-toastify';
import { master_data } from '../../../store/action/master';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { formatErrors } from '../../../utils/controller';
import ConfirmationModal from '../../../component/modal/ConfirmationModal';

const Adjustment = () => {
	const dispatch = useDispatch();
	const today = new Date().toISOString().split('T')[0];
	const navigate = useNavigate();
	const param = useParams();
	const InventoryAdjustmentId = param?.id;
	const [refnumber, setrefnumber] = useState('');
	const [date, setDate] = useState(today);
	const [account, setAccount] = useState('');
	const [reason, setReason] = useState('');
	const [description, setDescription] = useState('');
	const [tableData, setTableData] = useState([]);

	const [error, setError] = useState({});
	const [loader, setLoader] = useState(false);
	const [convrsionLoader, setConversionLoader] = useState(false);
	const [inventoryTableError, setInventoryTableError] = useState(false);
	const [isErrortable, setIsTableError] = useState(false);
	const [reasonOption, setReasonOption] = useState([]);
	const [accountOption, setAcctountOption] = useState([]);
	const [ModalState, setModalState] = useState({
		title: '',
		content: '',
		handleSubmit: null,
	});
	const [showModal, setShowModal] = useState(false);
	const [disabled, setDisabled] = useState(InventoryAdjustmentId ? true : false); // Determines if inputs should be disabled

	const masterData = useSelector((state) => state?.master?.masterData?.data);
	const inventoryAdjustMentList = useSelector(
		(state) => state?.inventory?.list?.data?.data?.inventoryAdjustment,
	);

	useEffect(() => {
		let param = `filterBy=adjustment_id&filterValue=${InventoryAdjustmentId}`;
		dispatch(master_data());
		dispatch(inventoryAdjustmentList(param)).then((res) => {
			if (res?.success) {
			}
		});
	}, [dispatch]);

	useEffect(() => {
		if (!InventoryAdjustmentId) {
			dispatch(getRefNumber()).then((res) => {
				if (res?.success) {
					setrefnumber(res?.data);
				} else {
				}
			});
		}
	}, []);

	useEffect(() => {
		setTableData([]);
		if (InventoryAdjustmentId) {
			let data = inventoryAdjustMentList?.[0];
			let adjusmentdetails = [];
			data?.adjusmentdetails?.map((item) => {
				adjusmentdetails?.push({
					product_id: item?.product?.product_name,
					batch_id: item?.product_batch?.batch_no?.toString(),
					quantity_adjusted: item?.quantity_adjusted,
					adjustment_details_id: item?.adjustment_details_id,
					batch_no: item?.product_batch_id,
					product_name: item?.product_id,
					qty: item?.product_batch?.quantity,
					qtyHand: `${
						data.is_draft === 0
							? Number(item?.product_batch?.quantity) - Number(item?.quantity_adjusted)
							: Number(item?.product_batch?.quantity) + Number(item?.quantity_adjusted)
					}`,
				});
			});
			setrefnumber(data?.reference_number);
			setDate(data?.date);
			setAccount(data?.account_id || '');
			setReason(data?.reason_id || '');
			setDescription(data?.description || '');
			setTableData(adjusmentdetails);
		}
	}, [inventoryAdjustMentList]);
	useEffect(() => {
		const reason = ObjectCreate({
			data: masterData?.reasons,
			key: 'reason_id',
			value: 'reason_description',
		});
		const account = ObjectCreate({
			data: masterData?.accounts,
			key: 'account_id',
			value: 'account_name',
		});
		setReasonOption(reason);
		setAcctountOption(account);
	}, [masterData]);

	const saveinventoryAdjust = (value) => {
		if (isErrortable === false) {
			if (value === 1 && InventoryAdjustmentId) {
				setConversionLoader(true);
				// convert to adjustment
				dispatch(inventoryAdjustmentConvert(InventoryAdjustmentId))
					.then((res) => {
						if (res?.success) {
							toast.success(res?.message);
							navigate(-1);
							setLoader(false);
						} else {
							toast.error(res?.message);
							setLoader(false);
						}
					})
					.catch((error) => {
						setLoader(false);
					});
			} else {
				// save as draft
				setLoader(true);
				let adjustMentDetils = [];
				tableData?.map((ele) => {
					adjustMentDetils?.push({
						// product_id: ele?.product_id,
						product_id: isNaN(ele?.product_id) ? ele?.product_name : ele?.product_id,
						batch_id: ele?.batch_id,
						quantity_adjusted: Number(ele?.quantity_adjusted),
					});
				});
				// Handle logic to save as draft
				const formData = {
					adjustment_date: date,
					account_id: account ? Number(account) : '',
					reason_id: reason ? Number(reason) : '',
					description: description,
					is_draft: value, // Assuming it's a draft
					adjustment_details: adjustMentDetils,
					reference_number: refnumber,
				};
				dispatch(inventoryAdjustmentCreate(formData))
					.then((res) => {
						if (res?.success) {
							toast.success(res?.message);
							navigate(-1);
							setLoader(false);
						} else {
							toast.error(res?.message);
							let error = formatErrors(res?.data?.message);
							setInventoryTableError(true);
							toast.error(error?.adjustment_details);
							setError(error);
							setLoader(false);
						}
					})
					.catch((error) => {
						toast.error(error?.message);
						setLoader(false);
						setConversionLoader(false);
					});
			}
		} else {
			toast.error('Error in add inventory table to know more click on show all error .');
			setInventoryTableError(true);
		}
		setLoader(false);
		setConversionLoader(false);
	};

	const handledelete = () => {
		dispatch(inventoryAdjustmentDelete(InventoryAdjustmentId)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				navigate(-1);
			} else {
				toast.error(res?.message);
			}
		});
	};
	const navigateRouter = (path) => {
		navigate(path);
	};

	const updateInventory = () => {
		let id = InventoryAdjustmentId;
		let adjustMentDetils = [];
		tableData?.map((ele) => {
			adjustMentDetils?.push({
				product_id: isNaN(ele?.product_id) ? ele?.product_name : ele?.product_id,
				batch_id: isNaN(ele?.batch_id) ? ele?.batch_no : ele?.batch_id,
				quantity_adjusted: Number(ele?.quantity_adjusted),
				adjustment_details_id: Number(ele?.adjustment_details_id),
			});
		});
		// Handle logic to save as draft
		const formData = {
			adjustment_date: date,
			account_id: account ? Number(account) : '',
			reason_id: reason ? Number(reason) : null,
			description: description || '',
			is_draft: 1, // Assuming it's a draft
			adjustment_details: adjustMentDetils,
			reference_number: refnumber,
		};
		dispatch(inventoryAdjustmentUpdate(id, formData)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				navigate(-1);
			} else {
			}
		});
	};
	const downloadPDF = () => {
		const input = document.getElementById('pdf-content');

		html2canvas(input).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'mm', 'a4');
			const imgWidth = 210;
			const pageHeight = 295;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;
			let heightLeft = imgHeight;
			let position = 0;

			pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;

			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				pdf.addPage();
				pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
			}

			pdf.save('page.pdf');
		});
	};
	const revertAsAdjusted = () => {
		setConversionLoader(true);
		// Revert to adjustment
		dispatch(inventoryAdjustmentReverted(InventoryAdjustmentId))
			.then((res) => {
				if (res?.success) {
					toast.success(res?.message);
					navigate(-1);
					setLoader(false);
				} else {
					toast.error(res?.message);
					setLoader(false);
				}
			})
			.catch((error) => {
				setLoader(false);
			});
	};

	const handleConfirmationModal = (data) => {
		if (data === 'delete') {
			setModalState({
				title: 'Inventory Adjustment',
				content: 'Are you sure want to delete ?',
				handleSubmit: handledelete,
			});
		} else if (data === 'reverted') {
			setModalState({
				title: 'Inventory Adjustment',
				content: 'Are you sure you want to revert this entry?',
				handleSubmit: revertAsAdjusted,
			});
		}
		setShowModal(true);
	};
	const handleAccount = (value) => {
		setAccount(value);
		if (value) {
			setError({});
		}
	};
	return (
		<div id="pdf-content">
			<div className="product-pg-header add-product">
				<p>Add New Adjustment</p>
				<div className="add-product-button">
					{disabled === true && inventoryAdjustMentList?.[0]?.is_draft === 0 && (
						<Button
							type="button"
							className="btn-gray mt-0 px-4"
							button="Edit"
							onClick={() => setDisabled(false)}
						/>
					)}
					{disabled === true && (
						<Button
							type="button"
							className="btn-gray mt-0 px-4"
							button="Print"
							onClick={() => window.print()}
						/>
					)}
					{disabled === true && (
						<Button
							type="button"
							className="btn-gray mt-0 px-4"
							button="Download"
							onClick={downloadPDF}
						/>
					)}
					{/* {disabled === true && <Button type="button" className="btn-gray mt-0 px-4" button="Clone" onClick={() => setDisabled(false)} />} */}

					{InventoryAdjustmentId &&
						disabled === true &&
						inventoryAdjustMentList?.[0]?.is_draft === 0 && (
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Delete"
								onClick={() => handleConfirmationModal('delete')}
							/>
						)}

					{disabled === false && !InventoryAdjustmentId && (
						<Button
							type="button"
							className="mt-0 w-100 px-5 btn-primary"
							button="Save as Draft"
							onClick={() => saveinventoryAdjust(0)}
							loader={loader}
						/>
					)}
					{disabled === false && InventoryAdjustmentId && (
						<Button
							type="button"
							className="mt-0 w-100 px-5 btn-primary"
							button="Save as Draft"
							onClick={updateInventory}
						/>
					)}
					{((disabled === false && !InventoryAdjustmentId) ||
						inventoryAdjustMentList?.[0]?.is_draft === 0) && (
						<Button
							type="button"
							className="mt-0 w-100 px-5 btn-primary"
							button="Convert as Adjusted"
							onClick={() => saveinventoryAdjust(1)}
							loader={convrsionLoader}
						/>
					)}
					{inventoryAdjustMentList?.[0]?.is_draft === 1 && (
						<Button
							type="button"
							className="mt-0 w-100 px-5 btn-primary"
							button="Revert as Adjusted"
							onClick={() => handleConfirmationModal('reverted')}
							//   onClick={revertAsAdjusted}
							loader={convrsionLoader}
						/>
					)}
					{
						<Button
							type="button"
							className="mt-0 btn-danger add-pro"
							close
							button=""
							onClick={() => navigateRouter(-1)}
						/>
					}
				</div>
			</div>
			<div
				className="max-w-1297"
				style={{ padding: '1rem 2rem 0' }}
			>
				<div className="row">
					<div className="col-6">
						<InputField
							label="Reference Number"
							placeholder="Ref#"
							type="text"
							autoFocus={true}
							onChange={(val) => setrefnumber(val)}
							value={refnumber}
							disabled
							required={true}
						/>
					</div>
					<div className="col-6">
						<InputField
							label="Date"
							placeholder="Enter Date"
							type="date"
							onChange={(val) => setDate(val)}
							value={date}
							autoFocus={disabled ? false : true}
							disabled={disabled}
							required={true}
							error={error?.date}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<InputSelect
							label="Account"
							placeholder="Select Account"
							onChange={(value) => handleAccount(value)}
							value={account}
							options={accountOption}
							disabled={disabled}
							required={true}
							error={error?.account_id}
						/>
					</div>
					<div className="col-6">
						<InputSelect
							label="Reason"
							placeholder="Select Reason"
							onChange={(value) => setReason(value)}
							value={reason}
							options={reasonOption}
							disabled={disabled}
							error={error?.reason}
						/>
					</div>
				</div>
				<div className="row my-1">
					<div className="col-12">
						<InputField
							label="Description"
							placeholder="Provides notes if any"
							type="text"
							onChange={(val) => setDescription(val)}
							value={description}
							disabled={disabled}
							error={error?.description}
						/>
					</div>
				</div>
			</div>
			<AdjustmentTable
				tableData={tableData}
				setTableData={setTableData}
				disabled={disabled}
				paramId={InventoryAdjustmentId}
				tableError={inventoryTableError}
				setIsTableErrorShow={setIsTableError}
			/>
			{showModal && (
				<ConfirmationModal
					className="add-productgroup-modal"
					isOpen={showModal}
					onClose={() => setShowModal(false)}
					title={ModalState?.title}
					content={ModalState?.content}
					handleSubmit={ModalState?.handleSubmit}
				/>
			)}
		</div>
	);
};

export default Adjustment;
