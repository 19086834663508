import React, { useState } from 'react';
import InputTable from '../../../../component/InputTable/InputTable';

const AddProductTable = ({
	tableData,
	setTableData,
	disabled,
	className,
	productTabelError,
	setIsTableError,
}) => {
	const handleTableChange = (newData) => {
		setTableData(newData);
	};

	const columns = [
		{ name: 'batch_no', label: 'Batch No', type: 'batch_no' },
		{ name: 'quantity', label: 'Qty', type: 'text' },
		{ name: 'free_quantity', label: 'Free Qty', type: 'text' },
		{ name: 'mfg_date', label: 'MFG Date', type: 'date' },
		{ name: 'expiry_date', label: 'EXP Date', type: 'date' },
		{ name: 'mrp', label: 'MRP', type: 'text' },
		{ name: 'trade_rate', label: 'Trade Rate', type: 'text' },
		{ name: 'purchase_rate', label: 'Purchase Rate', type: 'text' },
		{ name: 'sale_rate', label: 'Sale Rate', type: 'text',  width: '250px' },
	];

	const initialRows = [
		{
			batch_no: '',
			quantity: '0',
			free_quantity: '0',
			mfg_date: '',
			expiry_date: '',
			mrp: '0.00',
			trade_rate: '0.00',
			purchase_rate: '0.00',
			sale_rate: '0.00',
			product_batch_id: '',
		},
	];
	return (
		<div
			className={`add-product-inputTable ${className}`}
			style={{ padding: '0rem 2rem', marginTop: '0' }}
		>
			<InputTable
				className="th-width"
				initialRows={initialRows}
				columns={columns}
				onChange={handleTableChange}
				value={tableData}
				disabled={disabled}
				newRowgenerate={'Sale Rate'}
				returnInputvalue={true}
				Tabletype={'Product'}
				productTabelError={productTabelError}
				setIsTableError={setIsTableError}
			/>
		</div>
	);
};

export default AddProductTable;
