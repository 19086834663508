// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 10;
}

.modal-content-confirmation-modal {
  width: 500px;
  background: white;
  border-radius: 10;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: slide-down 0.3s ease-out forwards;
}

.modal-content {
  background: white;
  width: 50%;
  border-radius: 10;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: slide-down 0.3s ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #f6f6f9;
}

.modal-body {
  padding: 10px 20px 20px 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/component/modal/modal.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,iBAAA;AACD;;AAEA;EACC,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,yCAAA;EACA,4CAAA;AACD;;AAEA;EACC,iBAAA;EAEA,UAAA;EAEA,iBAAA;EACA,yCAAA;EACA,4CAAA;AADD;;AAIA;EACC;IACC,UAAA;IACA,2BAAA;EADA;EAGD;IACC,UAAA;IACA,wBAAA;EADA;AACF;AAIA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AAFD;;AAMA;EACC,4BAAA;AAHD;;AAMA;EACC,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,2BAAA;AAHD;;AAMA;EACC,WAAA;AAHD","sourcesContent":[".modal-backdrop {\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tbackground: rgba(0, 0, 0, 0.5);\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tz-index: 1000;\n\tborder-radius: 10;\n}\n\n.modal-content-confirmation-modal {\n\twidth: 500px;\n\tbackground: white;\n\tborder-radius: 10;\n\tbox-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n\tanimation: slide-down 0.3s ease-out forwards;\n}\n\n.modal-content {\n\tbackground: white;\n\t// border-radius: 8px;\n\twidth: 50%;\n\t// max-width: 500px;\n\tborder-radius: 10;\n\tbox-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n\tanimation: slide-down 0.3s ease-out forwards;\n}\n\n@keyframes slide-down {\n\tfrom {\n\t\topacity: 0;\n\t\ttransform: translateY(-10%);\n\t}\n\tto {\n\t\topacity: 1;\n\t\ttransform: translateY(0);\n\t}\n}\n\n.modal-header {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding: 10px 20px;\n\tbackground: #f6f6f9;\n\t// border-bottom: 1px solid #ddd;\n}\n\n.modal-body {\n\tpadding: 10px 20px 20px 20px;\n}\n\n.close-button {\n\tbackground: none;\n\tborder: none;\n\tfont-size: 1.5rem;\n\tcursor: pointer;\n\tcolor: #aaa;\n\ttransition: color 0.3s ease;\n}\n\n.close-button:hover {\n\tcolor: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
