import React from 'react';
import Information from './Information';
import Transactions from './Transactions';
import Statement from './Statement';

const renderContent = (activeTabMain) => {
  switch (activeTabMain) {
    case 'Information':
      return <Information />;
    case 'Transactions':
      return <Transactions />;
    case 'Statement':
      return <Statement />;
    default:
      return <Information />;
  }
};

export default renderContent;


