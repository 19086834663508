// Information.js
import React from "react";
import CreateVendors from "../CreateVendors";

const Information = () => {
    return (
        <div>
           
        </div>
    );
};

export default Information;
