import React, { useState, useEffect } from 'react';
import './InputTable.scss';

const ReadOnlyTable = ({
	initialRows = [],
	columns = [],
	className = '',
	value = [],
	deleteRow,
	handleModalOpen,
}) => {
	const [rows, setRows] = useState([]);

	function handleSetRows() {
		setRows(value.length > 0 ? value : initialRows.length > 0 ? initialRows : []);
	}

	useEffect(() => {
		handleSetRows();
	}, [value, initialRows]);

	return (
		<>
			<div className="text-center mt-0">
				<div>
					<table>
						<thead>
							<tr>
								{columns.map((col) => (
									<th
										className={`${className}`}
										key={col.name}
										style={{
											width: col.name === 'email' ? '300px' : '200px',
											borderRight: '0.5px solid white',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyItems: 'start',
												alignItems: 'center',
											}}
										>
											<span>{col.label}</span>
										</div>
									</th>
								))}
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{rows.map((row, index) => (
								<tr
									key={index}
									style={{ backgroundColor: '#F6F6F9', borderRight: '1px solid white' }}
								>
									{columns.map((col, colIndex) => (
										<td
											key={col.name + colIndex}
											style={{ borderRight: '0.5px solid white' }}
										>
											{col.type === 'text' && (
												<input
													type="text"
													name={col.name}
													value={row[col.name] || '-'}
													readOnly={true}
													className="table_input"
												/>
											)}
										</td>
									))}
									<td
										style={{
											display: 'flex',
										}}
									>
										<div
											// className="d-flex "
											style={{ gap: 10, display: 'flex', alignItems: 'center' }}
										>
											<button
												className="edit mt-0 mb-0 bg-transparent"
												onClick={() => handleModalOpen(index)}
											>
												<svg
													width="20"
													height="20"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g id="vuesax/linear/edit-2">
														<g id="edit-2">
															<path
																id="Vector"
																d="M13.26 3.59924L5.04997 12.2892C4.73997 12.6192 4.43997 13.2692 4.37997 13.7192L4.00997 16.9592C3.87997 18.1292 4.71997 18.9292 5.87997 18.7292L9.09997 18.1792C9.54997 18.0992 10.18 17.7692 10.49 17.4292L18.7 8.73924C20.12 7.23924 20.76 5.52924 18.55 3.43924C16.35 1.36924 14.68 2.09924 13.26 3.59924Z"
																stroke="#292D32"
																stroke-width="1.5"
																stroke-miterlimit="10"
																stroke-linecap="round"
																stroke-linejoin="round"
															/>
															<path
																id="Vector_2"
																d="M11.89 5.05078C12.32 7.81078 14.56 9.92078 17.34 10.2008"
																stroke="#292D32"
																stroke-width="1.5"
																stroke-miterlimit="10"
																stroke-linecap="round"
																stroke-linejoin="round"
															/>
															<path
																id="Vector_3"
																d="M3 22H21"
																stroke="#292D32"
																stroke-width="1.5"
																stroke-miterlimit="10"
																stroke-linecap="round"
																stroke-linejoin="round"
															/>
														</g>
													</g>
												</svg>
											</button>

											<button
												className="delete mt-0 mb-0 bg-transparent"
												onClick={() => {
													deleteRow(index);
												}}
												tabIndex="-1"
											>
												<svg
													width="20"
													height="20"
													viewBox="0 0 24 24"
													fill="none"
												>
													<path
														d="M3 6H5H21"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M10 11V17"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M14 11V17"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default ReadOnlyTable;
