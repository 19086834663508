import axios from 'axios';
import { APP_API_URL } from '../../utils/xhr-instance';

export const fetchPurchaseOrders = async (params) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axios.get(`${APP_API_URL}/purchase-order/get-purchase-order`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
};

export const deletePurchaseOrder = async (id) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axios.get(`${APP_API_URL}/delete-purchaseorder/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
};
