import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import './Login.scss';
import login from '../../../assets/image/login_illustration.svg';
import InputField from '../../../component/form/FormInput';
import Button from '../../../component/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { user_login, user_send_otp } from '../../../store/action/auth';
import { encodeEmailToToken, formatErrors } from '../../../utils/controller';
import { master_data } from '../../../store/action/master';
import { Helmet } from 'react-helmet';
import { passwordPolicy } from '../../../utils/dropsdown';
import ModalRight from '../../../component/modalRight';
import logo from '../../../assets/image/onboarding-logo.png';
import { fetchUserInfo } from '../../../store/action/userInfo';

// import { passwordPolicy } from "../../../utils/dropsdown";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [inputValue, setInputValue] = useState({ email: '' });
	const [passValue, setPassValue] = useState({ password: '' });
	const [openPassPolicy, setOpenPassPolicy] = useState(false);
	const { email } = inputValue;
	const { password } = passValue;
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState({});
	const expirationDate = new Date();
	expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000);

	const handleEmailChange = (inputValue) => {
		setInputValue({ email: inputValue });
	};
	const handlePasswordChange = (passValue) => {
		setPassValue({ password: passValue });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoader(true);
		let body = {
			email: email,
			password: password,
		};
		dispatch(user_login(body)).then((res) => {
			if (res?.success) {
				localStorage.setItem('authToken', res?.token);
				Cookies.set('authToken', res?.token, {
					expires: expirationDate,
					secure: true,
					sameSite: 'Strict',
				});
				if (res?.data?.is_email_verified === false) {
					const token = encodeEmailToToken(res?.data?.email);
					dispatch(user_send_otp({ email: res?.data?.email })).then((res) => {
						if (res?.success) {
							setLoader(false);
							toast.success(res?.message);
							navigate(`/otpVerify/?token=${token}&page_login=true`);
						} else {
							setLoader(false);
							toast.error(res?.message);
						}
					});
				} else if (res?.data?.is_organisation_setup === false) {
					navigate('/organizationDetail');
					setLoader(false);
				} else {
					localStorage.setItem('suburl', res?.data?.sub_domain);
					navigate(`/user/${res?.data?.sub_domain}/home`);
					setLoader(false);
				}
			} else {
				toast.error('Invalid Credentials');
				setLoader(false);
				let errors = formatErrors(res?.message);
				setError(errors);
			}
		});
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleSubmit(e);
		}
	};

	useEffect(() => {
		sessionStorage.clear();
	}, []);

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<Helmet>
					<title>Login to Pharmnex</title>
				</Helmet>
				<div className="login-form">
					<div className="login-body row clearfix">
						<div className="left-section">
							<div className="illustration">
								<div className="brand-name">
									<img
										src={logo}
										alt=""
									/>
								</div>
								<div className="login-illustration">
									<img
										src={login}
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className="right-section">
							<form className="form-body py-4 ms-0">
								<h1 className="form-title">Login</h1>
								<InputField
									value={email}
									label="Email"
									placeholder="Registered Email Address"
									type="email"
									onChange={handleEmailChange}
									error={error?.email}
									required={true}
									name="email"
									autoFocus={true}
									autoComplete="email"
									onKeyPress={handleKeyDown}
								/>
								<InputField
									value={password}
									label="Password"
									placeholder="Password"
									type="password"
									onChange={handlePasswordChange}
									error={error?.password}
									required={true}
									name="password"
									autoComplete="current-password"
									onKeyPress={handleKeyDown}
									showPasswordIcon
									onClick={() => setOpenPassPolicy(true)}
								/>

								<label className="form-forgotPass">
									<Link to="/forgotPassword"> Forgot Password?</Link>
								</label>
							</form>
							<Button
								type="submit"
								className="btn-primary"
								id="signin-btn"
								button="Sign in"
								loader={loader}
							/>
							<p className="mt-lg-5 mt-3 mt-sm-3 mb-3 text-center signin-btn">
								Don’t have a PharmNex account?
								<span className="primary-color cursor-pointer font-bold">
									<Link to="/signup"> Sign up </Link>
								</span>
								now
							</p>
						</div>
					</div>
				</div>
			</form>

			{openPassPolicy && (
				<div className="">
					<ModalRight
						show={openPassPolicy}
						isOpen={openPassPolicy}
						onClose={() => setOpenPassPolicy(false)}
						title="Password Policy"
						description1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since."
						description2="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
						description3="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
						linkTitle="Learn More"
						linkPath1="#"
						linkName1="Lorem Ipsum is simply dummy text of the printing"
						linkPath2="#"
						linkName2="Lorem Ipsum is simply dummy text of the printing"
						linkPath3="#"
						linkName3="Lorem Ipsum is simply dummy text of the printing"
						linkPath4="#"
						linkName4="Lorem Ipsum is simply dummy text of the printing"
						linkPath5="#"
						linkName5="Lorem Ipsum is simply dummy text of the printing"
						linkPath6="#"
						linkName6="Lorem Ipsum is simply dummy text of the printing"
					></ModalRight>
				</div>
			)}
		</div>
	);
};

export default Login;
