import React, { useEffect, useState } from 'react';
import FormSelect from '../../../../component/form/FormSelect';
import InputField from '../../../../component/form/FormInput';
import ImageUpload from '../../../../component/uplaodImage';
import Checkbox from '../../../../component/checkbox';
import AppendInput from '../../../../component/form/AppendInput';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import Modal from '../../../../component/modal/Modal';
import AddProductGroup from '../productGroup/AddProductGroup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ProductCatogery from '../productCatogery/ProductCatogery';
import { ObjectCreate } from '../../../../utils/utility';
import { product_group_list } from '../../../../store/action/group';
import Addshelf from '../shelf/Shelf';
import { product_catogery_list } from '../../../../store/action/catogery';
import { shelf_list } from '../../../../store/action/shelf';
import { master_data } from '../../../../store/action/master';
import { HSN_list } from '../../../../store/action/utility';
import Button from '../../../../component/ui/Button';
import AddProductTable from './AddProductTable';
import {
	change_status,
	product_Update,
	product_code_get,
	product_create,
	product_list,
} from '../../../../store/action/product';
import { toast } from 'react-toastify';
import BulkImport from '../BulkImport';
import ModalRight from '../../../../component/modalRight';
import { formatErrors } from '../../../../utils/controller';

const CreateProduct = () => {
	// const suburl = 'Hello'
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const productID = param?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [autoFocus, setAutoFocus] = useState({
		catogrey: false,
		product: false,
		shelf: false,
		tax1: false,
	});
	const [error, setError] = useState({});
	const [isOpenCatogery, setIsOpenCatogery] = useState(false);
	const [open, setOpen] = useState(false);
	const [isSheifId, setIsShelfId] = useState(false);

	const productgroupData = useSelector((state) => state?.productGroup?.data?.data?.productGroups);
	const productCatogeryData = useSelector(
		(state) => state?.productCatogery?.data?.data?.productCategories,
	);
	const shelfData = useSelector((state) => state?.shelf?.data?.data?.shelves);
	const masterData = useSelector((state) => state?.master?.masterData?.data);
	const HSNData = useSelector((state) => state?.utility?.data?.data);
	const [clone, setClone] = useState(false);
	const [groupOption, setGroupOptions] = useState([]);
	const [catogeryOption, setCatogeryOption] = useState([]);
	const [shelfOption, setShelfOption] = useState([]);
	const [packagingOption, setPackagingOption] = useState([]);
	const [unitOption, setUnitOption] = useState([]);
	const [HSNoption, setHSNoption] = useState([]);
	const [disabled, setDisabled] = useState(productID ? true : false); // Add state for disabling inputs
	const [openProductCode, setOpenProductCode] = useState(false);
	const [openHSNCode, setOpenHSNCode] = useState(false);
	const [openPacking, setOpenPacking] = useState(false);
	const [openUnit, setOpenUnit] = useState(false);
	const [openShelfID, setOpenShelfID] = useState(false);
	const [buklImport, setIsbuklImport] = useState(false);
	const [productCode, setproductCode] = useState('');
	const [persistGroupName, setPersistGroupName] = useState('');
	const [group, setGroup] = useState('');
	const [persistCatogey, setPersistCatogery] = useState('');
	const [catogery, setCatogery] = useState('');
	const [persistShlef, setPersistShelf] = useState('');
	const [shelf, setShelf] = useState('');

	const [isErrortable, setIsTableError] = useState(false);
	const [pageLoader, setPageLoader] = useState(false);
	// Table Data States
	const [tableData, setTableData] = useState([]);
	const [productTabelError, setProductTableError] = useState(false);
	const productData = useSelector(
		(state) => state?.product?.data?.data?.productWithStockCount?.[0],
	);

	const product_code = () => {
		let param = `product_group_id=${group}`;
		dispatch(product_code_get(param)).then((res) => {
			if (res?.success) {
				setproductCode(res?.data);
			}
		});
	};
	useEffect(() => {
		if (group && !param) {
			product_code();
		}
	}, [group]);

	const getProductById = () => {
		if (productID) {
			let param = `filterBy=product_id&filterValue=${productID}`;
			dispatch(product_list(param));
		}
	};
	useEffect(() => {
		getProductById();
	}, [productID]);
	const navigateRouter = (path) => {
		navigate(path);
	};
	const HandleGroupChange = (value) => {
		setGroup(value);
		if (value) {
			delete error?.product_group_id;
		}
		let param = `product_group_id=${value}`;
		dispatch(product_code_get(param)).then((res) => {
			if (res?.success) {
				setproductCode(res?.data);
				delete error?.product_code;
			}
		});
	};
	const handleCatogery = (value) => {
		setCatogery(value);
		if (value) {
			delete error?.category_id;
		}
	};
	const [formData, setFormData] = useState({
		name: '',
		hsn_id: '',
		packaging_id: '',
		contains: '0',
		unit_id: '',
		sell_loose: true,
		tax1: '0',
		tax2: '0',
		purchase_discount: '0.00',
		min_stock: '0',
		max_stock: '0',
		generic_name: '',
		product_image: null,
	});

	useEffect(() => {
		if (productID) {
			let BatchData = [];
			// Mapping over productData?.batchdetails to push mapped objects into data array
			productData?.batchdetails?.map((ele) => {
				BatchData?.push({
					batch_no: ele?.batch_no?.toString(),
					product_batch_id: ele?.product_batch_id?.toString(),
					quantity: ele?.quantity?.toString(),
					free_quantity: ele?.free_quantity?.toString(),
					mfg_date: ele?.mfg_date,
					expiry_date: ele?.expiry_date,
					mrp: ele?.mrp?.toString(),
					trade_rate: ele?.trade_rate?.toString(),
					purchase_rate: ele?.purchase_rate?.toString(),
					sale_rate: ele?.sale_rate?.toString(),
				});
			});
			setFormData({
				name: productData?.product_name || '',
				product_code: productData?.product_code || '',
				hsn_id: productData?.hsn_id || '',
				packaging_id: productData?.packaging_id || '',
				contains: productData?.contains || '',
				unit_id: productData?.unit_id || '',
				sell_loose: productData?.sell_loose || false,
				// shelf_id: productData?.shelf_id || '',
				tax1: Number(productData?.tax1) || '0.00',
				tax2: Number(productData?.tax2) || '0.00',
				purchase_discount: productData?.purchase_discount || '',
				min_stock: productData?.min_stock || '0',
				max_stock: productData?.max_stock || '0',
				generic_name: productData?.generic_name || '',
				product_image: productData?.image || null,
			});
			setCatogery(productData?.category_id || '');
			setGroup(productData?.product_group_id || '');
			setShelf(productData?.shelf_id || '');
			setTableData(BatchData);
			setproductCode(productData?.product_code);
		}
	}, [productData]);
	useEffect(() => {
		groupList();
		catogeryList();
		shelfList();
		dispatch(master_data());
		dispatch(HSN_list());
	}, [disabled]);
	useEffect(() => {
		groupList();
	}, [persistGroupName]);
	useEffect(() => {
		catogeryList();
	}, [persistCatogey]);
	useEffect(() => {
		shelfList();
	}, [persistShlef]);

	useEffect(() => {
		const unit_result = ObjectCreate({
			data: masterData?.units,
			key: 'unit_id',
			value: 'name',
		});
		const packaing_result = ObjectCreate({
			data: masterData?.packagingList,
			key: 'packaging_id',
			value: 'name',
		});
		setUnitOption(unit_result);
		setPackagingOption(packaing_result);
	}, [masterData]);
	useEffect(() => {
		const result = ObjectCreate({
			data: HSNData,
			key: 'hsn_id',
			value: 'hsn_code',
		});
		setHSNoption(result);
	}, [HSNData]);

	useEffect(() => {
		const result = ObjectCreate({
			data: productgroupData,
			key: 'product_group_id',
			value: 'name',
		});
		setGroupOptions(result);
	}, [productgroupData]);

	useEffect(() => {
		const result = ObjectCreate({
			data: productCatogeryData,
			key: 'category_id',
			value: 'name',
		});
		setCatogeryOption(result);
	}, [productCatogeryData]);

	useEffect(() => {
		const result = ObjectCreate({
			data: shelfData,
			key: 'shelf_id',
			value: 'name',
		});
		setShelfOption(result);
	}, [shelfData]);

	const handleImageUpload = (imageFile) => {
		const updatedData = { ...formData, product_image: imageFile };
		setFormData(updatedData);
	};

	const handleCheckboxChange = (checked) => {
		const updatedData = { ...formData, sell_loose: checked };
		setFormData(updatedData);
	};

	const handleInputChange = (key, value) => {
		const updatedData = { ...formData, [key]: value };
		setFormData(updatedData);

		if (value) {
			delete error?.[key];
		}
	};

	// product Group Get API
	const groupList = () => {
		let param = `page=${1}&pageSize=200`;
		dispatch(product_group_list(param)).then((res) => {
			if (persistGroupName) {
				if (res?.success) {
					let groupData = res?.data?.productGroups;
					let filteData = groupData?.filter((ele) => {
						return ele?.name === persistGroupName;
					});
					setGroup(filteData?.[0]?.product_group_id);
					setPersistGroupName('');
				}
			}
		});
	};

	const shelfList = () => {
		let param = `page=${1}&pageSize=200`;
		dispatch(shelf_list(param)).then((res) => {
			if (persistShlef) {
				if (res?.success) {
					let responseData = res?.data?.shelves;
					let filteData = responseData?.filter((ele) => {
						return ele?.name === persistShlef;
					});
					setShelf(filteData?.[0]?.shelf_id);
					setPersistShelf('');
				}
			}
		});
	};
	const catogeryList = () => {
		let param = `page=${1}&pageSize=200`;
		dispatch(product_catogery_list(param)).then((res) => {
			if (persistCatogey) {
				if (res?.success) {
					let respponseData = res?.data?.productCategories;
					let filteData = respponseData?.filter((ele) => {
						return ele?.name == persistCatogey;
					});
					setCatogery(filteData?.[0]?.category_id);
					setPersistCatogery('');
				}
			}
		});
	};

	const markAsinactive = () => {
		let productIds = Number(productID);
		let formbody = {
			product_ids: [productIds],
			status: 0,
		};
		dispatch(change_status(formbody)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				let param = `filterBy=product_id&filterValue=${productID}`;
				dispatch(product_list(param));
			} else {
				toast.error(res?.message);
			}
		});
	};
	const markAsactive = () => {
		let productIds = Number(productID);
		let formbody = {
			product_ids: [productIds],
			status: 1,
		};

		dispatch(change_status(formbody)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				let param = `filterBy=product_id&filterValue=${productID}`;
				dispatch(product_list(param));
			} else {
				toast.error(res?.message);
			}
		});
	};
	const cloneProduct = () => {
		setTableData([]);
		setFormData({
			name: '',
			packaging_id: formData?.packaging_id,
			contains: '0',
			unit_id: formData?.unit_id,
			sell_loose: formData?.sell_loose,
			tax1: formData?.tax1,
			tax2: formData?.tax2,
			purchase_discount: formData?.purchase_discount,
			min_stock: '0',
			max_stock: '0',
			hsn_id: formData.hsn_id,
			generic_name: '',
			product_image: null,
		});
		setClone(true);
		product_code();
		setDisabled(false);
	};
	const productAdujustment = () => {
		navigate(
			`/user/${suburl}/inventory-adjustment-crete?prodid=${productID}&prod=${productData?.product_name}`,
		);
	};

	const SubmitForm = () => {
		// setPageLoader(true)

		setPageLoader(true);
		const dataToSubmit = new FormData();
		for (const key in formData) {
			dataToSubmit.append(key, formData[key]);
		}
		if (!productID || clone) {
			dataToSubmit.append('product_code', productCode?.toString());
		}
		dataToSubmit.append('product_group_id', group?.toString());
		dataToSubmit.append('category_id', catogery?.toString());
		dataToSubmit.append('shelf_id', shelf?.toString());
		let BatchData = [];
		if (tableData?.length > 0 && tableData?.[0]?.batch_no !== '') {
			tableData?.map((ele) => {
				if (productID && clone === false) {
					BatchData?.push({
						product_batch_id: ele?.product_batch_id?.toString(),
						quantity: ele?.quantity.toString(),
						free_quantity: ele?.free_quantity?.toString(),
						mfg_date: ele?.mfg_date,
						expiry_date: ele?.expiry_date,
						mrp: ele?.mrp.toString(),
						trade_rate: ele?.trade_rate?.toString(),
						purchase_rate: ele?.purchase_rate?.toString(),
						sale_rate: ele?.sale_rate?.toString(),
						batch_no: ele?.batch_no,
					});
				} else {
					BatchData?.push({
						quantity: ele?.quantity?.toString(),
						free_quantity: ele?.free_quantity?.toString(),
						mfg_date: ele?.mfg_date,
						expiry_date: ele?.expiry_date,
						mrp: ele?.mrp?.toString(),
						trade_rate: ele?.trade_rate?.toString(),
						purchase_rate: ele?.purchase_rate?.toString(),
						sale_rate: ele?.sale_rate?.toString(),
						batch_no: ele?.batch_no,
					});
				}
			});
		}
		if (productID && clone === false) {
			setPageLoader(true);

			// update the product
			dataToSubmit.append('product_batch', JSON.stringify(BatchData));
			dispatch(product_Update(productID, dataToSubmit))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						getProductById();
						setDisabled(true);
						setTimeout(() => {
							setPageLoader(false);
						}, 100);
					} else {
						let errorObj = formatErrors(res?.data?.message);
						setError(errorObj);
						setPageLoader(false);
						toast.error(res?.response?.data?.message || res?.data?.message);
						if (Array.isArray(res?.response?.data?.message)) {
							setProductTableError(res?.response?.data?.message);
						}
					}
				})
				.catch((err) => {
					toast.error('Something went wrong!');
				});
		} else {
			setPageLoader(true);
			dataToSubmit.append('product_batch', JSON.stringify(BatchData));
			if (isErrortable === false) {
				dispatch(product_create(dataToSubmit)).then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						getProductById();
						navigate(`/user/${suburl}/product`);
					} else {
						let errorObj = formatErrors(res?.data?.message);
						setError(errorObj);
						toast.error(res?.response?.data?.message);
						if (Array.isArray(res?.response?.data?.message)) {
							setProductTableError(res?.response?.data?.message);
						}
					}
				});
			} else {
				toast.error('Error in add product table to know more click on show all error .');
				setProductTableError(true);
			}
		}
		setPageLoader(false);
	};

	return (
		<>
			{pageLoader ? (
				<div
					className="Page_loader"
					style={{ top: '40%', left: '48%' }}
				></div>
			) : (
				<div className="">
					<div className="">
						{productID ? (
							<div className="product-pg-header add-product">
								<p> {clone ? 'Add New Product' : 'Product Details'}</p>
								<div className="add-product-button">
									{disabled === true && (
										<Button
											type="button"
											className="btn-gray mt-0 px-4"
											button="Edit"
											onClick={() => setDisabled(false)}
										/>
									)}
									{clone === false && (
										<Button
											type="button"
											className="btn-gray mt-0 px-4  "
											button="Adjust"
											onClick={productAdujustment}
										/>
									)}
									{clone === false && (
										<Button
											type="button"
											className="btn-gray mt-0 px-4"
											button="Clone"
											onClick={cloneProduct}
										/>
									)}
									{productData?.status === 0
										? clone === false && (
												<Button
													type="button"
													className="btn-gray mt-0 px-4  "
													button="Mark as Active"
													onClick={markAsactive}
												/>
											)
										: clone === false && (
												<Button
													type="button"
													className="btn-gray mt-0 px-4  "
													button="Mark as Inactive"
													onClick={markAsinactive}
												/>
											)}
									{disabled === false && clone === false && (
										<Button
											type="button"
											className="btn-gray mt-0 px-4"
											button="Batch bulk upload "
											onClick={() => setIsbuklImport(true)}
										/>
									)}
									{disabled === false && (
										<Button
											type="button"
											className="btn-primary mt-0 px-4"
											button="Save"
											onClick={SubmitForm}
											loader={pageLoader}
										/>
									)}
									<Button
										type="button"
										className="mt-0 btn-danger add-pro"
										button=""
										close
										onClick={() => navigateRouter(-1)}
									/>
								</div>
							</div>
						) : (
							<div className="product-pg-header add-product">
								<p>Add New Product</p>
								<div className="add-product-button">
									<Button
										type="button"
										className="btn-primary mt-0 px-4"
										button="Save"
										onClick={SubmitForm}
									/>
									{/* <Button type="button" className="btn-dark add-pro" button="Cancel" /> */}
									<Button
										type="button"
										className="mt-0 btn-danger add-pro"
										button=""
										close
										onClick={() => navigateRouter(-1)}
									/>
								</div>
							</div>
						)}
					</div>
					{/*  product Form */}
					<div
						className="max-w-1297"
						style={{ padding: '1rem 2rem 0' }}
					>
						<div className="row">
							<div className="col-5 mt-4">
								<InputSelect
									options={groupOption || []}
									placeholder="Select Group"
									label="Select Group"
									onChange={(value) => HandleGroupChange(value)}
									value={group}
									// autoFocus={true}
									onAddNew={() => setOpen(true)}
									disabled={disabled}
									required={true}
									error={error?.product_group_id}
									autoFocus={!param?.id || disabled === false ? true : false}
								/>
							</div>
							<div className="col-5 mt-4">
								<InputSelect
									label="Select Category"
									placeholder="Select Category"
									onChange={(value) => handleCatogery(value)}
									value={catogery}
									options={catogeryOption || []}
									onAddNew={() => setIsOpenCatogery(true)}
									disabled={disabled}
									required={true}
									error={error?.category_id}
									autoFocus={!param?.id || disabled === false ? autoFocus?.catogrey : false}
								/>
							</div>
							<div className="col">
								<ImageUpload
									onImageUpload={handleImageUpload}
									error={error?.image}
									disabled={disabled}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<InputField
									label="Product Name"
									placeholder="Product Name"
									type="text"
									required={true}
									value={formData.name}
									onChange={(value) => handleInputChange('name', value)}
									disabled={disabled}
									error={error?.name}
									autoFocus={!param?.id || disabled === false ? autoFocus?.product : false}
								/>
							</div>
							<div className="col">
								<InputField
									label="Product Code"
									placeholder="Product Code"
									type="text"
									required={true}
									value={productCode}
									// onChange={(value) => handleInputChange('product_code', value)}
									disabled={true}
									infoIcon={true}
									onClick={() => setOpenProductCode(true)}
									error={error?.product_code}
								/>
							</div>
							<div className="col">
								<InputSelect
									options={HSNoption || []}
									placeholder="Select HSN Code"
									label="HSN code"
									onChange={(value) => handleInputChange('hsn_id', value)}
									value={formData.hsn_id}
									disabled={disabled}
									infoIcon
									required={true}
									error={error?.hsn_id}
									onClick={() => setOpenHSNCode(true)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-7">
								<div className="row">
									<div className="col-5">
										<InputSelect
											label="Packing"
											placeholder="Packing"
											onChange={(value) => handleInputChange('packaging_id', value)}
											value={formData.packaging_id}
											options={packagingOption || []}
											infoIcon={true}
											required={true}
											disabled={disabled}
											error={error?.packaging_id}
											onClick={() => setOpenPacking(true)}
										/>
									</div>
									<div className="col-2">
										<InputField
											label="Contains"
											placeholder=""
											type="number"
											onChange={(value) => handleInputChange('contains', value)}
											value={formData.contains}
											disabled={disabled}
											error={error?.contains}
											required={true}
										/>
									</div>
									<div className="col-3">
										<InputSelect
											label="Unit"
											placeholder="Select Unit"
											onChange={(value) => handleInputChange('unit_id', value)}
											value={formData.unit_id}
											options={unitOption || []}
											infoIcon={true}
											required={true}
											disabled={disabled}
											error={error?.unit_id}
											onClick={() => setOpenUnit(true)}
										/>
									</div>
									<div className="col-2 align-content-center mt-4">
										<Checkbox
											label="Sell Loose"
											checked={formData.sell_loose}
											onChange={handleCheckboxChange}
											error={error?.sell_loose}
											disabled={disabled}
											required={true}
										/>
									</div>
								</div>
							</div>
							<div className="col-5">
								<InputSelect
									label="Shelf ID"
									placeholder="Select Shelf ID"
									onChange={(value) => setShelf(value)}
									value={shelf}
									options={shelfOption || []}
									onAddNew={() => setIsShelfId(true)}
									infoIcon={true}
									// required={true}
									disabled={disabled}
									error={error?.shelf_id}
									onClick={() => setOpenShelfID(true)}
									autoFocus={!param?.id || disabled === false ? autoFocus?.shelf : false}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-7">
								<div className="row">
									<div className="col">
										<AppendInput
											label="Tax1"
											appendLabel="%"
											placeholder=""
											// required={true}
											className="custom-append-text"
											onChange={(value) => handleInputChange('tax1', value)}
											value={formData.tax1}
											disabled={disabled}
											error={error?.tax1}
											autoFocus={!param?.id || disabled === false ? autoFocus?.tax1 : false}
											// autoFocus={true}
										/>
									</div>
									<div className="col">
										<AppendInput
											label="Tax2"
											appendLabel="%"
											placeholder=""
											// required={true}
											className="custom-append-text"
											onChange={(value) => handleInputChange('tax2', value)}
											value={formData.tax2}
											disabled={disabled}
											error={error?.tax2}
										/>
									</div>
									<div className="col">
										<InputField
											label="Discount"
											placeholder=""
											type="text"
											onChange={(value) => handleInputChange('purchase_discount', value)}
											value={formData.purchase_discount}
											disabled={disabled}
											error={error?.purchase_discount}
										/>
									</div>
									<div className="col">
										<InputField
											label="Min Stock"
											placeholder=""
											type="text"
											onChange={(value) => handleInputChange('min_stock', value)}
											value={formData.min_stock}
											disabled={disabled}
											error={error?.min_stock}
										/>
									</div>
									<div className="col">
										<InputField
											label="Max Stock"
											placeholder=""
											type="text"
											onChange={(value) => handleInputChange('max_stock', value)}
											value={formData.max_stock}
											disabled={disabled}
											error={error?.max_stock}
										/>
									</div>
								</div>
							</div>
							<div className="col-5">
								<InputField
									label="Generic Name"
									placeholder=""
									type="text"
									onChange={(value) => handleInputChange('generic_name', value)}
									value={formData.generic_name}
									disabled={disabled}
									error={error?.generic_name}
								/>
							</div>
						</div>
					</div>
					<AddProductTable
						className="max-w-1297"
						tableData={tableData}
						setTableData={setTableData}
						disabled={disabled}
						productTabelError={productTabelError}
						setIsTableError={setIsTableError}
					/>
					{openProductCode && (
						<div className="">
							<ModalRight
								show={openProductCode}
								isOpen={openProductCode}
								onClose={() => setOpenProductCode(false)}
								title="Product Code"
								description1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since."
								description2="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								description3="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								linkTitle="Learn More"
								linkPath1="#"
								linkName1="Lorem Ipsum is simply dummy text of the printing"
								linkPath2="#"
								linkName2="Lorem Ipsum is simply dummy text of the printing"
								linkPath3="#"
								linkName3="Lorem Ipsum is simply dummy text of the printing"
								linkPath4="#"
								linkName4="Lorem Ipsum is simply dummy text of the printing"
								linkPath5="#"
								linkName5="Lorem Ipsum is simply dummy text of the printing"
								linkPath6="#"
								linkName6="Lorem Ipsum is simply dummy text of the printing"
							></ModalRight>
						</div>
					)}
					{openHSNCode && (
						<div className="">
							<ModalRight
								show={openHSNCode}
								isOpen={openHSNCode}
								onClose={() => setOpenHSNCode(false)}
								title="HSN Code"
								description1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since."
								description2="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								description3="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								linkTitle="Learn More"
								linkPath1="#"
								linkName1="Lorem Ipsum is simply dummy text of the printing"
								linkPath2="#"
								linkName2="Lorem Ipsum is simply dummy text of the printing"
								linkPath3="#"
								linkName3="Lorem Ipsum is simply dummy text of the printing"
								linkPath4="#"
								linkName4="Lorem Ipsum is simply dummy text of the printing"
								linkPath5="#"
								linkName5="Lorem Ipsum is simply dummy text of the printing"
								linkPath6="#"
								linkName6="Lorem Ipsum is simply dummy text of the printing"
							></ModalRight>
						</div>
					)}
					{openPacking && (
						<div className="">
							<ModalRight
								show={openPacking}
								isOpen={openPacking}
								onClose={() => setOpenPacking(false)}
								title="Product Packing"
								description1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since."
								description2="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								description3="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								linkTitle="Learn More"
								linkPath1="#"
								linkName1="Lorem Ipsum is simply dummy text of the printing"
								linkPath2="#"
								linkName2="Lorem Ipsum is simply dummy text of the printing"
								linkPath3="#"
								linkName3="Lorem Ipsum is simply dummy text of the printing"
								linkPath4="#"
								linkName4="Lorem Ipsum is simply dummy text of the printing"
								linkPath5="#"
								linkName5="Lorem Ipsum is simply dummy text of the printing"
								linkPath6="#"
								linkName6="Lorem Ipsum is simply dummy text of the printing"
							></ModalRight>
						</div>
					)}
					{openUnit && (
						<div className="">
							<ModalRight
								show={openUnit}
								isOpen={openUnit}
								onClose={() => setOpenUnit(false)}
								title="Product Unit"
								description1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since."
								description2="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								description3="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								linkTitle="Learn More"
								linkPath1="#"
								linkName1="Lorem Ipsum is simply dummy text of the printing"
								linkPath2="#"
								linkName2="Lorem Ipsum is simply dummy text of the printing"
								linkPath3="#"
								linkName3="Lorem Ipsum is simply dummy text of the printing"
								linkPath4="#"
								linkName4="Lorem Ipsum is simply dummy text of the printing"
								linkPath5="#"
								linkName5="Lorem Ipsum is simply dummy text of the printing"
								linkPath6="#"
								linkName6="Lorem Ipsum is simply dummy text of the printing"
							></ModalRight>
						</div>
					)}
					{openShelfID && (
						<div className="">
							<ModalRight
								show={openShelfID}
								isOpen={openShelfID}
								onClose={() => setOpenShelfID(false)}
								title="Shelt ID"
								description1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since."
								description2="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								description3="Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
								linkTitle="Learn More"
								linkPath1="#"
								linkName1="Lorem Ipsum is simply dummy text of the printing"
								linkPath2="#"
								linkName2="Lorem Ipsum is simply dummy text of the printing"
								linkPath3="#"
								linkName3="Lorem Ipsum is simply dummy text of the printing"
								linkPath4="#"
								linkName4="Lorem Ipsum is simply dummy text of the printing"
								linkPath5="#"
								linkName5="Lorem Ipsum is simply dummy text of the printing"
								linkPath6="#"
								linkName6="Lorem Ipsum is simply dummy text of the printing"
							></ModalRight>
						</div>
					)}

					{open && (
						<div>
							<Modal
								className="add-productgroup-modal"
								isOpen={open}
								onClose={() => setOpen(false)}
								title="Add New Group"
							>
								<AddProductGroup
									isOpen={open}
									onCLose={() => setOpen(false)}
									reloadTable={groupList}
									setPersistGroupName={setPersistGroupName}
									setAutoFocus={setAutoFocus}
									callBackFunction={HandleGroupChange}
									shouldCallCalback={true}
								/>
							</Modal>
						</div>
					)}
					{isOpenCatogery && (
						<div>
							<Modal
								className="add-productgroup-modal"
								isOpen={isOpenCatogery}
								onClose={() => setIsOpenCatogery(false)}
								title="Add New Catogery"
							>
								<ProductCatogery
									isOpen={isOpenCatogery}
									onCLose={() => setIsOpenCatogery(false)}
									reloadTable={catogeryList}
									setAutoFocus={setAutoFocus}
									setPersistCatogery={setPersistCatogery}
								/>
							</Modal>
						</div>
					)}

					{isSheifId && (
						<div>
							<Modal
								className="add-productgroup-modal"
								isOpen={isSheifId}
								onClose={() => setIsShelfId(false)}
								title="Add New shelf"
							>
								<Addshelf
									isOpen={isSheifId}
									onCLose={() => setIsShelfId(false)}
									reloadTable={shelfList}
									setAutoFocus={setAutoFocus}
									setPersistShelf={setPersistShelf}
								/>
							</Modal>
						</div>
					)}

					{buklImport && (
						<div>
							<Modal
								className="add-productgroup-modal"
								isOpen={buklImport}
								onClose={() => setIsbuklImport(false)}
								title=" Batch bulk Import "
							>
								<BulkImport
									isOpen={buklImport}
									onClose={() => setIsbuklImport(false)}
									type={'batch'}
									reloadTable={getProductById}
									productID={productID}
								/>
							</Modal>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default CreateProduct;
