export const PaymentMadeColoum = [
    {
        field: "",
        headerClass: 'HeaderBg',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        minWidth: 50,
        maxWidth: 50,
        lockPosition: true,
    },
    {
      field: "DATE",
      headerClass: "HeaderBg",
      suppressSizeToFit: true,
      minWidth: 200,
      flex: 1,
      cellClass: "left-align",
    },
 

    {
        field: "VENDOR NAME",
        headerClass: "HeaderBg",
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
        cellClass: "left-align",
      },
      {
        field: "MODE",
        headerClass: "HeaderBg",
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
        cellClass: "left-align",
      },
      {
        field: "AMOUNT",
        headerClass: "HeaderBg right-align",
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
        cellClass: "left-align",
      },
  ];
  