import axios from "axios";
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";



export const HSN_LIST_GET_DONE = "HSN_LIST_GET_DONE";
export const HSN_LIST_GET_FAIL = "HSN_LIST_GET_DONE";


export const SAMPLE_SHEET_GET_DONE = "SAMPLE_SHEET_GET_DONE";
export const SAMPLE_SHEET_GET_FAIL = "SAMPLE_SHEET_GET_DONE";


export const CREATE_HSN_API_URL = `${APP_API_URL}/shelf/create`;
export const LIST_HSN_API_URL = `${APP_API_URL}/product/getHSNCode`;
export const LIST_SAMPLE_SHEET_API_URL = `${APP_API_URL}/app/get-sample-sheet`;

let token = getLocalStorageData()
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
    },
}


export const HSN_list = () => {
    let API_URL = LIST_HSN_API_URL;
    return (dispatch) => {
        return axios
            .get(API_URL, config).then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: HSN_LIST_GET_DONE,
                        payload: res,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: HSN_LIST_GET_FAIL,
                        payload: res,
                    });
                    return res;
                }
            }).catch((err) => {
                return err
            });
    };
};
export const BulkUploadSampleSheet = () => {
    let API_URL = LIST_SAMPLE_SHEET_API_URL;
    return (dispatch) => {
        return axios
            .get(API_URL, config).then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: SAMPLE_SHEET_GET_DONE,
                        payload: res,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: SAMPLE_SHEET_GET_FAIL,
                        payload: res,
                    });
                    return res;
                }
            }).catch((err) => {
                return err
            });
    };
};