import React from 'react';
import PurchaseOrderTable from '../../component/InputTable/PurchaseOrderTable';

const OrderTable = ({
	tableData,
	setTableData,
	disabled,
	paramId,
	tableError,
	setIsTableErrorShow,
}) => {
	const handleTableChange = (newData) => {
		setTableData(newData);
	};

	const columns = [
		{ name: 'product_id', label: 'Name', type: 'product_name' },
		{ name: 'packaging', label: 'Product Packaging', type: 'text' },
		{ name: 'qty', label: 'Quantity ', type: 'text' },
	];

	let initialRows = [
		{
			product_id: '',
			packaging: '',
			qty: '',
		},
	];

	return (
		<div
			className="add-adjustment-inputTable max-w-1297"
			style={{ padding: '0rem 2rem' }}
		>
			<PurchaseOrderTable
				initialRows={initialRows}
				columns={columns}
				onChange={handleTableChange}
				value={tableData}
				disabled={disabled}
				newRowgenerate={'Quantity'}
				returnInputvalue={false}
				paramId={paramId}
				tableError={tableError}
				setIsTableErrorShow={setIsTableErrorShow}
			/>
		</div>
	);
};

export default OrderTable;
