import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import { useDispatch } from 'react-redux';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
import { toast } from 'react-toastify';
import { ExportTable } from '../../store/action/export/Export';
import { PurchaseOrderColoum } from './purchaseOrdereColoum';
import { fetchPurchaseOrders } from '../../services/purchaseOrder';
import { getDate } from '../../utils/dateUtils';
import advanceFilterIcon from '../../assets/image/advanceFilter.svg';
import FilterPopup from '../../component/Filter/AdvanceFilter';
const PurchaseOrder = () => {
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const [totalPages, setTotalPages] = useState(0);
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('All Purchase Order');
	const [stateCondition, setStateCondition] = useState('');
	const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
	const [purchaseOrderParams, setPurchaseOrderParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'purchase_order_id',
		sortBy: 'asc',
		filterBy: '',
		filterValue: '',
	});

	const navigateRouter = (path) => {
		navigate(path);
	};

	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/purchases-order-details/${id}`);
		}
	};

	const FilterDatalist = (param, value, dropDownData) => {
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setPurchaseOrderParams((prev) => ({
			...prev,
			...param,
			orderBy: '',
			sortBy: '',
		}));
	};
	const sortDataList = (param) => {
		setPurchaseOrderParams((prev) => ({
			...prev,
			...param,
			filterBy: '',
			filterValue: '',
		}));
	};

	const handlePageChange = (page) => {
		setPurchaseOrderParams((prev) => ({
			...prev,
			page,
		}));
	};


	const exportData = (param) => {
		ExportTable(param);
	};

	const handleFetchPurcahseOrders = async () => {
		try {
			const { purchaseOrders, totalPages } = await fetchPurchaseOrders(purchaseOrderParams);
			const mappedPurchaseOrderTableData = purchaseOrders.map((purchase) => {
				return {
					id: purchase.purchase_order_id,
					DATE: getDate(purchase?.date),
					'PURCHASE ORDER#': purchase?.purchase_order_number,
					'VENDOR NAME': purchase?.vendor?.vendor_name,
					STATUS: purchase?.status,
					RECEIVED: purchase?.is_received ? 'Yes' : 'No',
					BILLED: purchase?.is_billed ? 'Yes' : 'No',
					'EXPECTED DELIVERY': getDate(purchase?.expected_delivery_date),
				};
			});
			setTableData(mappedPurchaseOrderTableData);
			setTotalPages(totalPages);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	};

	useEffect(() => {
		handleFetchPurcahseOrders();
	}, [purchaseOrderParams]); // sortParam, filterparam, currentPage

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray"
							onClick={() => window.print()}
						/>

						<div className="dropdown">
							<button
								className="btn btn-gray mt-0 px-4 dropdown-toggle"
								style={{
									width: '86px',
									height: '32px',
									fontSize: '14px',
									fontWeight: '500',
								}}
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Share
							</button>
							<ul className="dropdown-menu font-14" style={{ minWidth: '86px' }}>
								<li>
									<a
										className="dropdown-item"
										href="#"
									>
										Print
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										href="#"
									>
										Download
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										href="#"
									>
										Email
									</a>
								</li>

							</ul>
						</div>

						<div className="dropdown">
							<button
								className="btn btn-gray mt-0 px-4 dropdown-toggle"
								style={{
									width: '136px',
									height: '32px',
									fontSize: '14px',
									fontWeight: '500',
								}}
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Bulk Update
							</button>
							<ul className="dropdown-menu font-14" style={{ minWidth: '136px' }}>
								<li>
									<a
										className="dropdown-item"
										href="#"
									>
										Mark As Issued
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										href="#"
									>
										Cancel Order
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										href="#"
									>
										Convert To Invoice
									</a>
								</li>

							</ul>
						</div>

						<Button
							type="button"
							button="Mark As Issued"
							className="mt-0 px-4 btn-gray"
						/>
						<Button
							type="button"
							button="Cancel Orders"
							className="mt-0 px-4 btn-gray"
						/>

						<Button
							type="button"
							button="Convert To Bill"
							className="mt-0 px-4 btn-gray"
						/>

						<Button
							type="button"
							button=""
							className="mt-0 px-4 btn-danger"
							close
							onClick={() => setBulkAction(false)}
						/>
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div className="dropdown text-end d-flex">
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ orderBy: 'product_order_id', sortBy: 'asc' },
											'',
											'All Purchase Order',
										)
									}
								>
									All Purchase Order
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_draft', filterValue: 1 }, 'active', 'Draft')
									}
								>
									Draft
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_pending_approval', filterValue: 1 },
											'is_pending_approval',
											'Pendding Approval',
										)
									}
								>
									Pendding Approval
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_approved', filterValue: 1 },
											'is_approved',
											'Approved',
										)
									}
								>
									Approved
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_issued', filterValue: 1 }, 'is_issued', 'Issued')
									}
								>
									Issued
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_billed', filterValue: 1 }, 'is_billed', 'Billed')
									}
								>
									Billed
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_closed', filterValue: 1 }, 'is_closed', 'Closed')
									}
								>
									Closed
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_cancelled', filterValue: 1 },
											'is_cancelled',
											'Cancelled',
										)
									}
								>
									Cancelled
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_received', filterValue: 1 },
											'is_received',
											'Received',
										)
									}
								>
									Received
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_partially_received', filterValue: 1 },
											'is_partially_received',
											'Partially Received',
										)
									}
								>
									Partially Received
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_partially_billed', filterValue: 1 },
											'is_partially_billed',
											'Billed and Not Received',
										)
									}
								>
									Billed and Not Received
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_in_transit', filterValue: 1 },
											'is_in_transit',
											'In-Transit',
										)
									}
								>
									In-Transit
								</a>
							</li>
						</ul>
						<div>
							<img
								src={advanceFilterIcon}
								alt=""
								style={{ width: '18px', height: '18px', marginRight: '8px' }}
							/>
							<a
								onClick={() => setIsAdvanceFilterOpen(true)}
								style={{
									color: '#2E63F6',
									fontSize: '14px',
									cursor: 'pointer',
									fontWeight: '600',
									lineHeight: '24px',
								}}
							>
								Advanced Filters
							</a>
						</div>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Purchase Order"
							add
							onClick={() => navigateRouter(`/user/${suburl}/purchases-order-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a className="dropdown-item sortBy">Sort By</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'date', sortBy: 'asc' })}
									>
										Date
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'created_at', sortBy: 'asc' })}
									>
										Created Time
										{/* <span>
											{sortDirection['created_time'] === 'asc' ? (
												<IoIosArrowRoundUp />
											) : (
												<IoIosArrowRoundDown />
											)}
										</span> */}
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_order_id', sortBy: 'asc' })}
									>
										Purchase Order
										{/* <span>
											{sortDirection['purchase_order'] === 'asc' ? (
												<IoIosArrowRoundUp />
											) : (
												<IoIosArrowRoundDown />
											)}
										</span> */}
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
									>
										Vendor Name
										{/* <span>
											{sortDirection['vendor_name'] === 'asc' ? (
												<IoIosArrowRoundUp />
											) : (
												<IoIosArrowRoundDown />
											)}
										</span> */}
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'amount', sortBy: 'asc' })}
									>
										Amount
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() =>
											sortDataList({ orderBy: 'expected_delivery_date', sortBy: 'asc' })
										}
									>
										Expected Delivery Date
									</a>
								</li>

								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'updated_at', sortBy: 'asc' })}
									>
										Last Modified time
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => exportData(`vendor`)}
									>
										Export purchase Order
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_order_id', sortBy: 'asc' })}
									>
										Refresh
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}

			{loader ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '100%', marginTop: '20%' }}
				>
					<span
						className="Page_loader"
						role="status"
						aria-hidden="true"
					></span>
				</div>
			) : (
				<div className="productTable-body">
					<Table
						row={tableData}
						coloum={PurchaseOrderColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
					/>
				</div>
			)}
			<Pagination
				currentPage={purchaseOrderParams.page}
				totalPages={totalPages}
				onChangePage={handlePageChange}
			/>

			{/* {openPaymentTermsModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openPaymentTermsModal}
						onClose={() => setOpenPaymentTermsModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<AddPaymentTerms
							isOpen={openPaymentTermsModal}
							onCLose={() => setOpenPaymentTermsModal(false)}
							handleSaveClick={handleBulkPaymentTermsUpdate}
						/>
					</Modal>
				</div>
			)} */}
			{/* {openCurrencyModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openCurrencyModal}
						onClose={() => setCurrencyModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<UpdateCurrency
							isOpen={openCurrencyModal}
							onCLose={() => setCurrencyModal(false)}
							handleSaveClick={handleBulkCurrencyUpdate}
						/>
					</Modal>
				</div>
			)} */}
			{/* Advance Filter  */}
			{isAdvanceFilterOpen && (
				<FilterPopup
					onClose={() => setIsAdvanceFilterOpen(false)}
				// onApply={handleApplyFilters}
				/>
			)}
		</div>
	);
};

export default PurchaseOrder;
