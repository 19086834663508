import React, { useState } from 'react';
import InputField from '../../../../component/form/FormInput';
import Button from '../../../../component/ui/Button';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { formatErrors } from '../../../../utils/controller';
import { group_create } from '../../../../store/action/group';

const AddProductGroup = ({
	// isOpen,
	onCLose,
	reloadTable,
	setPersistGroupName,
	setAutoFocus,
	callBackFunction,
	shouldCallCalback = false,
}) => {
	const dispatch = useDispatch();
	const [error, setError] = useState('');
	const [loader, setLoader] = useState(false);
	const [name, setName] = useState('');

	const SubmitForm = (event) => {
		event.preventDefault();

		setLoader(true);
		let body = {
			name: name,
		};
		dispatch(group_create(body)).then((res) => {
			if (res?.success) {
				// generate auto Product Code while adding a  new group
				if (shouldCallCalback) {
					callBackFunction(res?.data?.product_group_id);
				}
				setPersistGroupName(name);
				setAutoFocus({
					catogrey: true,
					product: false,
					shelf: false,
					tax1: false,
				});
				reloadTable();
				toast.success(res?.message);
				onCLose();
				setLoader(false);
			} else {
				formatErrors(res?.data?.message);
				toast.error(res?.response?.data?.message);
				setLoader(false);
			}
		});
	};

	return (
		<div>
			<form
				onSubmit={SubmitForm}
				className="add-productgroup-form"
			>
				<InputField
					label="Group Name"
					placeholder=""
					type="text"
					required={true}
					name=" Enter Group Name"
					autoFocus={true}
					onChange={(val) => setName(val)}
					value={name}
					error={error?.name || error}
				/>
				<div className="add-productgroup-footer">
					<Button
						type="submit"
						button="Save"
						className="btn-primary add-pgroup"
						loader={loader}
					/>
					<Button
						type="button"
						button="Cancel"
						className="btn-dark add-pgroup"
						onClick={onCLose}
					/>
				</div>
			</form>
		</div>
	);
};

export default AddProductGroup;
