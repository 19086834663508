import { createBrowserRouter, Outlet } from 'react-router-dom';
import Login from '../pages/auth/login/Login';
import ForgotPassword from '../pages/auth/forgotpassword/ForgotPassword';
import Signup from '../pages/auth/signup/Signup';
import CreateNewPassword from '../pages/auth/createNewPassword/CreateNewPassword';
import OTPVerify from '../pages/auth/otpVerify/OTPVerify';
import Layout from '../layout/Layout';
import Dashboard from '../pages/dashboard';
import OrganizationDetail from '../pages/organizationDetail/OrganizationDetail';
import PrivateRoute from './PrivateRoutes';
import ProductDetails from '../pages/inventory/product';
import ProductGroup from '../pages/inventory/product/productGroup/ProductGroup';
import Adjustment from '../pages/inventory/adjustment';
import InventoryAdjustment from '../pages/inventory/adjustment/InventoryAdjustment';
import NotFound from '../pages/404NotFound';
import CreateProduct from '../pages/inventory/product/addProduct/CreateProduct';
import GroupDetails from '../pages/inventory/product/productGroup/GroupDetails';
import Vendors from '../pages/vendors/Vendors';
import CreateVendors from '../pages/vendors/CreateVendors';
import PurchaseOrder from '../pages/purchaseOrder/PurchaseOrder';
import CreatePurchaseOrder from '../pages/purchaseOrder/CreatePurchaseOrder';
import PurchaseReceived from '../pages/PurchaseRecieved/PurchaseReceived';
import PurchaseDetails from '../pages/PurchaseRecieved/PurchaseDetails';
import CreatePurchaseReceive from '../pages/PurchaseRecieved/CreatePurchaseReceive';
import PurchaseBills from '../pages/purchasebills/PurchaseBills';
import PurchaseBillDetails from '../pages/purchasebills/PurchaseBillDetails';
import CreditNote from '../pages/creditNote/CreditNote';
import CreditNoteDetails from '../pages/creditNote/CreditNoteDetails';
import CreateCreditNote from '../pages/creditNote/CreateCreditNote';
import PaymentMade from '../pages/paymentMade/PaymentMade';
import PaymentMadeDetails from '../pages/paymentMade/PaymentMadeDetails';
import CreatePaymentMade from '../pages/paymentMade/CreatePaymentMade';
import CreatePurchaseBill from '../pages/purchasebills/CreatePurchaseBill';
// import CreateVendors from '../pages/vendors/CreateVendors';
import VendorsDetails from '../pages/vendors/CreateVendors';
import PurchaseOrderSendEmails from '../pages/purchaseOrder/PurchaseOrderSendEmails';
import PurchaseOrderDetails from '../pages/purchaseOrder/PurchaseOrderDetails';

export const Approuter = createBrowserRouter([
	{
		path: '/',
		element: <Login />,
	},
	{
		path: `/forgotPassword`,
		element: <ForgotPassword />,
	},
	{
		path: '/signup',
		element: <Signup />,
	},
	{
		path: '/createNewPassword',
		element: <CreateNewPassword />,
	},
	{
		path: '/otpVerify/*',
		element: <OTPVerify />,
	},
	{
		path: '/organizationDetail',
		element: <OrganizationDetail />,
	},
	{
		path: '/user',
		element: <Layout />,
		children: [
			{
				path: `/user/:suburl?/:suburl?/home`,
				element: <PrivateRoute element={<Dashboard />} />,
			},
			{
				path: '/user/:suburl?/product/:id?',
				element: <PrivateRoute element={<ProductDetails />} />,
			},
			{
				path: '/user/:suburl?/product-create/:id?',
				element: <PrivateRoute element={<CreateProduct />} />,
			},
			{
				path: '/user/:suburl?/group',
				element: <PrivateRoute element={<ProductGroup />} />,
			},
			{
				path: '/user/:suburl?/group-details/:id',
				element: <PrivateRoute element={<GroupDetails />} />,
			},
			{
				path: '/user/:suburl?/inventory-adjustment-crete/:id?',
				element: <PrivateRoute element={<Adjustment />} />,
			},
			{
				path: '/user/:suburl?/inventory-adjustment',
				element: <PrivateRoute element={<InventoryAdjustment />} />,
			},
			{
				path: '/user/:suburl?/vendor',
				element: <PrivateRoute element={<Vendors />} />,
			},
			{
				path: '/user/:suburl?/vendor-create/:id?',
				element: <PrivateRoute element={<CreateVendors />} />,
			},
			// purchase order  routes
			{
				path: '/user/:suburl?/purchases-order',
				element: <PrivateRoute element={<PurchaseOrder />} />,
			},
			{
				path: '/user/:suburl?/purchases-order-details/:id',
				element: <PrivateRoute element={<PurchaseOrderDetails />} />,
			},
			{
				path: '/user/:suburl?/purchases-order-create/:id?',
				element: <PrivateRoute element={<CreatePurchaseOrder />} />,
			},
			{
				path: '/user/:suburl?/purchases-order-send-emails/:id',
				element: <PrivateRoute element={<PurchaseOrderSendEmails />} />,
			},
			// purchase Recieved
			{
				path: '/user/:suburl?/purchases-recieve',
				element: <PrivateRoute element={<PurchaseReceived />} />,
			},
			{
				path: '/user/:suburl?/purchases-recieve-details/:id?',
				element: <PrivateRoute element={<PurchaseDetails />} />,
			},
			{
				path: '/user/:suburl?/purchases-receive-create',
				element: <PrivateRoute element={<CreatePurchaseReceive />} />,
			},
			// purchase bills
			{
				path: '/user/:suburl?/purchases-bills',
				element: <PrivateRoute element={<PurchaseBills />} />,
			},
			{
				path: '/user/:suburl?/purchases-bills-details/:id?',
				element: <PrivateRoute element={<PurchaseBillDetails />} />,
			},
			{
				path: '/user/:suburl?/purchases-bills-create',
				element: <PrivateRoute element={<CreatePurchaseBill />} />,
			},
			// Payment made
			{
				path: '/user/:suburl?/payment-made',
				element: <PrivateRoute element={<PaymentMade />} />,
			},
			{
				path: '/user/:suburl?/payment-made-details/:id?',
				element: <PrivateRoute element={<PaymentMadeDetails />} />,
			},
			{
				path: '/user/:suburl?/payment-made-create',
				element: <PrivateRoute element={<CreatePaymentMade />} />,
			},
			// Credit Note
			{
				path: '/user/:suburl?/credit-note',
				element: <PrivateRoute element={<CreditNote />} />,
			},
			{
				path: '/user/:suburl?/credit-note-details/:id?',
				element: <PrivateRoute element={<CreditNoteDetails />} />,
			},
			{
				path: '/user/:suburl?/credit-note-create/:id?',
				element: <PrivateRoute element={<CreateCreditNote />} />,
			},
			// Add a catch-all route for 404 page
			{
				path: '*',
				element: <NotFound />,
			},
		],
	},
]);
