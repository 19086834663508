import React, { useEffect, useState } from 'react';
import './Layout.scss';
import search from '../assets/image/search-status.svg';
import profile from '../assets/image/userProfile.png';
import notification from '../assets/image/notification.svg';
import setting from '../assets/image/setting.svg';
import Button from '../component/ui/Button';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import Clock from 'react-live-clock';
import { getFormattedDateTime } from '../utils/utility';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '../store/action/userInfo';
import GlobalSearch from '../component/globalSearch/GlobalSearch';
import Cookies from 'js-cookie';
import collapse from '../assets/image/sidebar/collapseSidebar.svg';
import expand from '../assets/image/sidebar/expandSidebar.svg';
import { toast } from 'react-toastify';

const Navbar = ({ collapsed, setCollapsed }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleSignout = () => {
		localStorage.clear();
		Cookies.remove('authToken', { path: '/' });

		navigate('/');
	};
	const dateToFormat = getFormattedDateTime();
	const userDetails = useSelector((state) => state?.userInfo?.userInfo);
	const userInfoDetails = () => {
		try {
			dispatch(fetchUserInfo()).then((res) => {
				if (res?.success) {
					localStorage.setItem('suburl', res?.data?.sub_domain);
				} else {
				}
			});
		} catch (error) {
			toast.error('Something went wrong while fetching user info!');
		}
	};
	useEffect(() => {
		setTimeout(() => {
			userInfoDetails();
		}, 300);
	}, []);

	const shortCutNavigate = (path) => {
		navigate(path);
	};
	// const suburl = ''
	const suburl = localStorage.getItem('suburl');

	const handlehamburger = () => {
		let root = document.getElementById('root');
		root.classList.toggle('collapsed');
		setCollapsed(!collapsed);
	};

	return (
		<div className={collapsed ? 'navtop' : 'navtop'}>
			<div className="nav-container">
				{/* <div className="input-group form-mobile-check left-search w-auto">
        </div> */}
				{/* Global Search   */}

				<span className="align-items-center d-flex gap-4">
					{/* <img src={collapse} width="20" alt="" /> */}
					<img
						src={!collapsed ? collapse : expand}
						width="20"
						alt=""
						onClick={handlehamburger}
					/>

					<GlobalSearch />
				</span>

				<div className="right-details">
					<span className="currentTime mt-1">
						<Moment
							local
							format="MMMM, DD, YYYY | "
						></Moment>{' '}
						<span>
							<Clock
								format={'hh:mm:ss A'}
								timeZone={'Asia/Kolkata'}
								ticking={true}
							/>
						</span>{' '}
					</span>
					<span className="welcomeText">
						Hey!{' '}
						<span className="currentName">Welcome! {userDetails?.data?.userData?.first_name}</span>
					</span>
					<div className="dropdown-action">
						<a
							href="#"
							id="more-action"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<Button
								type="button"
								add
								className="btn-primary addBtn"
							/>
						</a>
						<div
							className="dropdown-menu dropdown-menu-end"
							aria-labelledby="more-action"
						>
							<div className="d-flex">
								<div className="col-4 more-actions-list">
									<div className="section-title">GENERAL</div>
									<ul className="action-menu">
										<li
											className="action-menu-item"
											onClick={() => shortCutNavigate(`/user/${suburl}/product-create`)}
										>
											+ Add Product
										</li>
										<li className="action-menu-item">+ Add Users</li>
										<li
											className="action-menu-item"
											onClick={() => shortCutNavigate(`/user/${suburl}/group`)}
										>
											+ Items Groups
										</li>
										<li
											className="action-menu-item"
											onClick={() => shortCutNavigate(`/user/${suburl}/inventory-adjustment-crete`)}
										>
											+ Inventory Adjustments
										</li>
									</ul>
								</div>
								<div className="col-4 more-actions-list">
									<div className="section-title">GENERAL</div>
									<ul className="action-menu">
										<li className="action-menu-item">+ Customer</li>
										<li className="action-menu-item">+ Delivery Challan</li>
										<li className="action-menu-item">+ Invoices</li>
										<li className="action-menu-item">+ Retail Invoice</li>
										<li className="action-menu-item">+ Sales Order</li>
										<li className="action-menu-item">+ Shipment</li>
										<li className="action-menu-item">+ Customer Payment</li>
										<li className="action-menu-item">+ Credit Notes</li>
									</ul>
								</div>
								<div className="col-4 more-actions-list">
									<div className="section-title">GENERAL</div>
									<ul className="action-menu">
										<li className="action-menu-item">+ Vendor</li>
										<li className="action-menu-item">+ Bills</li>
										<li className="action-menu-item">+ Purchase Orders</li>
										<li className="action-menu-item">+ Purchase Receives</li>
										<li className="action-menu-item">+ Vendor Payment</li>
										<li className="action-menu-item">+ Vendor Credits</li>
									</ul>
								</div>
							</div>
						</div>

						<ul
							className="dropdown-menu font-14"
							aria-labelledby="moreAction"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									href="#"
								>
									New project...
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									href="#"
								>
									Settings
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									href="#"
								>
									Profile
								</a>
							</li>
							<li>
								<hr className="dropdown-divider" />
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={handleSignout}
								>
									Sign out
								</a>
							</li>
						</ul>
					</div>

					<img
						src={notification}
						alt="mdo"
						width="25"
						height="25"
					/>
					<img
						src={setting}
						alt="mdo"
						width="25"
						height="25"
					/>

					<div className="dropdown text-end">
						<a
							href="#"
							style={{ padding: '4px 0' }}
							className="d-block link-dark text-decoration-none dropdown-toggle px-3"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<img
								src={profile}
								alt="mdo"
								width="32"
								height="32"
								className="rounded-circle me-2"
							/>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									href="#"
								>
									New project...
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									href="#"
								>
									Settings
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									href="#"
								>
									Profile
								</a>
							</li>
							<li>
								<hr className="dropdown-divider" />
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={handleSignout}
								>
									Sign out
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
