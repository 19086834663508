import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './productgroup.scss';
import sortbydot from '../../../../assets/image/sort_by_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BulkImport from '../BulkImport';
import ProductTable from '../ProductTable';
import Button from '../../../../component/ui/Button';
import { change_status, product_list } from '../../../../store/action/product';
import { group_update, product_group_list } from '../../../../store/action/group';
import { ObjectCreate } from '../../../../utils/utility';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import AddToGroupModal from '../AddToGroupModal';
import Modal from '../../../../component/modal/Modal';
import { status_dropdown } from '../../../../utils/dropsdown';
import { formatErrors } from '../../../../utils/controller';
import { ExportTable } from '../../../../store/action/export/Export';
import InputField from '../../../../component/form/FormInput';
import Pagination from '../../../../component/pagination';
import { ProductTableColumn } from '../TableColoum';

const GroupDetails = () => {
	const param = useParams();
	const productId = param?.id;
	const GroupID = param?.id;
	const navigateRouter = (path) => {
		navigate(path);
	};
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [bulkAction, setBulkAction] = useState([]);
	const [open, setIsOpen] = useState(false);
	const [buklImport, setIsbuklImport] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [tableData, setTableData] = useState([]);
	const [groupOption, setGroupOptions] = useState([]);
	const [stateCondition, setStateCondition] = useState('');
	const [groupName, setgroupName] = useState('');
	const [groupStatus, setgroupStatus] = useState('');
	const [error, setError] = useState({});
	const [headerDropDown, setHeaderDropDown] = useState('All Product');
	const [filterparam, setFilterparam] = useState('');
	const [sortParam, setSortParam] = useState('');
	const [productList, setProductList] = useState([]);
	const [loading, setLoader] = useState(false);
	const productgroupData = useSelector((state) => state?.productGroup?.data?.data?.productGroups);
	useEffect(() => {
		const groupName = productgroupData?.filter((ele) => {
			return ele?.product_group_id === Number(productId);
		});
		setgroupName(groupName?.[0]?.name);
		setgroupStatus(`${groupName?.[0]?.status}`);
	}, [productgroupData]);
	useEffect(() => {
		const result = ObjectCreate({ data: productgroupData, key: 'product_group_id', value: 'name' });
		setGroupOptions(result);
	}, [productgroupData]);
	useEffect(() => {
		groupList(param);
	}, []);
	const getAllProduct = (param, value) => {
		setLoader(true);
		setStateCondition(value);
		dispatch(product_list(param))
			.then((res) => {
				if (res?.success) {
					setProductList(res?.data);
					setLoader(false);
				} else {
					setProductList([]);
					setLoader(false);
				}
			})
			.catch((err) => {
				setProductList([]);
				setLoader(false);
			});
	};
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	useEffect(() => {
		if (productId) {
			let param = `filterBy=product_group_id&filterValue=${productId}`;
			getAllProduct(param);
		}
	}, [productId, sortParam, filterparam, currentPage]);
	// product Group Get API
	const groupList = () => {
		let param = `page=${1}&pageSize=20`;
		dispatch(product_group_list(param));
	};

	const filterProduct = (param, value, dropDownData) => {
		setFilterparam(param);
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setSortParam('');
		setCurrentPage(1);
	};
	const sortProduct = (param) => {
		setSortParam(param);
		setCurrentPage(1);
	};
	useEffect(() => {
		let data = [];
		productList?.productWithStockCount?.map((ele) => {
			data?.push({
				id: ele.product_id,
				'PRODUCT NAME': ele?.product_name,
				'PRODUCT CODE': ele?.product_code,
				'STOCK IN HAND': ele?.stockOnHand,
				'REORDER LEVE': 'NA',
				Status: 'Enable',
			});
		});
		setTableData(data);
		let toatlPages = Math.ceil(productList?.totalCount / 20);
		setTotalPages(toatlPages);
	}, [productList]);

	const handleRowDataChange = (newData) => {
		// setRowData(newData);
	};

	const markAsActive = () => {
		let formbody = {
			product_ids: bulkAction,
			status: 1,
		};
		dispatch(change_status(formbody)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				getAllProduct();
			} else {
				toast.error(res?.message);
			}
		});
	};
	const markAsinactive = () => {
		let formbody = {
			product_ids: bulkAction,
			status: 0,
		};
		dispatch(change_status(formbody)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				getAllProduct();
			} else {
				toast.error(res?.message);
			}
		});
	};
	const addTonewGroup = () => {
		setIsOpen(true);
	};
	const handlegroupUpdate = () => {
		let body = {
			name: groupName,
			status: Number(groupStatus),
		};
		dispatch(group_update(GroupID, body)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
			} else {
				toast.error(res?.message);
				let err = formatErrors(res?.data?.message);
				setError(err);
			}
		});
	};
	const exportData = (param) => {
		ExportTable(param);
	};

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						{stateCondition != 'active' && (
							<Button
								type="button"
								button="Mark as Active"
								className="mt-0 px-4 btn-gray"
								onClick={markAsActive}
							/>
						)}
						{stateCondition != 'inactive' && (
							<Button
								type="button"
								button="Mark as Inactive"
								className="mt-0 px-4 btn-gray"
								onClick={markAsinactive}
							/>
						)}
						<Button
							type="button"
							button="New Transcation"
							className="mt-0 px-4 btn-gray"
						/>
						<Button
							type="button"
							button="Add to Group"
							className="mt-0 px-4 btn-primary"
							onClick={addTonewGroup}
							add
						/>
					</div>
				</div>
			) : (
				<div className="product-pg-header">
					{productId ? (
						<div className="dropdown text-end">
							<a className="d-block link-dark text-decoration-none">
								<p>Group Details</p>
							</a>
						</div>
					) : (
						<div className="dropdown text-end">
							<a
								className="d-block link-dark text-decoration-none dropdown-toggle"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<p>{headerDropDown}</p>
							</a>
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a
										className="dropdown-item"
										onClick={() =>
											filterProduct('orderBy=product_code&sortBy=asc', '', 'All Products')
										}
									>
										All Products
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() =>
											filterProduct('filterBy=status&filterValue=1', 'active', 'Acitive Products')
										}
									>
										Acitive Products
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => filterProduct('filterBy=stockOnHand', '', 'Low Stock Products')}
									>
										Low Stock Products
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => filterProduct('', '', 'Sales')}
									>
										Sales
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => filterProduct('', '', 'Purchase')}
									>
										Purchase
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() =>
											filterProduct(
												'filterBy=status&filterValue=0',
												'inactive',
												'Inactive Products',
											)
										}
									>
										Inactive Products
									</a>
								</li>
							</ul>
						</div>
					)}
					<div className="d-flex align-items-center">
						{productId ? (
							<div className="add-product-button">
								<Button
									type="button"
									className="btn-primary mt-0 px-4"
									button="Save"
									onClick={handlegroupUpdate}
								/>
								{/* <Button type="button" className="btn-dark add-pro" button="Cancel" /> */}
								<Button
									type="button"
									className="mt-0 btn-danger add-pro"
									button=""
									close
									onClick={() => navigateRouter(-1)}
								/>
							</div>
						) : (
							// <Button type="button" button="Add New" className="mt-0 px-4 btn-primary" onClick={() => navigateRouter(`/user/${suburl}/product-create`)} add />
							<></>
						)}
						{!productId && (
							<div className="dropdown text-end ms-3">
								<a
									className="d-block link-dark text-decoration-none"
									id="dropdownUser1"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<img src={sortbydot} />
								</a>
								<ul
									className="dropdown-menu font-14"
									aria-labelledby="dropdownUser1"
									style={{ minWidth: '240px' }}
								>
									<li>
										<a className="dropdown-item sortBy">Sort by</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => sortProduct('orderBy=created_at&sortBy=asc ')}
										>
											Created Time
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => sortProduct('orderBy=product_name&sortBy=asc')}
										>
											Name
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => sortProduct('orderBy=product_code&sortBy=asc')}
										>
											Product Code
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => sortProduct('orderBy=stockOnHand&sortBy=asc')}
										>
											Stock on Hand
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => sortProduct('orderBy=updated_at&sortBy=asc')}
										>
											Last Modified Time
										</a>
									</li>
									<hr />
									<li>
										<a
											className="dropdown-item"
											onClick={() => setIsbuklImport(true)}
										>
											Import Product
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => exportData(`product`)}
										>
											Export Product
										</a>
									</li>
									<hr />
									<li>
										<a className="dropdown-item">Prefrence</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => getAllProduct('')}
										>
											Refresh
										</a>
									</li>
								</ul>
							</div>
						)}
						{/* {productId && <Button type="button" className="mt-0 btn-danger add-pro" button="" close onClick={() => navigateRouter(-1)} />} */}
					</div>
				</div>
			)}

			{productId && bulkAction?.length === 0 && (
				<div className="add-group-detail px-4 mx-2 py-3 my-2">
					<div className="row">
						<div className="col-5">
							<InputField
								label="Group Name"
								placeholder="Enter Group Name"
								type="text"
								onChange={(val) => setgroupName(val)}
								value={groupName}
								error={error?.name}
							/>
						</div>
						<div class="col-3">
							<InputSelect
								label="Status"
								placeholder="Status"
								onChange={(value) => setgroupStatus(value)}
								value={groupStatus}
								options={status_dropdown || []}
								error={error?.status}
							/>
						</div>
					</div>
				</div>
			)}
			{loading ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '100%', marginTop: '20%' }}
				>
					<span
						className="Page_loader"
						role="status"
						aria-hidden="true"
					></span>
				</div>
			) : (
				<div className="productTable-body">
					<ProductTable
						row={tableData}
						className="productTable"
						coloum={ProductTableColumn}
						onRowDataChange={handleRowDataChange}
						action={setBulkAction}
					/>
				</div>
			)}

			<Pagination
				currentPage={currentPage}
				totalPages={totalPages}
				onChangePage={handlePageChange}
			/>
			{open && (
				<div>
					<Modal
						className="add-productgroup-modal"
						isOpen={open}
						onClose={() => setIsOpen(false)}
						title="Add to Group"
					>
						<AddToGroupModal
							isOpen={open}
							onCLose={() => setIsOpen(false)}
							reloadTable={getAllProduct}
							groupOption={groupOption}
							action={bulkAction}
						/>
					</Modal>
				</div>
			)}

			{buklImport && (
				<div>
					<Modal
						className="add-productgroup-modal"
						isOpen={buklImport}
						onClose={() => setIsbuklImport(false)}
						title=" Product bulk Import "
					>
						<BulkImport
							isOpen={buklImport}
							onClose={() => setIsbuklImport(false)}
							reloadTable={getAllProduct}
							type={'product'}
						/>
					</Modal>
				</div>
			)}
		</div>
	);
};

export default GroupDetails;
