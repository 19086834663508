import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../../component/ui/Button';
import { toast } from 'react-toastify';
import moment from 'moment';

const CustomDateSelect = ({ isOpen, onCLose, setFilterparam }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [error, setError] = useState('');

    const validateDates = () => {
        if (!fromDate || !toDate) {
            toast.error('Both dates are required')
        }
        if (fromDate > toDate) {
            toast.error('From Date cannot be greater than To Date.')
        }
        return null;
    };

    const handleSubmit = () => {
        const validationError = validateDates();
        if (validationError) {
            setError(validationError);
            return;
        } else {
            let param = `startDate=${fromDate}&endDate=${toDate}&filterBy=date`
            setFilterparam(param)
            onCLose()
        }
    };

    return (
        // <div>
        //     <div className='add-productgroup-form mb-4'>
        //         <div className='row'>
        //             <div className="col-6">
        //                 <div className="form-group text-start">
        //                     <label className='form-label'>From Date</label>
        //                     <DatePicker
        //                         selected={fromDate}
        //                         onChange={(date) => setFromDate(date)}
        //                         dateFormat="yyyy-MM-dd"
        //                         className="form-control w-100"
        //                         placeholderText="Select From Date"
        //                     />
        //                 </div>
        //             </div>
        //             <div className="col-6">
        //                 <div className="form-group text-start">
        //                     <label className='form-label'>To Date</label>
        //                     <DatePicker
        //                         selected={toDate}
        //                         onChange={(date) => setToDate(date)}
        //                         dateFormat="yyyy-MM-dd"
        //                         className="form-control w-100"
        //                         placeholderText="Select To Date"
        //                     />
        //                 </div>
        //             </div>
        //         </div>
        //         {error && <div className="error-message">{error}</div>}
        //     </div>
        //     <div className='add-productgroup-footer'>
        //         <Button type="button" button="Save" className="btn-primary add-pgroup" onClick={handleSubmit} disabled={!fromDate || !toDate} />
        //         <Button type="button" button="Cancel" className="btn-dark add-pgroup" onClick={onCLose} />
        //     </div>
        // </div>
        <div>
            <div className='add-productgroup-form mb-4'>
                <div className='row'>
                    <div className="col-6">
                        <div className="form-group text-start">
                            <label className='form-label'>From Date</label>
                            <DatePicker
                                selected={fromDate ? moment(fromDate).toDate() : null}
                                onChange={(date) => setFromDate(date ? moment(date).format('YYYY-MM-DD') : null)}
                                dateFormat="yyyy-MM-dd"
                                className="form-control w-100"
                                placeholderText="Select From Date"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group text-start">
                            <label className='form-label'>To Date</label>
                            <DatePicker
                                selected={toDate ? moment(toDate).toDate() : null}
                                onChange={(date) => setToDate(date ? moment(date).format('YYYY-MM-DD') : null)}
                                dateFormat="yyyy-MM-dd"
                                className="form-control w-100"
                                placeholderText="Select To Date"
                            />
                        </div>
                    </div>
                </div>
                {error && <div className="error-message text-danger">{error}</div>}
            </div>
            <div className='add-productgroup-footer'>
                <Button type="button" button="Save" className="btn-primary add-pgroup" onClick={handleSubmit} disabled={!fromDate || !toDate} />
                <Button type="button" button="Cancel" className="btn-dark add-pgroup" onClick={onCLose} />
            </div>
        </div>
    );
};

export default CustomDateSelect;
