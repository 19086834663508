// export default PrivateRoute;
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const isAuthenticated = () => {
	return Cookies.get('authToken') || localStorage.getItem('authToken');
};

const PrivateRoute = ({ element, ...rest }) => {
	return isAuthenticated() ? React.cloneElement(element, { ...rest }) : <Navigate to="/" />;
};

export default PrivateRoute;
