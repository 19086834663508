export const getLocalStorageData = () => {
	let data = {};
	const token = localStorage.getItem('authToken');
	if (token) {
		data = {
			token: token,
		};
	}
	return data;
};

export const setRequestHeader = (type) => {
	let loggedInInfo = getLocalStorageData();
	var headerObj = new Headers();

	if (loggedInInfo.token) {
		headerObj.append('Authorization', `Bearer ${loggedInInfo.token}`);
	}
	if (type === 'urlencoded') {
		headerObj.append(
			'Content-Type',
			'application/x-www-form-urlencoded;charset=UTF-8',
			'withCredentials: true',
		);
	}

	return headerObj;
};
export const formatDate = (dateString) => {
	const date = new Date(dateString);
	const month = date.getMonth() + 1; // Month is zero-based, so add 1
	const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
	return `${month}/${year}`;
};
