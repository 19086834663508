import React from 'react';
import bankIcon from '../../../assets/image/bankIcon.svg';
import './AddBankDetails.scss';
import InputField from '../../../component/form/FormInput';

const AddBankDetails = ({
	isEdit,
	handleInputChange,
	formData,
	error,
	setError,
	setReaccount,
	reaccount,
}) => {
	return (
		<div className="addBank-component">
			<div className="col">
				<InputField
					label={<span style={{ fontWeight: '600', fontSize: '14px' }}>Account Holder Name</span>}
					placeholder="Enter Beneficiary Name"
					type="text"
					onChange={(value) => handleInputChange('beneficiary_name', value)}
					value={formData?.beneficiary_name}
					required={true}
					error={error?.beneficiary_name}
				/>
			</div>
			<div style={{ backgroundColor: '#F6F6F9', padding: '2rem' }}>
				<div className="accDetails">
					<div className="accIcon">
						<img
							src={bankIcon}
							alt=""
							style={{ height: '57px', borderRadius: '15px' }}
						/>
					</div>
					<div className="accName">
						<InputField
							label={<span style={{ fontWeight: '600', fontSize: '14px' }}>Bank Name</span>}
							placeholder="Enter Bank Name"
							type="text"
							onChange={(value) => handleInputChange('bank_name', value)}
							value={formData?.bank_name}
							required={true}
							error={error?.bank_name}
						/>
					</div>
				</div>
				<div className="col">
					<InputField
						label={<span style={{ fontWeight: '600', fontSize: '14px' }}>Account Number</span>}
						placeholder="Enter Account Number"
						type="number"
						required={true}
						onChange={(value) => handleInputChange('account_number', value)}
						value={formData?.account_number}
						error={error?.account_number}
					/>
				</div>
				{!isEdit && (
					<div className="col">
						<InputField
							label={
								<span style={{ fontWeight: '600', fontSize: '14px' }}>Confirm Account Number</span>
							}
							placeholder="Confirm Account Number"
							type="number"
							onChange={(value) => {
								setReaccount(value);
								if (value) {
									const newError = { ...error };
									delete newError?.reaccount;
									setError(newError);
								}
							}}
							value={reaccount}
							error={error?.reaccount}
						/>
					</div>
				)}
			</div>
			<div
				style={{
					backgroundColor: '#F6F6F9',
					padding: '2rem',
					marginTop: '1rem',
				}}
			>
				<div className="col">
					<InputField
						label={<span style={{ fontWeight: '600', fontSize: '14px' }}>IFSC Code</span>}
						placeholder="Enter IFSC Code"
						type="text"
						required={true}
						onChange={(value) => handleInputChange('ifsc_code', value)}
						value={formData?.ifsc_code}
						error={error?.ifsc_code}
					/>
				</div>
			</div>
		</div>
	);
};

export default AddBankDetails;
