import React, { useState } from 'react';
import '../NavigationTab.scss'; // Import the custom CSS file

const NavigationTabMain = ({ activeTabMain, setActiveTabMain }) => {
	const navTabs = ['Information', 'Transactions', 'Statement'];

	return (
		<div>
			<ul className="nav mb-4 mt-2">
				{navTabs.map((item, index) => (
					<li
						key={index}
						className="nav-item"
					>
						<a
							className={`nav-link ${activeTabMain === item ? 'active' : ''} cursur-pointer`}
							href="#"
							onClick={() => setActiveTabMain(item)}
						>
							{item}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default NavigationTabMain;
