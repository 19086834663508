export const InventoryAdjustmentTableColoum = [
    // {
    //     field: "",
    //     headerClass: 'HeaderBg',
    //     checkboxSelection: true,
    //     headerCheckboxSelection: true,
    //     minWidth: 50,
    //     maxWidth: 50,
    //     lockPosition: true,
    // },
    {
        field: "DATE",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 200,
        flex: 1
    },
    {
        field: "ACCOUNT",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1
    },
    {
        field: "DESCRIPTON",
        headerClass: 'HeaderBg right-align',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
        cellClass: 'right-align',
    },
    {
        field: "STATUS",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
    },
    {
        field: "REFERENCE NUMBER",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
    },
    {
        field: "REASON",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
    },
    {
        field: "CREATED TIME",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
    }
    // {
    //     field: "ACTION",
    //     headerClass: 'HeaderBg',
    //     suppressSizeToFit: true,
    //     minWidth: 150,
    //     flex: 1
    // }
]