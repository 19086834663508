import React, { useEffect, useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputSelect from '../../component/inputSelect/InputSelect';
import OrderTable from '../purchaseOrder/OrderTable';
import { vendor_list } from '../../store/action/vendor';
import { constant } from '../../utils/constant';
// import OrderTable from './OrderTable'

const CreatePurchaseReceive = () => {
	const today = new Date().toISOString().split('T')[0];
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [date, setDate] = useState(today);
	const [isErrortable, setIsTableError] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [disabled, setDisabled] = useState(id ? true : false);
	const [TableError, setTableError] = useState(false);

	const [vendorList, setVendorList] = useState([]);

	const [selectedVendorId, setSelectedVendorId] = useState(null);
	const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState(null);

	const navigateRouter = (path) => {
		navigate(path);
	};

	useEffect(() => {
		const param = `pageSize=${constant.bigNumber}`;

		dispatch(vendor_list(param))
			.then((res) => {
				if (res?.success) {
					if (res?.data) {
						if (res?.data?.vendors && res?.data?.vendors?.length > 0) {
							setVendorList(res?.data.vendors);
						}
					}
				} else {
					setVendorList([]);
				}
			})
			.catch((err) => {
				setVendorList([]);
			});
	}, []);

	// getting all purchase orders
	const selectedPurchaseOrder = selectedVendorId
		? vendorList
				.find((vendor) => vendor?.vendor_id === selectedVendorId)
				.purchase_order.map((order) => ({
					key: order?.purchase_order_id,
					value: order?.purchase_order_number,
				}))
		: [];

	// getting all purchase order products
	const selectedPurchaseOrderProducts = selectedPurchaseOrderId
		? vendorList
				.find((vendor) => vendor?.vendor_id === selectedVendorId)
				?.purchase_order.find((order) => order.purchase_order_id === selectedPurchaseOrderId)
				?.purchase_order_products
		: [];

	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>Create New Purchase Receive</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save as Received"
								// onClick={() => setDisabled(false)}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/purchases-order`)}
							/>
						</div>
					</div>
				</div>
				{/*  Vendor Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col-6">
							<InputSelect
								options={vendorList.map((vendor) => ({
									key: vendor.vendor_id,
									value: vendor.vendor_name,
								}))}
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								value={selectedVendorId}
								onChange={(value) => setSelectedVendorId(value)}
								// disabled={disabled}
								// error={error?.vendor_name}
								autoFocus={true}
							/>
						</div>
						<div className="col">
							<InputSelect
								options={selectedVendorId ? selectedPurchaseOrder : []}
								label="Order Number #"
								placeholder="Order Number"
								required={true}
								value={selectedPurchaseOrderId}
								onChange={(value) => setSelectedPurchaseOrderId(value)}
								// disabled={disabled}
								// required={true}
								// error={error?.vendor_display_name}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputField
								label="Delivery Address"
								placeholder="Delivery Address"
								type="text"
								// required={true}
								// value={vendorBasicData.email}
								// onChange={(value) => handleBasicInputChange('email', value)}
								// disabled={disabled}
								// error={error?.email}
							/>
						</div>

						<div className="col">
							<InputField
								label="Receive Date"
								placeholder="Receive  Date"
								type="date"
								value={date}
								onChange={(val) => setDate(val)}
								// autoFocus={disabled ? false : true}
								// disabled={disabled}
								required={true}
								// error={error?.date}
							/>
						</div>
					</div>
				</div>
				{/* order Table */}
				<OrderTable
					tableData={selectedPurchaseOrderProducts}
					setTableData={setTableData}
					disabled={disabled}
					paramId={id}
					tableError={TableError}
					setIsTableErrorShow={setIsTableError}
				/>
			</div>
		</div>
	);
};

export default CreatePurchaseReceive;
