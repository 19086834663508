import React from 'react'
import UnderCunstruction from '../underCunstruction'
import './dashboardstyle.scss';
import orderDelivered from '../../assets/image/dashboard/Icon_Delivered.svg'
import orderTotal from '../../assets/image/dashboard/Icon_Order.svg'
import orderCancel from '../../assets/image/dashboard/Icon_Order_cancel.svg'
import totalRevanue from '../../assets/image/dashboard/Icon_Total_revanue.svg'
import iconCalender from '../../assets/image/dashboard/Icon_calender.svg'
import iconArrow from '../../assets/image/dashboard/Icon_arrow.svg'
import iconArrow2 from '../../assets/image/dashboard/Icon_red.svg'


const Dashboard = () => {
    return (
        <div className='dashboard-container'>


<div class="main-container">
        <div class="metric-card">
            <div class="icon">
                <img src={orderTotal} alt="Order Icon"/>
            </div>
            <div class="details">
                <h2>75</h2>
                <p>Total Orders</p>
                <p class="change"><img src={iconArrow} className='greenColor'/> 4% (30 days)</p>
            </div>
        </div>
        <div class="metric-card">
            <div class="icon">
                <img src={orderDelivered} alt="Order Icon"/>
            </div>
            <div class="details">
                <h2>357</h2>
                <p>Total Delivered</p>
                <p class="change"><img src={iconArrow} className='greenColor'/> 4% (30 days)</p>
            </div>
        </div>
        <div class="metric-card">
            <div class="icon">
                <img src={orderCancel} alt="Order Icon"/>
            </div>
            <div class="details">
                <h2>65</h2>
                <p>Total Cancelled</p>
                <p class="change"><img src={iconArrow2} className='greenColor'/> 25% (30 days)</p>
            </div>
        </div>
        <div class="metric-card">
            <div class="icon">
                <img src={totalRevanue} alt="Order Icon"/>
            </div>
            <div class="details">
                <h2>$128</h2>
                <p>Total Revenue</p>
                <p class="change"><img src={iconArrow2} className='greenColor'/> 12% (30 days)</p>
            </div>
        </div>
    </div>





            {/* <!-- First Row with 2 Columns --> */}
            <div className="row row-space frist-row">
                <div className="col-md-6 ">Column 1</div>
                <div className="col-md-6 ">Column 2</div>
            </div>

            {/* <!-- Second Row with 4 Columns --> */}
            <div className="row row-space second-row">
                <div className="col-md-3 ">Card 1</div>
                <div className="col-md-3 ">Card 2</div>
                <div className="col-md-3 ">Card 3</div>
                <div className="col-md-3 ">Card 4</div>
            </div>

            {/* <!-- Third Row with 2 Columns --> */}
            <div className="row row-space third-row">
                <div className="col-md-6 ">Pie chart
                    <div className="row row-space ">
                        <div className="col-md-3 ">circle 1</div>
                        <div className="col-md-3 ">circle 2</div> 
                        <div className="col-md-3 ">circle 3</div>
                    </div>
                </div>
                <div className="col-md-6 ">Column 2</div>
            </div>

            {/* <!-- Fourth Row with 2 Columns --> */}
            <div className="row row-space fourth-row">
                <div className="col-md-6 ">Column 1</div>
                <div className="col-md-6 ">Column 2</div>
            </div>

        </div>
    )
}

export default Dashboard



