import React, { useState } from 'react';

const Configure = ({ onClose }) => {
  const [autoGenerate, setAutoGenerate] = useState(true);
  const [prefix, setPrefix] = useState('PO-');
  const [nextNumber, setNextNumber] = useState('0008');

  const handleSave = () => {
    const configData = {
      autoGenerate,
      prefix: autoGenerate ? prefix : '',
      nextNumber: autoGenerate ? nextNumber : '',
    };
    console.log('Saved Config:', configData);
    onClose(); // Close modal on save
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div style={styles.header}>
          <h2 style={styles.title}>Configure Purchase Order</h2>
          <button onClick={onClose} style={styles.closeButton}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#21263C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6 6L18 18" stroke="#21263C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        <p style={styles.subtext}>
          Your purchase order numbers are set on auto-generate mode to save your time.
        </p>
        <p>Are you sure about changing this setting?</p>

        {/* Radio Options */}
        <div style={styles.option}>
          <input
            type="radio"
            name="orderOption"
            checked={autoGenerate}
            onChange={() => setAutoGenerate(true)}
            style={styles.radio}
          />
          <label style={{ color: '#00000', fontSize: '16px', fontWeight: '600' }}>Continue auto-generating purchase order numbers</label>
        </div>

        {/* Input Fields for Prefix and Next Number */}
        {autoGenerate && (
          <div style={styles.inputContainer}>
            <div>
              <label style={{ color: '#00000', fontWeight: '600', fontSize: '14px', }}>Prefix</label>
              <input
                type="text"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
                style={styles.input}

              />
            </div>
            <div>
              <label style={{ color: '#00000', fontWeight: '600', fontSize: '14px' }}>Next Number</label>
              <input
                type="text"
                value={nextNumber}
                onChange={(e) => setNextNumber(e.target.value)}
                style={styles.input}
              />
            </div>
          </div>
        )}

        <div style={styles.option}>
          <input
            type="radio"
            name="orderOption"
            checked={!autoGenerate}
            onChange={() => setAutoGenerate(false)}
            style={styles.radio}
          />
          <label style={{ color: '#00000', fontSize: '16px', fontWeight: '600' }}>Enter purchase order numbers manually</label>
        </div>

        {/* Action Buttons */}
        <div style={styles.buttonContainer}>
          <button onClick={handleSave} style={styles.saveButton}>Save</button>
          <button onClick={onClose} style={styles.cancelButton}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

// CSS Styles
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    width: '650px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title: {
    margin: 0,
    color: '#000000',
    fontWeight: '600',
    fontSize: '22px'
  },
  closeButton: {
    backgroundColor: '#FF0000',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '6px 11px',
    cursor: 'pointer',
  },
  subtext: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '10px',
    marginTop: '26px'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  radio: {
    width: '18px',
    height: '18px',
    marginRight: '10px',  // Increased the gap between radio button and label
  },
  inputContainer: {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
    borderRadius: '7px',
    border: '1px solid #ccc',
    marginTop: '8px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
    marginTop: '20px',
  },
  saveButton: {
    backgroundColor: '#3B58FF',
    color: '#fff',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '7px',
    cursor: 'pointer',
  },
  cancelButton: {
    backgroundColor: '#424553',
    color: 'white',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '7px',
    cursor: 'pointer',
  },
};

export default Configure;
