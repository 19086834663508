import React, { useEffect, useRef, useState } from 'react';
import './OTPVerify.scss';
import Button from '../../../component/ui/Button';
import otpverify from '../../../assets/image/otpVerify.svg';
import timer from '../../../assets/image/timer.svg';
import OTPInput from 'react-otp-input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { decodeTokenToEmail, encodeEmailToToken } from '../../../utils/controller';
import { user_otp_verification, user_send_otp } from '../../../store/action/auth';
import { useDispatch } from 'react-redux';
import logo from '../../../assets/image/onboarding-logo.png';
import Cookies from 'js-cookie';

const OTPVerify = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(window.location.search);
	const pageLogin = searchParams.get('page_login');
	const pageSignup = searchParams.get('page_signup');

	const [timeLeft, setTimeLeft] = useState(5 * 60);
	const [otp, setOtp] = useState('');
	const [email, setEmail] = useState('');
	const [error, setError] = useState({});
	const otpRefs = useRef([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get('token');
		if (token) {
			const decodedEmail = decodeTokenToEmail(token);
			setEmail(decodedEmail);
		}
	}, [location.search]);

	useEffect(() => {
		if (timeLeft <= 0) return;
		const intervalId = setInterval(() => {
			setTimeLeft((prevTime) => prevTime - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timeLeft]);

	useEffect(() => {
		if (otpRefs.current[0]) {
			otpRefs.current[0].focus();
		}
	}, []);

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleVerifyOTP();
		} else if (event.key === 'Backspace') {
			const currentIndex = otpRefs.current.findIndex((el) => document.activeElement === el);
			if (currentIndex > 0) {
				event.preventDefault(); // Prevents default Backspace behavior (like navigating back in history)
				setOtp((prevOtp) => prevOtp.slice(0, -1)); // Remove last character from OTP
				otpRefs.current[currentIndex - 1].focus(); // Move focus to previous input field
			}
		}
	};

	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
	};

	const handleOtpChange = (otp) => {
		setOtp(otp);
		const nextInputIndex = otp.length;
		if (nextInputIndex < otpRefs.current.length) {
			otpRefs.current[nextInputIndex].focus();
		}
	};

	const handleVerifyOTP = () => {
		let body = {
			otp: otp,
			email: email,
		};
		dispatch(
			user_otp_verification(
				body,
				location.pathname === '/otpVerify/resetpassword'
					? '?verify_email=false'
					: '?verify_email=true',
			),
		).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				if (location.pathname === '/otpVerify/resetpassword') {
					const token = encodeEmailToToken(email);
					navigate(`/createNewPassword/?token=${token}`);
				} else {
					sessionStorage.clear();
					navigate('/organizationDetail');
				}
			} else {
				toast.error(res?.message);
			}
		});
	};

	const handleResendOTP = () => {
		setLoading(true);
		setOtp('');
		let body = {
			email: email,
		};
		if (timeLeft === 0) {
			dispatch(user_send_otp(body)).then((res) => {
				if (res?.success) {
					const nextInputIndex = otp.length;
					if (nextInputIndex < otpRefs.current.length) {
						otpRefs.current[nextInputIndex].focus();
					}
					toast.success(res?.message);
					setTimeLeft(5 * 60);
					setLoading(false);
				}
			});
		} else {
			toast.error('Please wait for the timer to end.');
			setLoading(false);
		}
	};
	const handleBackto = () => {
		localStorage.clear();
		Cookies.remove('authToken');
		if (pageLogin) {
			navigate('/');
		}
		if (pageSignup) {
			navigate('/signup');
		} else {
			navigate('/');
		}
	};
	return (
		<div className="otpverify-form">
			<div className="otpverify-body row clearfix">
				<div className="left-section">
					<div className="illustration">
						{/* <div className="brand-name"><span>Pharma</span><span>Nex</span></div> */}
						<div className="brand-name">
							<img
								src={logo}
								alt=""
							/>
						</div>
						<div className="otpverify-illustration">
							<img
								src={otpverify}
								alt=""
							/>
						</div>
					</div>
				</div>
				<div className="right-section">
					<span
						className="back-button"
						onClick={handleBackto}
						title="Back To Sign In"
					>
						<i className="fa fa-arrow-left"></i>
						Back
					</span>
					<form className="form-body py-4 ms-0">
						<h1 className="form-title">Enter OTP</h1>
						<p className="mt-5 mb-3 text-center font-bold">Verify your email address</p>
						<p className="mt-4 mb-3 text-center">Check the OTP sent to your email address</p>
						{!pageSignup && (
							<p className="mt-4 mb-3 text-center">
								{email}
								<a className="font-bold ms-3"></a>
							</p>
						)}
						{pageSignup && (
							<p className="mt-4 mb-3 text-center">
								{email}
								<Link
									to="/signup"
									className="font-bold ms-3"
								>
									{' '}
									Change
								</Link>
							</p>
						)}
						{pageLogin === false && (
							<p className="mt-4 mb-3 text-center">
								{email}
								<Link
									to="/forgotPassword"
									className="font-bold ms-3"
								>
									{' '}
									Change
								</Link>
							</p>
						)}
						{/* {pageLogin && <p className="mt-4 mb-3 text-center">{email}<a className="font-bold ms-3"></a></p>} */}
						<span className="enter-otp my-5 py-3">
							<OTPInput
								value={otp}
								onChange={handleOtpChange}
								numInputs={4}
								renderSeparator={<span></span>}
								isInputNum={true}
								autoFocus={true}
								renderInput={(props, index) => (
									<input
										{...props}
										ref={(el) => (otpRefs.current[index] = el)}
										onKeyDown={handleKeyDown}
									/>
								)}
							/>
						</span>
						<Button
							type="button"
							className="btn-primary"
							button="Verify OTP"
							onClick={handleVerifyOTP}
							disabled={otp.length !== 4}
						></Button>
						<div className="timer-body">
							<span className="timer">
								<img
									className="align-top me-2"
									src={timer}
									alt="timer"
								/>
								{formatTime(timeLeft)}
							</span>
						</div>
						{timeLeft === 0 && !loading && (
							<p className="mt-5 mb-3 text-center">
								Didn’t receive the code?{' '}
								<span className="primary-color cursor-pointer">
									{
										<a
											className="font-bold"
											onClick={handleResendOTP}
										>
											{' '}
											Resend Code
										</a>
									}
								</span>
							</p>
						)}
						{loading ? (
							<div
								className="d-flex justify-content-center align-items-center"
								style={{ height: '100%', marginTop: '15px' }}
							>
								<span
									className="Page_loader"
									role="status"
									aria-hidden="true"
								></span>
							</div>
						) : (
							''
						)}
					</form>
				</div>
			</div>
		</div>
	);
};

export default OTPVerify;
