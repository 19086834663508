import React, { useState } from 'react';
import InputField from '../../../../component/form/FormInput';
import Button from '../../../../component/ui/Button';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { formatErrors } from '../../../../utils/controller';
import { shelf_create } from '../../../../store/action/shelf';

const Addshelf = ({ isOpen, onCLose, reloadTable, setAutoFocus, setPersistShelf }) => {
	const dispatch = useDispatch();
	const [name, setName] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const submitForm = (e) => {
		e.preventDefault();
		setLoading(true);
		let body = {
			name: name,
		};
		dispatch(shelf_create(body))
			.then((res) => {
				setLoading(false);
				if (res?.success) {
					setPersistShelf(name);
					setAutoFocus({
						catogrey: false,
						product: false,
						shelf: false,
						tax1: true,
					});
					reloadTable();
					toast.success(res?.message);
					onCLose();
				} else {
					formatErrors(res?.data?.message);
					toast.error(res?.response?.data?.message);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<div>
			<form
				onSubmit={submitForm}
				className="add-productgroup-form"
			>
				<InputField
					label="Shelf Name"
					placeholder=""
					type="text"
					required={true}
					name="Enter Shelf Name"
					autoFocus={true}
					onChange={(val) => setName(val)}
					value={name}
					error={error?.name || error}
				/>
				<div className="add-productgroup-footer">
					<Button
						type="submit"
						button="Save"
						className="btn-primary add-pgroup"
						loader={loading}
					/>
					<Button
						type="button"
						button="Cancel"
						className="btn-dark add-pgroup"
						onClick={onCLose}
					/>
				</div>
			</form>
		</div>
	);
};

export default Addshelf;
