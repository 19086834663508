import axios from 'axios';
import { APP_API_URL } from '../../../utils/xhr-instance';
import { getLocalStorageData } from '../../../utils/helper';

export const PURCHASE_ORDER_CREATE_DONE = 'PURCHASE_ORDER_CREATE_DONE';
export const PURCHASE_ORDER_CREATE_FAIL = 'PURCHASE_ORDER_CREATE_DONE';

export const PURCHASE_ORDER_UPDATE_DONE = 'PURCHASE_ORDER_UPDATE_DONE';
export const PURCHASE_ORDER_UPDATE_FAIL = 'PURCHASE_ORDER_UPDATE_UPDAT';

export const PURCHASE_ORDER_LIST_GET_DONE = 'PURCHASE_ORDER_LIST_GET_DONE';
export const PURCHASE_ORDER_LIST_GET_FAIL = 'PURCHASE_ORDER_LIST_GET_DONE';

export const CREATE_PURCHASE_ORDER_API_URL = `${APP_API_URL}/product-group/create`;
export const UPDATE_PURCHASE_ORDER_API_URL = `${APP_API_URL}/product-group/update`;
export const LIST_PURCHASE_ORDER_API_URL = `${APP_API_URL}/purchase-order/get-purchase-order`;
export const GET_PURCHASE_ORDER_NUMBER = `${APP_API_URL}/purchase-order/get-purchase-order-number`;
export const GET_DELIVERY_METHODS = `${APP_API_URL}/purchase-order/get-delivery-method`;
export const CREATE_PURCHASE_ORDER = `${APP_API_URL}/purchase-order/create-purchase-order`;

export const get_purchase_order_number = () => {
	let API_URL = GET_PURCHASE_ORDER_NUMBER;
	const token = getLocalStorageData();
	return () => {
		return axios
			.get(API_URL, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				return err;
			});
	};
};

export const get_delivery_methods = () => {
	let API_URL = GET_DELIVERY_METHODS;
	const token = getLocalStorageData();
	return () => {
		return axios
			.get(API_URL, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				return err;
			});
	};
};

export const purchase_order_create = (formBody) => {
	let API_URL = CREATE_PURCHASE_ORDER_API_URL;
	const token = getLocalStorageData();
	return (dispatch) => {
		return axios
			.post(API_URL, formBody, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: PURCHASE_ORDER_CREATE_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: PURCHASE_ORDER_CREATE_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
export const purchase_order_list = (param) => {
	let API_URL = `${LIST_PURCHASE_ORDER_API_URL}?${param}`;
	const token = getLocalStorageData();
	return (dispatch) => {
		return axios
			.get(API_URL, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: PURCHASE_ORDER_LIST_GET_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: PURCHASE_ORDER_LIST_GET_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};

export const purchase_order_update = (id, formBody) => {
	let API_URL = UPDATE_PURCHASE_ORDER_API_URL;
	const token = getLocalStorageData();
	if (id) {
		API_URL = `${API_URL}/${id}`;
	}
	return (dispatch) => {
		return axios
			.put(API_URL, formBody, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: PURCHASE_ORDER_UPDATE_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: PURCHASE_ORDER_UPDATE_DONE,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};

export const create_purchase_order = (data) => {
	let API_URL = CREATE_PURCHASE_ORDER;
	const token = getLocalStorageData();
	return (dispatch) => {
		return axios
			.post(API_URL, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				return err;
			});
	};
};
