import React, { useEffect, useState } from 'react';
import arrow from '../../assets/image/arrowD.svg';
import InputSelect from '../inputSelect/InputSelect';
import Singleclose from '../../assets/image/SingeCloseRed.svg';

function AdvanceFilterSelectInput({
	label,
	options,
	removeInput,
	placeholder,
	onFocusCb,
	onChange,
	value,
	onKeyDownCb,
	className = ""
}) {
	return (
		<div style={styles.inputGroup} >
			<div
				style={{
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					borderRadius: '8px',
					height: '44px',
					marginTop: '7px'


				}}
			>
				{label && (
					<label
						htmlFor="vendorName"
						style={{
							color: 'black',
							fontWeight: '600',
							width: '30%',
							fontSize: '12px',
						}}
					>
						{label}
					</label>
				)}
				<div
					style={{
						width: label ? '70%' : "100%",
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							// width: '100%',
						}}
					>
						<InputSelect
							className={className}
							options={options}
							onFocusCb={onFocusCb}
							onKeyDownCb={onKeyDownCb}
							placeholder={placeholder}
							onChange={onChange}
							value={value}
						/>
					</div>


				</div>
			</div>
			<div>
				<button
					style={styles.clearButton}
					onClick={removeInput}
				>
					<img
						src={Singleclose}
						alt=""
					/>
				</button>
			</div>
		</div>
	);
}

export default AdvanceFilterSelectInput;

const styles = {
	overlay: {
		position: 'fixed',
		top: '-64px',
		left: '126px',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: '1000',
	},
	popup: {
		backgroundColor: '#fff',
		width: '670px',
		minHeight: '480px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginTop: '160px',
	},
	header: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '-56px',
		backgroundColor: '#F6F6F9',
		padding: '15px 30px',
		height: '70px',
	},
	closeButton: {
		backgroundColor: 'rgb(246, 246, 249)',
		color: '#fff',
		border: 'none',
		borderRadius: '5px',
		padding: '6px 11px',
		cursor: 'pointer',
	},
	note: {
		backgroundColor: '#E6F5FF',
		padding: '10px',
		fontSize: '17px',
		width: '95%',
		margin: 'auto',
		marginTop: '70px',
		color: 'rgba(85, 91, 103, 0.80)',
		fontWeight: '500',
	},
	select: {
		flexGrow: '0.4',
		padding: '8px',
		fontSize: '14px',
		borderRadius: '5px',
		border: '1px solid #ccc',
		color: '#555B67',
		backgroundImage: `url(${arrow})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right 10px center',
		backgroundSize: '12px',
		appearance: 'none', // Hide the default dropdown arrow
		marginLeft: '60px',
		width: '230px',
	},
	clearButton: {
		background: 'none',
		border: 'none',
		fontSize: '16px',
		cursor: 'pointer',
		color: '#f00',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'end',
		marginBottom: '30px',
		marginRight: '30px',
	},
	cancelButton: {
		backgroundColor: '#CCCCCC',
		color: '#000',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '5px',
		cursor: 'pointer',
		marginRight: '20px',
		fontWeight: '600',
		width: '180px',
		height: '40px',
		position: 'sticky',
	},
	applyButton: {
		backgroundColor: '#3B58FF',
		color: '#fff',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '5px',
		fontWeight: '600',
		cursor: 'pointer',
		width: '180px',
		height: '40px',
		position: 'sticky',
	},
	headerTitle: { color: 'black', fontWeight: '700', fontSize: '22px' },
	selectInput: { width: '100%', background: 'transparent', border: 'none' },
	inputGroup: {
		position: 'relative',
		width: '100%',
		marginBottom: '10px',
		display: 'flex',
		// flexDirection: 'column',
		alignItems: 'center'
	},
	inputLabel: { fontWeight: '600', color: 'black', marginBottom: '5px' },
	searchInput: {
		backgroundImage: `url(${arrow})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right 10px center',
		backgroundSize: '12px',
		appearance: 'none', // Hide the default dropdown arrow
		padding: '8px',
		border: '1px solid #ccc',
		borderRadius: '4px',
		color: '#555B67',
		fontSize: '14px',
		width: '100%',
	},
	dropdown: {
		position: 'absolute',
		top: '100%',
		left: '0',
		right: '0',
		background: '#fff',
		border: '1px solid #ddd',
		borderRadius: '4px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
	},
	dropdownItem: {
		padding: '8px',
		cursor: 'pointer',
	},
	noData: {
		padding: '8px',
		color: '#777',
	},
};
