import React, { useEffect, useState } from 'react';
import './CreateNewPassword.scss';
import login from '../../../assets/image/createNewPassword.svg';
import InputField from '../../../component/form/FormInput';
import Button from '../../../component/ui/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { decodeTokenToEmail } from '../../../utils/controller';
import { user_reset_password } from '../../../store/action/auth';
import { toast } from 'react-toastify';
import logo from '../../../assets/image/onboarding-logo.png';
import Cookies from 'js-cookie';
// import InputField from "../../component/form/FormInput";

// import Button from "../../../component/ui/Button";

const CreateNewPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get('token');
		if (token) {
			const decodedEmail = decodeTokenToEmail(token);
			setEmail(decodedEmail);
		}
	}, [location?.search]);

	const handleResetPassword = () => {
		setLoader(true);
		let body = {
			email: email,
			password: password,
			confirmPassword: confirmPassword,
		};
		dispatch(user_reset_password(body)).then((res) => {
			if (res?.success) {
				setLoader(false);
				toast.success(res?.message);
				navigate('/');
			} else {
				setLoader(false);
			}
		});
	};
	const handleBackto = () => {
		localStorage.clear();
		navigate('/forgotPassword');
		Cookies.remove('authToken');
	};
	return (
		<div className="createNewPassword-form">
			<div className="createNewPassword-body row clearfix">
				<div className="left-section">
					<div className="illustration">
						{/* <div className="brand-name"><span>Pharm</span><span>Nex</span></div> */}
						<div className="brand-name">
							<img
								src={logo}
								alt=""
							/>
						</div>
						<div className="createNewPassword-illustration">
							<img
								src={login}
								alt=""
							/>
						</div>
					</div>
				</div>
				<div className="right-section">
					<span
						className="back-button"
						onClick={handleBackto}
					>
						<i className="fa fa-arrow-left"></i>
						Back
					</span>
					<form className="form-body py-4 ms-0">
						<h1 className="form-title">Create New Password</h1>
						<p className="text-center">Create your new password in showing guide</p>
						<p className="text-center mb-5">keep in mind and remember it!</p>
						<InputField
							type="text"
							value={password}
							placeholder="**********"
							label="Password"
							autoFocus={true}
							onChange={(value) => setPassword(value)}
						/>
						<InputField
							type="text"
							placeholder="**********"
							label="New Password"
							onChange={(value) => setConfirmPassword(value)}
							value={confirmPassword}
						/>
						{/* <label className="form-forgotPass"><a href="/forgotPassword"> </a></label> */}
						<Button
							type="button"
							className="btn-primary"
							button="Update"
							onClick={handleResetPassword}
							loader={loader}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default CreateNewPassword;
