import React, { useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { toast } from 'react-toastify';
import { createPaymentTerm } from '../../services/vendor';

const AddPaymentTerms = ({
	paymentTerms,
	setPaymentTerms,
	handleInputChange,
	setOpenPaymentTermsModal,
}) => {
	const [error, setError] = useState('');
	const [paymentTerm, setPaymentTerm] = useState('');

	async function handleAddNewPaymentTerm(event) {
		event.preventDefault();

		// Validate if paymentTerm input is empty
		if (!paymentTerm) {
			setError('Payment terms cannot be empty');
			return;
		}

		try {
			// Call the API to create the payment term
			const createdPaymentTermResp = await createPaymentTerm(paymentTerm);

			// Update payment terms list with the new term
			setPaymentTerms([
				{
					key: createdPaymentTermResp?.payment_terms_id,
					value: 'Net ' + createdPaymentTermResp?.term_name,
				},
				...paymentTerms,
			]);

			// Show success message and update state
			toast.success(createdPaymentTermResp?.message);
			handleInputChange('payment_terms', 'Net ' + createdPaymentTermResp?.term_name);
			setOpenPaymentTermsModal(false);
			setPaymentTerm('');
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	return (
		<div>
			<form
				className="add-productgroup-form"
				onSubmit={handleAddNewPaymentTerm}
			>
				<InputField
					label="Payment Terms"
					placeholder="Enter Payment Terms"
					type="text"
					autoFocus={true}
					value={paymentTerm}
					onChange={(val) => {
						if (/^\d*$/.test(val) || val === '') {
							setPaymentTerm(val);
							setError('');
						} else {
							setError('Only numbers are allowed');
						}
					}}
					error={error}
				/>

				<span>
					<Button
						type="submit"
						className="btn-primary mt-4 px-5 w-auto"
						button="Done"
					/>
				</span>
			</form>
		</div>
	);
};

export default AddPaymentTerms;
