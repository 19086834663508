export const vendorColoum = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'NAME',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'VENDOR DISPLAY NAME',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'WORK PHONE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'PAYABLE',
		headerClass: 'HeaderBg right-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'right-align',
	},
];
