import React, { useState, useRef } from 'react';
import UploadImage from '../../assets/image/imagePlaceholder.jpg';
const ImageUpload = ({ label, onImageUpload, error,disabled }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const inputRef = useRef();

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            onImageUpload(file);
        }
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    return (
        <div className="image-upload-component">
            {label && <label className="form-label">{label}</label>}
            <div className="image-upload-wrapper" onClick={handleClick}>
                {selectedImage ? (
                    <img src={selectedImage} alt="Selected" className="image-preview" />
                ) : (
                    <div className="image-placeholder">
                        <img src={UploadImage} alt="Selected" className="placeholder" />
                        <span>Upload Image</span>
                    </div>
                )}
            </div>
            <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleImageChange}
                disabled = {disabled}
            />
            {error && <div className="error-text" style={{ color: 'red', fontSize: '12px', marginTop: '10px' }}>{error}</div>}
        </div>
    );
};

export default ImageUpload;
