import React, { useState } from 'react';
import arrow from '../../../assets/image/arrow.svg';
import Table from '../../../component/table/Table';

// Arrow Icon as SVG

const ArrowIcon = ({ isOpen, onClick }) => (
	<button
		onClick={onClick}
		style={{
			background: 'none',
			border: 'none',
			cursor: 'pointer',
			paddingLeft: '20px',
			transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
			transition: 'transform 0.3s ease',
		}}
		aria-label="Toggle section"
	>
		<img
			src={arrow}
			alt="Arrow Icon"
		/>
	</button>
);

const faqData = [
	{
		title: 'Purchase Orders',
		content: [
			{
				DATE: '15/05/24',
				'INVOICE#': 'The Walt Disney Company',
				STATUS: 'Active',
				AMOUNT: '14.000',
			},
			{
				DATE: '15/05/24',
				'INVOICE#': 'Louis Vuitton',
				STATUS: 'Active',
				AMOUNT: '14.000',
			},
			{
				DATE: '15/05/24',
				'INVOICE#': 'Mitsubishi',
				STATUS: 'Active',
				AMOUNT: '14.000',
			},
			{
				DATE: '15/05/24',
				'INVOICE#': 'McDonald,s',
				STATUS: 'Active',
				AMOUNT: '14.000',
			},
		],
	},
	{
		title: 'Payments Received',
		content: 'No data available',
	},
	{
		title: 'Purchase Bills',
		content: 'No data available',
	},
	{
		title: 'Payment Made',
		content: 'No data available',
	},
	{
		title: 'Credit Notes',
		content: 'No pending invoices at this moment',
	},
];

const columns = [
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 20,
		flex: 1,
		headerStyle: { paddingLeft: '25px' },
		cellStyle: { color: '#555B67' },
	},
	{
		field: 'INVOICE#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 350,
		flex: 1,
		cellStyle: { color: '#555B67' },
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 350,
		flex: 1,
		cellRenderer: (params) => (
			<span style={{ color: params.value === 'STATUS' ? 'red' : 'green' }}>{params.value}</span>
		),
		cellStyle: { color: '#555B67' },
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 10,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
		cellStyle: { color: '#555B67' },
	},
];

const FaqSection = () => {
	const [openSection, setOpenSection] = useState(null);

	const toggleSection = (index) => {
		setOpenSection(openSection === index ? null : index);
	};

	return (
		<div>
			{faqData.map((section, index) => (
				<div
					key={index}
					style={{ marginBottom: '8px' }}
				>
					{/* Section Header */}
					<div
						style={{
							cursor: 'pointer',
							background: '#F6F6F9',
							// padding: '10px',
							display: 'flex',
							height: '58px',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						{/* Left Arrow Icon */}
						<div
							onClick={() => toggleSection(index)}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<ArrowIcon isOpen={openSection === index} />
							<span style={{ marginLeft: '0px' }}>{section.title}</span>
						</div>

						{/* Right "New +" Button */}
						<button
							onClick={() => toggleSection(index)}
							style={{
								backgroundColor: '#3B58FF',
								fontSize: '16',
								fontWeight: '600',
								marginRight: '8px',
								width: '84px',
								height: '42px',
								color: 'white',
								border: 'none',
								// padding: '4px 8px',
								borderRadius: '6px',
								cursor: 'pointer',
							}}
						>
							New +
						</button>
					</div>

					{/* Section Content */}
					{openSection === index && (
						<div style={{ borderBottom: '1px solid #ccc', backgroundColor: '#F6F6F9' }}>
							{Array.isArray(section.content) ? (
								<>
									{/* Table Content */}
									<div style={styles.tableContainer}>
										<Table
											coloum={columns}
											row={section.content}
											onRowClick={() => {}} // Disable row click
										/>
									</div>
									{/* View All Button */}
									<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
										<button
											style={{
												fontSize: '16',
												fontWeight: '600',
												marginRight: '8px',
												width: '84px',
												height: '42px',
												color: 'black',
												border: '1px solid #3B58FF',
												background: 'transparent',
												padding: '4px 8px',
												marginBottom: '7px',
												borderRadius: '6px',
												cursor: 'pointer',
											}}
										>
											View All
										</button>
									</div>
								</>
							) : (
								<p>{section.content}</p>
							)}
						</div>
					)}
				</div>
			))}
		</div>
	);
};

const styles = {
	tableContainer: {
		width: '100%',
		minHeight: '210px', // Set a maximum height
		overflowY: 'hidden',
	},
};

export default FaqSection;
