import React from 'react';
import InputField from '../../component/form/FormInput';
import { useSelector } from 'react-redux';
import FormSelect from '../../component/form/FormSelect';
import { objectCreater } from '../../utils/controller';

const VendorsAddress = ({ data, error, disabled, handleInputChange }) => {
	let masterData = useSelector((state) => state?.master?.masterData?.data);

	const countryList = objectCreater(masterData?.countries);
	const StateList = objectCreater(masterData?.states);

	console.log(masterData)
	// const validateZipCode = (value) => {
	// 	const regex = /^\d{6}$/;
	// 	return regex.test(value);
	// };

	const handleZipChange = (value) => {
		// if (validateZipCode(value)) {
		handleInputChange('zip_code', value);
		// }
		// else {
		// 	handleInputChange('zip_code', value);
		// 	error.zip_code = 'Invalid Zip';
		// }
	};

	return (
		<div>
			<div className="row">
				<div className="col">
					<InputField
						label="Attention "
						placeholder="Attention"
						type="text"
						value={data.attention}
						onChange={(value) => handleInputChange('attention', value)}
						disabled={disabled}
						error={error?.attention}
					/>
				</div>
				<div className="col">
					<FormSelect
						label="Country "
						placeholder="Select Country"
						onChange={(value) => handleInputChange('country_id', Number(value))}
						value={data.country_id}
						options={countryList}
						disabled={disabled}
						name={'country_id'}
						error={error?.country_id}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<InputField
						label="Address Street 1"
						placeholder="Address Street 1"
						type="text"
						value={data.street_1}
						onChange={(value) => handleInputChange('street_1', value)}
						disabled={disabled}
						error={error?.street_1}
					/>
				</div>
				<div className="col">
					<InputField
						label="Address Street 2 "
						placeholder="Address Street 2"
						type="text"
						value={data.street_2}
						onChange={(value) => handleInputChange('street_2', value)}
						disabled={disabled}
						error={error?.street_2}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<FormSelect
						label="State"
						placeholder="Select State"
						onChange={(value) => handleInputChange('state_id', value)}
						value={data.state_id}
						options={StateList}
						disabled={disabled}
						name={'state_id'}
						error={error?.state ? 'is not allowed to be empty.' : ''}
					/>
				</div>
				<div className="col">
					<InputField
						label="City"
						placeholder="Enter City"
						type="text"
						value={data.city}
						onChange={(value) => handleInputChange('city', value)}
						disabled={disabled}
						error={error?.name}
					/>
				</div>
				<div className="col">
					<InputField
						label="Zip Code"
						placeholder="Zip code"
						type="text"
						value={data.zip_code}
						onChange={handleZipChange}
						disabled={disabled}
						error={error?.zip_code}
					/>
				</div>
			</div>
		</div>
	);
};

export default VendorsAddress;
