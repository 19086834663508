import { PRODUCT_BATCH_LIST_GET_DONE, PRODUCT_LIST_GET_DONE } from "../../action/product";

const initState = {
    data: [],
    error: null,
    status: null,
    currentlogedInuser: null,
};

export const productReducer = (state = initState, action) => {
    switch (action.type) {
        case PRODUCT_LIST_GET_DONE:
            return {
                ...state,
                data: {
                    data: action?.payload?.data?.data,
                    status: action?.payload?.data?.status,
                    error: null,
                },
            };
            case PRODUCT_BATCH_LIST_GET_DONE:
            return {
                ...state,
                data: {
                    data: action?.payload?.data?.data,
                    status: action?.payload?.data?.status,
                    error: null,
                },
            };
        default:
            return state;
    }
};
