import React, { useState } from 'react';
import './ContactPersons.scss';
import CombinedShape from '../../../assets/image/Combined-Shape.svg'

const Transactions = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <button class=" dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ fontWeight: '600', fontSize: '18px', color: 'black', backgroundColor: '#ffffff' }}>
            Purchase Orders
          </button>
          <ul class="dropdown-menu " style={{
            fontSize: '14px',
            boxShadow: '-3px 0px 4px 0px rgba(0, 0, 0, 0.25)',


          }}>
            <li><a class="dropdown-item" href="#">Purchase Orders</a></li>
            <li><a class="dropdown-item" href="#">Sales Orders</a></li>
            <li><a class="dropdown-item" href="#">Invoices</a></li>
            <li><a class="dropdown-item" href="#">Delivery Challans</a></li>
            <li><a class="dropdown-item" href="#">Credit Notes</a></li>
            <li><a class="dropdown-item" href="#">Bills</a></li>
            <li><a class="dropdown-item" href="#">Vendor Credits</a></li>
          </ul >
        </div>

        <div>
          <button class=" dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ fontWeight: '600', fontSize: '18px', color: 'black', backgroundColor: '#ffffff' }}>
            <span style={{ color: '#797979' }}>Status:</span> All
          </button>
          <ul class="dropdown-menu " style={{
            fontSize: '14px',
            boxShadow: '-3px 0px 4px 0px rgba(0, 0, 0, 0.25)',
            fontWeight: '600'

          }}>
            <li><a class="dropdown-item" href="#">All</a></li>
            <li><a class="dropdown-item" href="#">Draft</a></li>
            <li><a class="dropdown-item" href="#">Billed</a></li>
            <li><a class="dropdown-item" href="#">Pertially Billed</a></li>
            <li><a class="dropdown-item" href="#">Cancel</a></li>
            <li><a class="dropdown-item" href="#">Issued</a></li>
            <li><a class="dropdown-item" href="#">Receiveed</a></li>
            <li><a class="dropdown-item" href="#">Pertially Received</a></li>
            <li><a class="dropdown-item" href="#">Closed</a></li>

          </ul >
        </div>


      </div >


      <div style={{ backgroundColor: '#EBEBEB', padding: '5px', borderRadius: '6px' }}>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
          <label class="form-check-label" for="flexCheckDefault">
            Show only Medico Agencies’s transactions
          </label>
        </div>
      </div>
      <br />

      <div className="purchase-order-card">
        {/* Status Bar */}
        <div className="status-bar">
          ISSUED
        </div>

        {/* Header */}
        <div className="order-header" onClick={toggleDropdown}>
          <div className="order-info">
            <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
            <span className="order-date">26/09/2024</span>
          </div>
          <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
            <div className="order-summary d-flex flex-column">
              <span>Total Items: 10</span>
              <span className="expected-date" style={{ color: '#030303' }}>Ordered Quantity: 10</span>
            </div>
            <div>
              {/* <span>{isOpen ? '▲' : '▼'}</span> */}
            </div>
          </div>

        </div>

        {/* Dropdown Content */}
        {/* {isOpen && (
          <div className="order-items">
            {Array(5).fill().map((_, index) => (
              <div key={index} className="order-item">
                <div>Alcet</div>
                <div>Strip</div>
                <div>200</div>
              </div>
            ))}
          </div>
        )} */}
      </div>
      <br />

      <div className="purchase-order-card">
        {/* Status Bar */}
        <div className="status-bar">
          ISSUED
        </div>

        {/* Header */}
        <div className="order-header" onClick={toggleDropdown}>
          <div className="order-info">
            <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
            <span className="order-date">26/09/2024</span>
          </div>
          <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
            <div className="order-summary d-flex flex-column">
              <span>Total Items: 10</span>
              <span className="expected-date" style={{ color: '#030303' }}>Ordered Quantity: 10</span>
            </div>
            <div>
              {/* <span>{isOpen ? '▲' : '▼'}</span> */}
            </div>
          </div>

        </div>

        {/* Dropdown Content */}
        {/* {isOpen && (
          <div className="order-items">
            {Array(5).fill().map((_, index) => (
              <div key={index} className="order-item">
                <div>Alcet</div>
                <div>Strip</div>
                <div>200</div>
              </div>
            ))}
          </div>
        )} */}
      </div>

      <br />

      <div className="purchase-order-card">
        {/* Status Bar */}
        <div className="status-bar" style={{ backgroundColor: '#030303' }}>
          DRAFT
        </div>

        {/* Header */}
        <div className="order-header" onClick={toggleDropdown}>
          <div className="order-info">
            <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
            <span className="order-date">26/09/2024</span>
          </div>
          <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
            <div className="order-summary d-flex flex-column">
              <span>Total Items: 10</span>
              <span className="expected-date" style={{ color: '#030303' }}>Ordered Quantity: 10</span>
            </div>
            <div>
              {/* <span>{isOpen ? '▲' : '▼'}</span> */}
            </div>
          </div>

        </div>

        {/* Dropdown Content */}
        {/* {isOpen && (
          <div className="order-items">
            {Array(5).fill().map((_, index) => (
              <div key={index} className="order-item">
                <div>Alcet</div>
                <div>Strip</div>
                <div>200</div>
              </div>
            ))}
          </div>
        )} */}
      </div>

      <br />

      <div className="purchase-order-card">
        {/* Status Bar */}
        <div className="status-bar">
          ISSUED
        </div>

        {/* Header */}
        <div className="order-header" onClick={toggleDropdown}>
          <div className="order-info">
            <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
            <span className="order-date">26/09/2024</span>
          </div>
          <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
            <div className="order-summary d-flex flex-column">
              <span>Total Items: 10</span>
              <span className="expected-date" style={{ color: '#030303' }}>Ordered Quantity: 10</span>
            </div>
            <div>
              {/* <span>{isOpen ? '▲' : '▼'}</span> */}
            </div>
          </div>

        </div>

        {/* Dropdown Content */}
        {/* {isOpen && (
          <div className="order-items">
            {Array(5).fill().map((_, index) => (
              <div key={index} className="order-item">
                <div>Alcet</div>
                <div>Strip</div>
                <div>200</div>
              </div>
            ))}
          </div>
        )} */}
      </div>

      <br />
      <div className="purchase-order-card">
        {/* Status Bar */}
        <div className="status-bar" style={{ backgroundColor: '#030303' }}>
          DRAFT
        </div>

        {/* Header */}
        <div className="order-header" onClick={toggleDropdown}>
          <div className="order-info">
            <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
            <span className="order-date">26/09/2024</span>
          </div>
          <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
            <div className="order-summary d-flex flex-column">
              <span>Total Items: 10</span>
              <span className="expected-date" style={{ color: '#030303' }}>Ordered Quantity: 10</span>
            </div>
            <div>
              {/* <span>{isOpen ? '▲' : '▼'}</span> */}
            </div>
          </div>

        </div>

        {/* Dropdown Content */}
        {/* {isOpen && (
          <div className="order-items">
            {Array(5).fill().map((_, index) => (
              <div key={index} className="order-item">
                <div>Alcet</div>
                <div>Strip</div>
                <div>200</div>
              </div>
            ))}
          </div>
        )} */}
      </div>
      <br />
      <div className="purchase-order-card">
        {/* Status Bar */}
        <div className="status-bar">
          ISSUED
        </div>

        {/* Header */}
        <div className="order-header" onClick={toggleDropdown}>
          <div className="order-info">
            <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
            <span className="order-date">26/09/2024</span>
          </div>
          <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
            <div className="order-summary d-flex flex-column">
              <span>Total Items: 10</span>
              <span className="expected-date" style={{ color: '#030303' }}>Ordered Quantity: 10</span>
            </div>
            <div>
              {/* <span>{isOpen ? '▲' : '▼'}</span> */}
            </div>
          </div>

        </div>

        {/* Dropdown Content */}
        {/* {isOpen && (
          <div className="order-items">
            {Array(5).fill().map((_, index) => (
              <div key={index} className="order-item">
                <div>Alcet</div>
                <div>Strip</div>
                <div>200</div>
              </div>
            ))}
          </div>
        )} */}
      </div>



      <br />
      <br />

    </div >
  );
};

export default Transactions;
