import React, { useState } from 'react';
import './ContactPersons.scss';
import email from '../../../assets/image/email.svg';
import phone from '../../../assets/image/phone-call.svg';


const DetailsTab = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div>
      <div className='d-flex' style={{ gap: '50px' }}>
        <div>
          <div style={{
            color: '#000',
            fontSize: '17px',

          }}>Total Outstanding</div>
          <div style={{
            color: '#000',
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '30px'

          }}>INR  3,000,00.00</div>
        </div>
        <div style={{ borderRight: '1px solid #C3CBDC' }}></div>
        <div>
          <div style={{
            color: '#000',
            fontSize: '17px',

          }}>Total Credits</div>
          <div style={{
            color: '#000',
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '30px'


          }}>INR  500.00</div>
        </div>
      </div>
      <br />

      <div style={{
        border: '1px solid #D0D0D0',
        borderRadius: '8px',
        padding: '16px',
        maxWidth: '100%',
        fontFamily: 'Arial, sans-serif'
      }}>
        <h3 style={{ borderBottom: '1px solid #D0D0D0', paddingBottom: '8px', fontWeight: '600' }}>Other Details</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div className='ListQ'>Customer Type</div>
          <div>Individual | Composition</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', }}>
          <div className='ListQ'>DL Number</div>
          <div>87876-87877</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div className='ListQ'>PAN</div>
          <div>NA</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div className='ListQ'>GSTIN</div>
          <div>NA</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div className='ListQ'>Currency</div>
          <div>INR</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div className='ListQ'>Payment Terms</div>

          <div>Net 15</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div className='ListQ'>Website URL</div>
          <div>
            <a href="https://www.shreerammedicals.com" target="_blank" rel="noopener noreferrer">
              shreerammedicals.com
            </a>
          </div>
        </div>
      </div>
      <br />

      <div className="contact-card">
        {/* Dropdown Header */}
        <div className="dropdown-header" onClick={toggleDropdown}>
          <h3 style={{
            fontWeight: '600',
            fontSize: '16px',
            color: '#000',

          }}>Contact Persons <span className="badge">2</span></h3>
          <span>{isOpen ? '▲' : '▼'}</span>
        </div>

        {/* Dropdown Content */}
        {isOpen && (
          <div className="contact-details">
            {/* Contact 1 */}
            <div className="contact-item">
              <div className="contact-avatar">R</div>
              <div>
                <div className="contact-name">Mr. Raj Kumar</div>
                <div className="contact-info">
                  <span role="img" aria-label="phone"> <img src={phone} alt="" /></span> +91 1234567890
                </div>
                <div className="contact-info">
                  <span role="img" aria-label="email"> <img src={email} alt="" /></span> rajkumar@gmail.com
                </div>
              </div>
            </div>

            {/* Divider Line */}
            <div className="divider"></div>

            {/* Contact 2 */}
            <div className="contact-item">
              <div className="contact-avatar">R</div>
              <div>
                <div className="contact-name">Mr. Ram Kumar</div>
                <div className="contact-info">
                  <span role="img" aria-label="phone"> <img src={phone} alt="" /></span> +91 1234567890
                </div>
                <div className="contact-info">
                  <span role="img" aria-label="email"> <img src={email} alt="" /></span> ramkumar@gmail.com
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <br />

      <div className="contact-card">
        {/* Dropdown Header */}
        <div className="dropdown-header" onClick={toggleDropdown}>
          <h3 style={{
            fontWeight: '600',
            fontSize: '16px',
            color: '#000',

          }}>Address</h3>
          <span>{isOpen ? '▲' : '▼'}</span>
        </div>

        {/* Dropdown Content */}
        {isOpen && (
          <div className="contact-details">

            <div style={{
              color: '#555B67',
              fontSize: '16px',
              fontWeight: '600',
            }}>Billing Address</div>
            <div>
              <div style={{
                borderLeft: '1px solid black',
                padding: '5px',
                color: '#555b67'
              }}>
                103/3 Kumbha Marg Pratap Nagar Sanganare <br />
                Jaipur <br />
                Rajasthan 302033  <br />
                India <br />
                Phone: 9602320214<br />

              </div>
            </div>

            <div style={{
              color: '#555B67',
              fontSize: '16px',
              fontWeight: '600',
            }}>Shipping Address</div>
            <div>
              <div style={{
                borderLeft: '1px solid black',
                padding: '5px',
                color: '#555b67'
              }}>
                103/3 Kumbha Marg Pratap Nagar Sanganare <br />
                Jaipur <br />
                Rajasthan 302033  <br />
                India <br />
                Phone: 9602320214<br />

              </div>







            </div>






          </div>
        )}
      </div>


      <br />
      <br />





    </div>
  );
};

export default DetailsTab;
