import React from "react";

const ImageRenderer = ({ value }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "4px",
      }}
    >
      <img
        src={
          value
            ? value
            : "https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
        }
        alt="Product"
        style={{
          width: "34px",
          height: "34px",
          objectFit: "cover",
          borderRadius: "30px",
          border: "1px solid grey",
        }}
      />
    </div>
  );
};

export default ImageRenderer;
