import React, { useState } from 'react';
import Button from '../../component/ui/Button';
import { useNavigate } from 'react-router-dom';

function EmailModal() {
	const [fromEmail, setFromEmail] = useState("Ram Kumar <ramkumar@gmail.com>");
	const [toEmail, setToEmail] = useState("Ram Kumar <ramkumar@gmail.com>");
	const [subject, setSubject] = useState("Purchase Order from Medical Store (Purchase Order #: PO-0001)");
	const [message, setMessage] = useState("");
	const suburl = localStorage.getItem('suburl');
	const navigate = useNavigate();
	const [attachPdf, setAttachPdf] = useState(true);
	const [showFromDropdown, setShowFromDropdown] = useState(false);
	const [showToDropdown, setShowToDropdown] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [senders] = useState([
		"Ram Kumar <ramkumar@gmail.com>",
	]); // List of emails for the dropdown

	const handleSend = () => {
		// Send email logic here
		console.log("Email Sent!");
	};

	const filteredSenders = senders.filter(sender =>
		sender.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const navigateRouter = (path) => {
		navigate(path);
	};



	return (
		<div>
			<div className='d-flex justify-content-between' style={{ backgroundColor: '#EDEDF7', padding: '1rem 2rem', alignItems: 'center' }}>
				<h2 style={{ color: '#0F1C35', fontWeight: '600' }}>Email To Medico Agencies</h2>
				<div className='d-flex' style={{ gap: '12px' }}>
					<Button
						type="button"
						className="btn-primary mt-0 px-4"
						button="Send"
						onClick={handleSend}
					/>
					<Button
						type="button"
						className="mt-0 btn-danger add-pro"
						button=""
						close
						onClick={() => navigateRouter(`/user/${suburl}/purchases-order`)}
					/>
				</div>
			</div>
			<div style={{
				width: '950px', border: '1px solid #ccc', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', marginTop: '20px', borderRadius: '10px'
			}}>
				{/* From Email */}
				<div style={{ marginBottom: '18px', marginTop: '12px', display: 'flex', alignItems: 'center', borderBottom: '2px solid #ccc', width: '870px' }}>
					<label style={{ fontWeight: '500', width: '80px', color: '#888888', fontSize: '16px' }}>From</label>
					<div style={{ position: 'relative', flex: 1 }}>
						<input
							type="email"
							value={fromEmail}
							onClick={() => setShowFromDropdown(!showFromDropdown)}
							onChange={(e) => setFromEmail(e.target.value)}
							placeholder="Enter your email"
							style={{
								padding: '10px',
								border: 'none',
								outline: 'none',
								fontSize: '16px',
								width: '100%',
								borderBottom: 'none',
								boxShadow: 'none',
								fontWeight: '500',
								color: '#000000'
							}}
						/>
						{/* Dropdown for "From" field */}
						{showFromDropdown && (
							<div style={{
								position: 'absolute', top: '52px', left: '0', width: '100%', border: '1px solid #ccc', backgroundColor: 'white', borderRadius: '4px', zIndex: 10, maxHeight: '200px', overflowY: 'auto', borderTop: 'none',
							}}>
								<input
									type="text"
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
									placeholder="Search"
									style={{
										padding: '9px',
										width: '98%',
										backgroundColor: 'white',
										border: '1px solid #8c8ce1',
										margin: '4px',
										borderRadius: '6px',
										fontSize: '16px'
									}}
								/>
								{/* Displaying email list */}
								<div style={{ padding: '10px' }}>
									{filteredSenders.length === 0 ? (
										<div>No senders found</div>
									) : (
										filteredSenders.map((sender, index) => (
											<div
												key={index}
												style={{
													padding: '10px',
													cursor: 'pointer',
													marginBottom: '10px',
													transition: 'background-color 0.3s ease',
													backgroundColor: 'transparent',
													borderRadius: '5px',
													color: '#888888',
													fontSize: '16px'
												}}
												onClick={() => {
													setFromEmail(sender);
													setShowFromDropdown(false);
												}}
												onMouseEnter={(e) => {
													e.target.style.backgroundColor = '#3B58FF';
													e.target.style.color = 'white';
												}}
												onMouseLeave={(e) => {
													e.target.style.backgroundColor = 'transparent';
													e.target.style.color = '#888888';
												}}
											>
												{sender}
											</div>
										))
									)}
									<div
										style={{
											padding: '11px', cursor: 'pointer', color: 'blue', backgroundColor: '#EDEDF7', borderRadius: '4px', fontWeight: '500'
										}}
										onClick={() => {
											setFromEmail('');
											setShowFromDropdown(false);
										}}
									>
										+ Add New Sender Email
									</div>
								</div>
							</div>
						)}
					</div>
				</div>

				{/* To Email */}
				<div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', borderBottom: '2px solid #ccc', width: '870px' }}>
					<label style={{ fontWeight: '500', width: '80px', color: '#888888', fontSize: '16px' }}>Send To</label>
					<div style={{ position: 'relative', flex: 1 }}>
						<input
							type="email"
							value={toEmail}
							onClick={() => setShowToDropdown(!showToDropdown)}
							onChange={(e) => setToEmail(e.target.value)}
							placeholder="Enter recipient's email"

							style={{
								padding: '10px',
								border: 'none',
								outline: 'none',
								fontSize: '16px',
								width: '100%',
								borderBottom: 'none',
								boxShadow: 'none',
								fontWeight: '500',
								color: '#000000',
							}}
						/>
						{/* Dropdown for "Send To" field */}
						{showToDropdown && (
							<div style={{
								position: 'absolute', top: '52px', left: '0', width: '100%', border: '1px solid #ccc', backgroundColor: 'white', borderRadius: '4px', zIndex: 10, maxHeight: '200px', overflowY: 'auto', borderTop: 'none',
							}}>
								<input
									type="text"
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
									placeholder="Search"
									style={{
										padding: '9px',
										width: '98%',
										backgroundColor: 'white',
										border: '1px solid #8c8ce1',
										margin: '4px',
										borderRadius: '6px',
										fontSize: '16px'
									}}
								/>
								{/* Displaying email list */}
								<div style={{ padding: '10px' }}>
									{filteredSenders.length === 0 ? (
										<div>No senders found</div>
									) : (
										filteredSenders.map((sender, index) => (
											<div
												key={index}
												style={{
													padding: '10px',
													cursor: 'pointer',
													marginBottom: '10px',
													transition: 'background-color 0.3s ease',
													backgroundColor: 'transparent',
													borderRadius: '5px',
													color: '#888888',
													fontSize: '16px'
												}}
												onClick={() => {
													setToEmail(sender);
													setShowToDropdown(false);
												}}
												onMouseEnter={(e) => {
													e.target.style.backgroundColor = '#3B58FF';
													e.target.style.color = 'white';
												}}
												onMouseLeave={(e) => {
													e.target.style.backgroundColor = 'transparent';
													e.target.style.color = '#888888';
												}}
											>
												{sender}
											</div>
										))
									)}
								</div>
							</div>
						)}
					</div>
				</div>

				{/* Subject */}
				<div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', borderBottom: '2px solid #ccc', width: '870px' }}>
					<label style={{ fontWeight: '500', width: '80px', color: '#888888', fontSize: '16px' }}>Subject</label>
					<input
						type="text"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						placeholder="Enter subject"
						style={{
							flex: 1,
							padding: '10px',
							border: 'none',
							outline: 'none',
							fontSize: '16px',
							fontWeight: '500',
							color: '#000000',
							background: 'none',
							boxShadow: 'none',
						}}
					/>
				</div>

				{/* Message */}
				<div style={{ marginBottom: '20px' }}>
					<label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px', width: '870px', color: '#888888' }}>Message</label>
					<textarea
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						placeholder="Type your message here..."
						rows="6"
						style={{
							width: '100%',
							padding: '10px',
							border: '1px solid #ccc',
							borderRadius: '5px',
							outline: 'none',
							fontSize: '16px',
							resize: 'vertical',
						}}
					/>
				</div>

				{/* Attach PDF Checkbox */}
				<div style={{
					width: '870px',
					display: 'flex',
					alignItems: 'center',
					marginBottom: '15px',
					justifyContent: 'space-between',
					border: '1px solid #ccc',
					borderRadius: '5px',
					padding: '10px',
				}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<input
							type="checkbox"
							checked={attachPdf}
							onChange={() => setAttachPdf(!attachPdf)}
							style={{ marginRight: '10px' }}
						/>
						<label style={{ fontWeight: '600' }}>Attach Purchase Order PDF</label>
					</div>

					{attachPdf && (
						<div
							style={{
								alignItems: 'center',
								marginLeft: '20px',
								border: '1px solid #ccc',
								borderRadius: '4px',
								padding: '5px 10px',
								width: '200px'
							}}
						>
							<span>PO-00110</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default EmailModal;
