import React, { useEffect, useRef, useState } from 'react';
import './ForgotPassword.scss';
import forgotPassword from '../../../assets/image/forgot_password.svg';
// import forgotPassword from '../../../assets/image/login_illustration.svg'
import flag from '../../../assets/image/flag.png';
import logo from '../../../assets/image/onboarding-logo.png';

import InputField from '../../../component/form/FormInput';
import Button from '../../../component/ui/Button';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { encodeEmailToToken, formatErrors } from '../../../utils/controller';
import { toast } from 'react-toastify';
import { user_send_otp } from '../../../store/action/auth';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
const ForgotPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [inputValue, setInputValue] = useState({ email: '' });
	const [error, setError] = useState({});
	const [loader, setLoader] = useState(false);
	const { email } = inputValue;

	const handleChange = (inputValue) => {
		setInputValue({ email: inputValue });
	};

	const handleForgotPassword = (e) => {
		e.preventDefault();
		setLoader(true);
		let body = {
			email: email,
		};
		const token = encodeEmailToToken(email);
		dispatch(user_send_otp(body)).then((res) => {
			if (res?.success) {
				setLoader(false);
				toast.success(res?.message);
				navigate(`/otpVerify/resetpassword?token=${token}`);
			} else {
				toast.error(res?.message);
				let error = formatErrors(res?.message);
				setError(error);
				setLoader(false);
			}
		});
	};
	const handleBackto = () => {
		localStorage.clear();
		navigate('/');
		Cookies.remove('authToken');
	};
	return (
		<div className="forgotPassword-form">
			<div className="forgotPassword-body row clearfix">
				<div className="left-section">
					<div className="illustration">
						<div className="brand-name">
							<img
								src={logo}
								alt=""
							/>
						</div>
						<div className="forgotPassword-illustration">
							<img
								src={forgotPassword}
								alt=""
							/>
						</div>
					</div>
				</div>
				<div className="right-section">
					<span
						className="back-button"
						onClick={handleBackto}
						title="Back To Sign In"
					>
						<i className="fa fa-arrow-left"></i>
						Back
					</span>
					<form
						onSubmit={handleForgotPassword}
						className="form-body py-4 ms-0"
					>
						<h1 className="form-title">Forgot Password?</h1>
						<InputField
							type="text"
							value={email}
							placeholder="yourname@domain.com"
							label="Email/Username"
							onChange={handleChange}
							error={error?.email}
							required
							name={'email'}
							autoComplete={'email'}
							autoFocus={true}
						/>
						<Button
							type="submit"
							className="btn-primary"
							button="Submit"
							loader={loader}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
