import React from 'react';
import PropTypes from 'prop-types';
// import "./modal.scss";
import Button from '../ui/Button';

const ConfirmationModal = ({
	isOpen,
	onClose,
	title,
	content,
	className,
	onCancel,
	onOk,
	loader,
}) => {
	if (!isOpen) return null;

	return (
		<div
			className={`modal-backdrop ${className}`}
			onClick={onClose}
		>
			<div
				className="modal-content-confirmation-modal"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="modal-header">
					<p style={{ fontSize: '16px', fontWeight: '600' }}>{title}</p>
					{onClose && (
						<Button
							type="button"
							className="mt-0 btn-danger add-pro"
							button=""
							close
							onClick={onClose}
						/>
					)}
				</div>
				<div className="my-3">
					<div
						style={{
							padding: '10px',
						}}
					>
						{content}
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'end',
							gap: 10,
							padding: '10px',
						}}
					>
						{onCancel && (
							<Button
								type="button"
								button="Cancel"
								className="btn-gray add-pgroup"
								onClick={onCancel}
							/>
						)}
						{onOk && (
							<Button
								type="submit"
								button="Ok"
								className="btn-danger add-pgroup"
								loader={loader}
								onClick={onOk}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

ConfirmationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	children: PropTypes.node,
};

ConfirmationModal.defaultProps = {
	title: '',
	content: null,
};

export default ConfirmationModal;
