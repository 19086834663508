import React, { useState } from 'react';
import InputSelect from '../../component/inputSelect/InputSelect';
import Button from '../../component/ui/Button';

const UpdateCurrency = ({ value, handelPaymentTermsInputChange, handleSaveClick, onCLose }) => {
	const [error, setError] = useState('');

	const [currency, setCurrency] = useState('INR');

	return (
		<div>
			<form className="add-productgroup-form">
				<InputSelect
					options={[]}
					label="Currency"
					placeholder="Vendor Currency"
					returnInputValue={true}
					onChange={(value) => {}}
					value={currency}
					disabled={true}
					required={true}
					error={error?.currency}
				/>
				<span className="">
					<Button
						type="button"
						className="btn-primary mt-4 px-5 w-auto "
						button="Update"
						onClick={() => handleSaveClick(currency)}
					></Button>
				</span>
			</form>
		</div>
	);
};

export default UpdateCurrency;
