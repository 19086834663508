
import React, { useState } from 'react';
import './checkBoxStyles.scss';

const Checkbox = ({ label, checked, onChange, disabled ,required}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = (event) => {
    if (disabled) return;
    const newCheckedState = event.target.checked;
    setIsChecked(newCheckedState);
    onChange(newCheckedState);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !disabled) {
      const newCheckedState = !isChecked;
      setIsChecked(newCheckedState);
      onChange(newCheckedState);
    }
  };

  return (
    <div>
      <div className="form-check">
        <input
          checked={isChecked}
          onChange={handleCheckboxChange}
          onKeyPress={handleKeyPress}
          disabled={disabled}
          className="form-check-input custumCheckBox"
          type="checkbox"
          id="flexCheckDefault"
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          {label}
          {required && <span style={{ color: 'red', marginLeft: '.2rem' }}>*</span>}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
