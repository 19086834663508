import React, { useEffect } from "react";
import closei from "../../assets/image/x-square.svg";

const Button = ({
  button,
  type,
  onClick,
  loader,
  disabled,
  className,
  add,
  close,
  id,
}) => {
  return (
    <div id={id}>
      {/* <button type={type}  className="w-100 btn btn-lg btn-primary" onClick={onClick} disabled = {disabled}>{loader ? <i className="fa fa-refresh fa-spin"></i> : button}</button> */}
      <button
        type={type}
        className={`w-100 btn btn-lg font-14 m-0 ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {loader ? <i className="fa fa-refresh fa-spin"></i> : button}
        {add && (
          <i
            className="fa fa-plus"
            style={{
              strokeWidth: ".1rem",
              "-webkit-text-stroke-color": "var(--brand-color)",
              "-webkit-text-stroke-width": "1px",
              marginLeft: "0.5rem",
            }}
          ></i>
        )}
        {close && <img src={closei} style={{}} />}
      </button>
    </div>
  );
};

export default Button;
