import React, { useEffect, useState } from 'react';
import Modal from '../../component/modal/Modal';
import ContactPersonTable from './ContactPerson/ContactPersonTable';
import AddContactPerson from './ContactPerson/AddContactPerson';
import "./contactPerson.scss"

const ContactPerson = ({ isEdit, vendorContact, setVendorContact, reloadTable, vendorId }) => {
	const [open, setOpen] = useState(false);
	const [selectedContactPersonIndex, setSelectContactPersonIndex] = useState(null);
	const [contactTableData, setContactTableData] = useState([])

	const handleModalOpen = (index) => {
		setOpen(true);
		setSelectContactPersonIndex(index);
	};

	function handleMappedContactTableData() {
		const mappedData = vendorContact?.map((contact) => {
			return {
				salutation: contact?.salutation,
				name: contact.first_name + " " + contact?.last_name || "-",
				mobile: contact?.mobile?.length || "-",
				work_phone: contact?.work_phone || "-",
				email: contact?.email || "-",
				...contact
			}
		})
		setContactTableData(mappedData)
	}

	useEffect(() => {
		handleMappedContactTableData()
	}, [vendorContact])

	console.log(contactTableData)

	return (
		<div>
			<div
				className="add-contactv"
				onClick={() => {
					if (isEdit) {
						const areYouSure = window.confirm(
							'Changes will be lost. Please make sure to save your changes before adding new content.',
						);
						if (!areYouSure) return;
					}
					setOpen(true);
				}}
			>
				<i className="fa fa-plus"></i>
				<span>Add New Contact</span>
			</div>
			{vendorContact?.length > 0 && (
				<ContactPersonTable
					className="contactVendorTable"
					vendorId={vendorId}
					tableData={contactTableData}
					setTableData={setVendorContact}
					handleModalOpen={handleModalOpen}
				/>
			)}

			<div>
				{open && (
					<div>
						<Modal
							className="add-contact-modal"
							isOpen={open}
							onClose={() => {
								setOpen(false);
								setSelectContactPersonIndex(null);
							}}
							title="Add Contact Person"
							cancelButton={true}
						>
							<AddContactPerson
								isOpen={open}
								onClose={() => {
									setOpen(false);
									setSelectContactPersonIndex(null);
								}}
								selectedContactPersonIndex={selectedContactPersonIndex}
								tableData={vendorContact}
								setTableData={setVendorContact}
								reloadTable={reloadTable}
								vendorId={vendorId}
							/>
						</Modal>
					</div>
				)}
			</div>
		</div>
	);
};

export default ContactPerson;
