import { RouterProvider } from "react-router-dom";
import './App.scss';
import { Approuter } from "./router";


const App = () => {
  return (
    <div className="wrapper">
      <RouterProvider router={Approuter} />
    </div>
  );
}

export default App

