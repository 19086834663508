import axios from 'axios';
import { APP_API_URL } from '../../../utils/xhr-instance';
import { getLocalStorageData } from '../../../utils/helper';

export const SHELF_CREATE_DONE = 'SHELF_CREATE_DONE';
export const SHELF_CREATE_FAIL = 'SHELF_CREATE_DONE';

export const SHELF_LIST_GET_DONE = 'SHELF_LIST_GET_DONE';
export const SHELF_LIST_GET_FAIL = 'SHELF_LIST_GET_DONE';

export const CREATE_SHELF_API_URL = `${APP_API_URL}/shelf/create`;
export const LIST_SHELF_API_URL = `${APP_API_URL}/shelf/list`;

export const shelf_create = (formBody) => {
	let API_URL = CREATE_SHELF_API_URL;
	const token = getLocalStorageData();
	return (dispatch) => {
		return axios
			.post(API_URL, formBody, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: SHELF_CREATE_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: SHELF_CREATE_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
export const shelf_list = (param) => {
	let API_URL = LIST_SHELF_API_URL;
	const token = getLocalStorageData();
	if (param) {
		API_URL = `${LIST_SHELF_API_URL}?${param}`;
	}
	return (dispatch) => {
		return axios
			.get(API_URL, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: SHELF_LIST_GET_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: SHELF_LIST_GET_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
