module.exports = {
	vendor: [
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'vendor_display_name', value: 'Vendor Display Name' },
		{ key: 'email', value: 'Email' },
		{ key: 'phone_mobile', value: 'Phone Number' },
		{ key: 'vendor_id', value: 'Vendor Id' },
		{ key: 'status', value: 'Status' },
	],
};
