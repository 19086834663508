import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { group_create } from '../../../../store/action/group'
// import { toast } from 'react-toastify'
// import { formatErrors } from '../../../../utils/controller'
import InputSelect from '../../../component/inputSelect/InputSelect';
import Button from '../../../component/ui/Button';
import { toast } from 'react-toastify';
import { add_product_to_group } from '../../../store/action/product';

const AddToGroupModal = ({ isOpen, onCLose, reloadTable, groupOption, action }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [groupId, setgroupId] = useState('');
	const [error, setError] = useState('');

	const SubmitForm = () => {
		let body = {
			product_ids: action,
			product_group_id: groupId,
		};
		dispatch(add_product_to_group(body)).then((res) => {
			if (res?.success) {
				toast.success(res?.message);
				onCLose();
				reloadTable();
			} else {
				toast.error(res?.message);
			}
		});
	};
	return (
		<div>
			<div className="add-productgroup-form mb-3">
				<InputSelect
					label={'Groups'}
					placeholder="Select Group"
					onChange={(value) => setgroupId(value)}
					value={groupId}
					options={groupOption}
					required={true}
				/>
			</div>
			<div className="add-productgroup-footer">
				<Button
					type="button"
					button="Save"
					className="btn-primary add-pgroup"
					onClick={SubmitForm}
				/>
				<Button
					type="button"
					button="Cancel"
					className="btn-dark add-pgroup"
					onClick={onCLose}
				/>
			</div>
		</div>
	);
};

export default AddToGroupModal;
