import React from 'react';
import PropTypes from 'prop-types';
import './modalRight.scss';
import { Link } from 'react-router-dom';

const ModalRight = ({ isOpen, onClose, title, children, 
    description1,description2,description3,
    linkTitle,
    linkPath1,linkName1,
    linkPath2,linkName2,
    linkPath3,linkName3,
    linkPath4,linkName4,
    linkPath5,linkName5,
    linkPath6,linkName6,}) => {
  if (!isOpen) return null;

  return (
    <div className='modal-productDetail'>
    <div className="modal-backdrop modal_outer right_modal"  onClick={onClose}>
       <div class="modal-dialog" role="document">
       <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header border-0">
          <span className='modal-title'>{title}</span>
          <button className="close-button" type="button" onClick={onClose}>&times;</button>         
        </div>
        <div className="modal-body">
          <p>{description1}</p>
          
          <p>{description2}</p>
          
          <p>{description3}</p>
          
          <label className='link-title'>{linkTitle}</label>
          <p>
          <Link to={linkPath1}>{linkName1}</Link>
          <Link to={linkPath2}>{linkName2}</Link>
          <Link to={linkPath3}>{linkName3}</Link>
          <Link to={linkPath4}>{linkName4}</Link>
          <Link to={linkPath5}>{linkName5}</Link>
          <Link to={linkPath6}>{linkName6}</Link>
          </p>
          
        </div>
      </div>
       </div>
       </div>
    </div>
  );
};

ModalRight.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};

ModalRight.defaultProps = {
  title: '',
  children: null,
};

export default ModalRight;
