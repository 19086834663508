export function getDate(dateString) {
	const date = new Date(dateString);

	if (isNaN(date.getTime())) {
		throw new Error('Invalid date string');
	}

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
}
