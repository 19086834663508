import React, { useState } from 'react';
import './ContactPersons.scss';
import email from '../../../assets/image/email.svg';
import phone from '../../../assets/image/phone-call.svg';


const ProductDetails = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div>
      <div>
        <div>
          <div className='d-flex' style={{ gap: '50px' }}>
            <div>
              <div style={{
                color: '#000',
                fontSize: '17px',

              }}>To Be Shipped</div>
              <div style={{
                color: '#000',
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '30px',
                textAlign: 'center'

              }}>300</div>
            </div>
            <div style={{ borderRight: '1px solid #C3CBDC' }}></div>
            <div>
              <div style={{
                color: '#000',
                fontSize: '17px',

              }}>To Be Received</div>
              <div style={{
                color: '#000',
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '30px',
                textAlign: 'center'


              }}>120</div>
            </div>
          </div>
          <br />



          <div style={{
            border: '1px solid #D0D0D0',
            borderRadius: '8px',
            padding: '16px',
            maxWidth: '100%',
            fontFamily: 'Arial, sans-serif'
          }}>
            <h3 style={{ borderBottom: '1px solid #D0D0D0', paddingBottom: '8px', fontWeight: '600' }}>Physical Stock Details</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
              <div className='ListQ'>Stock on Hand</div>
              <div>600</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', }}>
              <div className='ListQ'>Committed Stock</div>
              <div>120</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
              <div className='ListQ'>Available for Sale</div>
              <div>480</div>
            </div>




          </div>
          <br />

          <div style={{
            border: '1px solid #D0D0D0',
            borderRadius: '8px',
            padding: '16px',
            maxWidth: '100%',
            fontFamily: 'Arial, sans-serif'
          }}>
            <h3 style={{ borderBottom: '1px solid #D0D0D0', paddingBottom: '8px', fontWeight: '600' }}>Accounting Stock Details</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
              <div className='ListQ'>Stock on Hand</div>
              <div>600</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', }}>
              <div className='ListQ'>Committed Stock</div>
              <div>120</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
              <div className='ListQ'>Available for Sale</div>
              <div>480</div>
            </div>




          </div>
          <br />





        </div>



      </div>





    </div>
  );
};

export default ProductDetails;
