import React, { useState } from 'react';
import InputField from '../../../../component/form/FormInput';
import Button from '../../../../component/ui/Button';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { formatErrors } from '../../../../utils/controller';
import { catogery_create } from '../../../../store/action/catogery';

const ProductCatogery = ({ isOpen, onCLose, reloadTable, setAutoFocus, setPersistCatogery }) => {
	const dispatch = useDispatch();
	const [name, setName] = useState('');
	const [error, setError] = useState('');
	const [loader, setLoader] = useState(false);

	const submitForm = (event) => {
		setLoader(true);
		event.preventDefault();
		let body = {
			name: name,
		};
		dispatch(catogery_create(body)).then((res) => {
			if (res?.success) {
				setPersistCatogery(name);
				setAutoFocus({
					catogrey: false,
					product: true,
					shelf: false,
					tax1: false,
				});
				reloadTable();
				toast.success(res?.message);
				onCLose();
				setLoader(false);
			} else {
				formatErrors(res?.data?.message);
				setLoader(false);
				toast.error(res?.response?.data?.message);
			}
		});
	};

	return (
		<div>
			<form
				onSubmit={submitForm}
				className="add-productgroup-form"
			>
				<InputField
					label="Catogery Name"
					placeholder=""
					type="text"
					required={true}
					name="Enter Catogery Name"
					autoFocus={true}
					onChange={(val) => setName(val)}
					value={name}
					error={error?.name || error}
				/>
				<div className="add-productgroup-footer">
					<Button
						type="submit"
						button="Save"
						className="btn-primary add-pgroup"
						loader={loader}
					/>
					<Button
						type="button"
						button="Cancel"
						className="btn-dark add-pgroup"
						onClick={onCLose}
					/>
				</div>
			</form>
		</div>
	);
};

export default ProductCatogery;
