import { INVENTORY_ADJUSTMENT_CONVERT_DONE, INVENTORY_ADJUSTMENT_CONVERT_FAIL, INVENTORY_ADJUSTMENT_CREATE_DONE, INVENTORY_ADJUSTMENT_CREATE_FAIL, INVENTORY_ADJUSTMENT_DELETE_DONE, INVENTORY_ADJUSTMENT_DELETE_FAIL, INVENTORY_ADJUSTMENT_LIST_GET_DONE, INVENTORY_ADJUSTMENT_LIST_GET_FAIL, INVENTORY_ADJUSTMENT_UPDATE_DONE, INVENTORY_ADJUSTMENT_UPDATE_FAIL } from "../../action/inventory";

 

const initState = {
    create: {
      data: null,
      error: null,
      status: null,
    },
    list: {
      data: [],
      error: null,
      status: null,
    },
    update: {
      data: null,
      error: null,
      status: null,
    },
    delete: {
      data: null,
      error: null,
      status: null,
    },
    convert: {
      data: null,
      error: null,
      status: null,
    },
  };
  
  export const inventoryAdjustmentReducer = (state = initState, action) => {
    switch (action.type) {
      case INVENTORY_ADJUSTMENT_CREATE_DONE:
        return {
          ...state,
          create: {
            ...state.create,
            data: action.payload,
            status: 'success',
            error: null,
          },
        };
      case INVENTORY_ADJUSTMENT_CREATE_FAIL:
        return {
          ...state,
          create: {
            ...state.create,
            data: null,
            status: 'fail',
            error: action.payload,
          },
        };
  
      case INVENTORY_ADJUSTMENT_LIST_GET_DONE:
        return {
          ...state,
          list: {
            ...state.list,
            data: action.payload.data,
            status: 'success',
            error: null,
          },
        };
      case INVENTORY_ADJUSTMENT_LIST_GET_FAIL:
        return {
          ...state,
          list: {
            ...state.list,
            data: [],
            status: 'fail',
            error: action.payload,
          },
        };
  
      case INVENTORY_ADJUSTMENT_UPDATE_DONE:
        return {
          ...state,
          update: {
            ...state.update,
            data: action.payload,
            status: 'success',
            error: null,
          },
        };
      case INVENTORY_ADJUSTMENT_UPDATE_FAIL:
        return {
          ...state,
          update: {
            ...state.update,
            data: null,
            status: 'fail',
            error: action.payload,
          },
        };
  
      case INVENTORY_ADJUSTMENT_DELETE_DONE:
        return {
          ...state,
          delete: {
            ...state.delete,
            data: action.payload,
            status: 'success',
            error: null,
          },
        };
      case INVENTORY_ADJUSTMENT_DELETE_FAIL:
        return {
          ...state,
          delete: {
            ...state.delete,
            data: null,
            status: 'fail',
            error: action.payload,
          },
        };
  
      case INVENTORY_ADJUSTMENT_CONVERT_DONE:
        return {
          ...state,
          convert: {
            ...state.convert,
            data: action.payload,
            status: 'success',
            error: null,
          },
        };
      case INVENTORY_ADJUSTMENT_CONVERT_FAIL:
        return {
          ...state,
          convert: {
            ...state.convert,
            data: null,
            status: 'fail',
            error: action.payload,
          },
        };
  
      default:
        return state;
    }
  };
  