import React, { useState } from 'react';
import InputField from '../../component/form/FormInput';

const Remark = ({ disabled, setRemark, remark }) => {
	const formData = [];
	const [error, setError] = useState({});
	return (
		<div>
			<div className="">
				<InputField
					label="Remark"
					placeholder="Write Remark"
					type="text"
					value={remark?.remark_text}
					onChange={(value) =>
						setRemark((prev) => ({
							...prev,
							remark_text: value,
						}))
					}
					disabled={disabled}
					error={error?.name}
				/>
			</div>
		</div>
	);
};
export default Remark;
