import React, { useEffect, useRef, useState } from 'react';
import InputField from '../../component/form/FormInput';
import { VendorTds } from '../../utils/dropsdown';
import InputSelect from '../../component/inputSelect/InputSelect';
import Modal from '../../component/modal/Modal';
import UploadDocuments from './uploadDocument/index';
import AddPaymentTerms from './AddPaymentTerms';
import { toast } from 'react-toastify';
import { fetchPaymentTerm } from '../../services/vendor';

const OtherDetails = ({ error, disabled, setError, data, handleInputChange }) => {
	const [paymentTerms, setPaymentTerms] = useState([]);
	const [open, setOpen] = useState(false);
	const [openPaymentTermsModal, setOpenPaymentTermsModal] = useState(false);
	const [paymentTermParams, setPaymetTermParams] = useState({
		page: 1,
		limit: 10,
		search: '',
	});

	const handleFetchPaymentTerm = async () => {
		try {
			const paymentTermData = await fetchPaymentTerm(paymentTermParams);
			if (paymentTermData?.length > 0) {
				const paymentTermOptions = paymentTermData.map((payment) => {
					return {
						key: payment?.payment_terms_id,
						value: 'Net ' + payment?.term_name,
					};
				});
				setPaymentTerms(paymentTermOptions);
			}
		} catch (error) {
			toast.error('Something went wrong!');
		}
	};

	//TODO: Debouncing left
	useEffect(() => {
		handleFetchPaymentTerm();
	}, [paymentTermParams?.search]);

	return (
		<div>
			<div className="row mt-1">
				<div className="col">
					<InputField
						label="PAN"
						placeholder="Vendor PAN"
						type="text"
						value={data.pan}
						onChange={(value) => handleInputChange('pan', value)}
						disabled={disabled}
						error={error?.pan}
						showValidateIcon={true}
					/>
				</div>
				<div className="col">
					<InputField
						label="GST"
						placeholder="Vendor GST"
						type="text"
						value={data.gst}
						onChange={(value) => handleInputChange('gst', value)}
						disabled={disabled}
						error={error?.gst}
						showValidateIcon={true}
					/>
				</div>
				<div className="col">
					<InputSelect
						options={[]}
						label="Currency"
						placeholder="Vendor Currency"
						returnInputValue={true}
						onChange={(value) => handleInputChange('currency', value)}
						value={'INR'}
						disabled={true}
						required={true}
						error={error?.currency}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<InputSelect
						options={paymentTerms || []}
						label="Payment Terms"
						returnInputValue={true}
						placeholder="Vendor Payment Terms"
						value={data.payment_terms}
						onFocusCb={handleFetchPaymentTerm}
						onKeyDownCb={(e) => {
							if (e.target.value !== '') {
								setPaymetTermParams((prev) => ({
									...prev,
									search: e.target.value,
								}));
							}
						}}
						onAddNew={() => setOpenPaymentTermsModal(true)}
						onChange={(value) => {
							handleInputChange('payment_terms', value);
						}}
						disabled={disabled}
						error={error?.payment_terms}
					/>
				</div>
				<div className="col">
					<InputSelect
						options={VendorTds || []}
						label="TDS"
						returnInputValue={true}
						placeholder="Vendor TDS"
						value={'TDS'}
						onChange={(value) => handleInputChange('tds', value)}
						disabled={true}
						error={error?.tds}
					/>
				</div>
				<div className="col-4">
					<InputField
						label="Website URL"
						placeholder="Vendor Website URL"
						type="text"
						value={data.website_url}
						onChange={(value) => handleInputChange('website_url', value)}
						disabled={disabled}
						error={error?.website_url}
					/>
				</div>
			</div>

			<div>
				{open && (
					<Modal
						className="uploadFile-modal"
						isOpen={open}
						onClose={() => setOpen(false)}
						title="Upload Documents"
						cancelButton={true}
						actionButton={true}
					>
						<UploadDocuments
							isOpen={open}
							onClose={() => setOpen(false)}
						/>
					</Modal>
				)}

				{openPaymentTermsModal && (
					<Modal
						className="add-payment-terms-modal"
						isOpen={openPaymentTermsModal}
						onClose={() => setOpenPaymentTermsModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<AddPaymentTerms
							paymentTerms={paymentTerms}
							setPaymentTerms={setPaymentTerms}
							handleInputChange={handleInputChange}
							setOpenPaymentTermsModal={setOpenPaymentTermsModal}
						/>
					</Modal>
				)}
			</div>
		</div>
	);
};

export default OtherDetails;
