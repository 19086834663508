import React, { useEffect, useState } from 'react';
import './BankDetails.scss';
import plus from '../../../assets/image/plus.svg';
import Modal from '../../../component/modal/Modal';
import AddBankDetails from '../AddBankDetails';
import BankCard from '../BankCard';
import { useDispatch } from 'react-redux';
import { create_Vendor_bank, updateVendorBankDetails } from '../../../store/action/vendor';
import { toast } from 'react-toastify';
import { formatErrors } from '../../../utils/controller';
import { deleteBankDetails } from '../../../services/vendor';
import ConfirmationModal from '../../../component/modal/ConfirmationModal';

const BankDetails = ({ isEdit, data, error, setBankDetails, vendorId, reloadTable }) => {
	const [open, setOpen] = useState(false);
	const [bankIndex, setBankIndex] = useState(null);
	const dispatch = useDispatch();
	const [formError, setFormError] = useState({});
	const [isEditBankDetails, setIsEditBankDetails] = useState(false);
	const [formData, setFormData] = useState({
		bank_name: '',
		account_number: '',
		ifsc_code: '',
		beneficiary_name: '',
	});
	const selectedBank = bankIndex !== null ? data[bankIndex] : null;
	const [reaccount, setReaccount] = useState('');
	const [deleteBankDetailsId, setDeleteBankDetaislId] = useState(null);
	const [isDeleteBankDetailsModalOpen, setIsDeleteBankDetailsModalOpen] = useState(false);

	const openEditBankDetailsModal = (bankId) => {
		setIsEditBankDetails(true);
		setBankIndex(bankId);
		const bankDetails = data[bankId];
		setFormData(bankDetails);
		setOpen(true);
	};

	const handleInputChange = (key, value) => {
		const updatedData = { ...formData, [key]: value };
		setFormData(updatedData);

		if (value) {
			const newError = { ...error };
			delete newError?.[key];
			setFormError(newError);
		}
	};

	function validateFrom() {
		if (formData?.account_number === '') {
			setFormError((prev) => ({
				...prev,
				account_number: 'Account number is required!',
			}));
			return;
		}

		if (formData?.bank_name === '') {
			setFormError((prev) => ({
				...prev,
				bank_name: 'Bank name is required!',
			}));
			return;
		}

		if (formData?.beneficiary_name === '') {
			setFormError((prev) => ({
				...prev,
				beneficiary_name: 'Account holder name is required!',
			}));
			return;
		}

		if (formData?.ifsc_code === '') {
			setFormError((prev) => ({
				...prev,
				ifsc_code: 'IFSC Code is required!',
			}));
			return;
		}

		if (formData.account_number !== reaccount) {
			const newError = {
				...error,
				reaccount: 'Account numbers are not same',
			};
			setFormError(newError);
			return;
		}
		return true;
	}

	const handleAddNewBankDetails = () => {
		if (!validateFrom()) return;

		if (vendorId) {
			dispatch(create_Vendor_bank(formData, vendorId)).then((res) => {
				if (res?.success) {
					toast.success(res?.message);
					reloadTable();
					setFormData({
						bank_name: '',
						account_number: '',
						ifsc_code: '',
						beneficiary_name: '',
					});
					setFormError({});
				} else {
					if (res?.code === 422) {
						let errorObj = formatErrors(res?.message);
						setFormError(errorObj);
					} else {
						toast.error(res?.message || 'Something went wrong');
					}
				}
			});
		} else {
			setBankDetails([...(data || []), formData]);
		}

		setOpen(false);
	};

	const handleEditBankDetails = () => {
		if (!validateFrom()) return;

		if (vendorId) {
			dispatch(updateVendorBankDetails(vendorId, formData?.bank_details_id, formData))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						reloadTable();
						setFormError({});
					} else {
						toast.error(res?.message || '');
					}
					setIsEditBankDetails(false);
				})
				.catch((err) => {
					setIsEditBankDetails(false);
					toast.error('Something went wrong!');
				});
		} else {
			const newBankDetails = data.map((bank, i) =>
				bankIndex === i ? { ...bank, ...formData } : { ...bank },
			);
			setBankDetails(newBankDetails);
		}
		setFormData({
			bank_name: '',
			account_number: '',
			ifsc_code: '',
			beneficiary_name: '',
		});
		setFormError({});
		setBankIndex(null);
		setOpen(false);
	};

	const handleDeleteBankDetails = async () => {
		try {
			const newBankDetails = data?.filter((_, i) => i !== deleteBankDetailsId);
			await deleteBankDetails(
				data[deleteBankDetailsId]?.vendor_id,
				data[deleteBankDetailsId]?.bank_details_id,
			);
			setBankDetails(newBankDetails);
			toast.success('Bank account deleted successfully!');
		} catch (error) {
			toast.error('Something went wrong!');
		}
	};

	useEffect(() => {
		if (selectedBank) {
			setFormData({ ...formData, ...selectedBank });
		}
	}, [selectedBank]);

	return (
		<div className="bankDetailsBody">
			<div
				className="headr"
				style={{
					padding: '0px 5px',
				}}
			>
				<p>Bank Accounts</p>
				<button
					type="button"
					className="w-100 btn btn-primary"
					style={{ paddingLeft: '9px', paddingRight: '9px' }}
					onClick={() => {
						if (isEdit) {
							const areYouSure = window.confirm(
								'Changes will be lost. Please make sure to save your changes before adding new content.',
							);
							if (!areYouSure) return;
						}
						setOpen(true);
					}}
				>
					<img
						src={plus}
						alt=""
					/>
				</button>
			</div>

			{data &&
				data.length > 0 &&
				data.map((bank, i) => (
					<React.Fragment key={i}>
						<BankCard
							id={i}
							data={bank}
							openEditBankDetailsModal={openEditBankDetailsModal}
							deleteBankDetails={(id) => {
								setDeleteBankDetaislId(id);
								setIsDeleteBankDetailsModalOpen(true);
							}} // Pass delete function
						/>
					</React.Fragment>
				))}

			{/* Modal */}
			{open && (
				<Modal
					className="add-bank-detail-modal"
					isOpen={open}
					onClose={() => {
						setOpen(false);
						setFormData({ bank_name: '', account_number: '', ifsc_code: '', beneficiary_name: '' });
						if (selectedBank) setBankIndex(null);
					}}
					title={<span style={{ fontWeight: '500', fontSize: '18px' }}>Add Bank Account</span>}
					handleSave={isEditBankDetails ? handleEditBankDetails : handleAddNewBankDetails}
					cancelButton={true}
					actionButton={true}
				>
					<AddBankDetails
						isEdit={!!selectedBank}
						reloadTable={reloadTable}
						setReaccount={setReaccount}
						formData={formData}
						setFormData={setFormData}
						error={formError}
						setError={setFormError}
						handleInputChange={handleInputChange}
					/>
				</Modal>
			)}

			{isDeleteBankDetailsModalOpen && (
				<ConfirmationModal
					isOpen={isDeleteBankDetailsModalOpen}
					title="Delete Bank Account"
					content={
						<p
							style={{
								textAlign: 'center',
								backgroundColor: '#E6F5FF',
								padding: '10px',
								borderRadius: '5px',
								fontWeight: '500',
								color: 'black',
							}}
						>
							Are you sure you want to delete this bank account?
						</p>
					}
					onCancel={() => {
						setIsDeleteBankDetailsModalOpen(false);
						setDeleteBankDetaislId(null);
					}}
					onOk={handleDeleteBankDetails}
				/>
			)}
		</div>
	);
};

export default BankDetails;
