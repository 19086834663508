// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FILE UPLOAD */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/pages/vendors/AddBankDetails/AddBankDetails.scss"],"names":[],"mappings":"AAAA,gBAAA;AAKA;;EAEC,wBAAA;EACA,SAAA;AAHD;;AAMA;EACC,0BAAA;AAHD","sourcesContent":["/* FILE UPLOAD */\n.addBank-component {\n\t// padding: 0 2rem;\n}\n\ninput[type='number']::-webkit-outer-spin-button,\ninput[type='number']::-webkit-inner-spin-button {\n\t-webkit-appearance: none;\n\tmargin: 0;\n}\n\ninput[type='number'] {\n\t-moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
