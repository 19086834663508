// VendorDetails.js

import React, { useState } from "react";
import "../vendors/NavigationTab.scss"; // Import the custom CSS file
import DetailsTab from './Navigation/DetailsTab'; // Import the Details tab component
import OpenPOSTab from './Navigation/OpenPOSTab'; // Import the Open POs tab component

import './purchaseOrder.scss';
import ProfilePlaceHolder from '../../assets/image/emailPlaceHolder.svg';
import BlackClose from '../../assets/image/x-squareBlack.svg';
import phone from '../../assets/image/phone-call.svg';
import email from '../../assets/image/email.svg';





const VendorDetails = ({ isOpen, onClose }) => {

  const [activeTab, setActiveTab] = useState("Details");

  const navTabs = [
    "Details",
    "Open POs",
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return <DetailsTab />;
      case "Open POs":
        return <OpenPOSTab />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        right: isOpen ? '0' : '-486px',
        top: '0',
        width: '486px',
        height: '100%',
        backgroundColor: '#fff',
        boxShadow: '-3px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        transition: 'right 0.3s ease-in-out',
        overflowY: 'auto',
        padding: '20px',
        zIndex: 1000,
        marginTop: '60px',


      }}

    >

      <div className='d-flex align-self-center justify-content-sm-between '>

        <div className='VenderDetails'>Vendor Details</div>

        <div className='d-flex align-self-center ' style={{ gap: '26px', alignItems: 'center' }}>
          <div><a href="#">View more details</a></div>


          <div className='SidebaarCloseBTN d-flex align-self-center justify-content-sm-center' onClick={onClose} >
            <img src={BlackClose} alt="" style={{ width: '14px', cursor: 'pointer' }} />

          </div>
        </div>

      </div>
      <br />

      <div className='d-flex' style={{ gap: '20px' }}>
        <div className='profileImage d-flex align-self-center justify-content-sm-center'>
          <img src={ProfilePlaceHolder} alt="" style={{ width: '55px', borderRadius: '10px' }} />
        </div>

        <div className='d-flex flex-column' style={{ gap: '15px' }} >
          <div style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#333',



          }}>Shree Ram Medicals</div>

          <div style={{
            fontSize: '14px',
            color: 'var(--sub, #555B67)',

          }}>Pratap Nagar, Jaipur</div>

        </div>

      </div>
      <br />
      <hr />
      <div style={{
        fontSize: '14px',
        color: 'var(--sub, #555B67)',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        marginTop: '15px'

      }}>
        <div><img src={phone} alt="" /> +91 1234567890 </div>
        <div><img src={email} alt="" /> rajkumar@gmail.com</div>
      </div>
      <br />
      <br />

      {/* tabs  */}
      <div>
        <ul className="nav mb-4 mt-2">
          {navTabs.map((item, index) => (
            <li key={index} className="nav-item">
              <a
                className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default anchor click behavior
                  setActiveTab(item);
                }}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
        <hr style={{
          backgroundColor: '#E3E4E5',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          marginTop: '-13px'


        }} />
        <div>
          {renderTabContent()}
        </div>
      </div>






    </div>
  );
};

export default VendorDetails;
